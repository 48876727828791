import React, { useEffect } from 'react';
import {
    Flex,
    IconButton,
    Grid,
    GridItem,
    Stack,
    Box,
    HStack,
    Text,
    useBreakpointValue,
    Collapse,
    Radio,
    RadioGroup,
    Divider,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { MaxWidthWrapper, PageLayoutProvider, SummaryPanel } from '../../layout/page-layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseUnits } from 'ethers/lib/utils';
import _ from 'lodash';
import { Field, FieldProps, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { OrangeButton, SwitchNetworksButton, WhiteButton } from '../../inputs/buttons';
import { useBullaBanker } from '../../../hooks/useBullaBanker';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useCanChangeNetwork } from '../../../hooks/useCanChangeNetwork';
import { useUIState } from '../../../state/ui-state';
import { useAppState } from '../../../state/app-state';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useOnboard } from '../../../hooks/useWeb3';
import { ChainId, SUPPORTED_NETWORKS, TokenDto } from '../../../data-lib/networks';
import {
    ChainSelector,
    disabledInputProps,
    RecipientField,
    ClaimDescriptionField,
    DueByField,
    AccountTagField,
    ClaimNotesField,
    EmailField,
    AttachmentField,
    LineItemsSection,
    TaxType,
    claimAmountValidationSchema,
    ClaimAmountField,
} from '../../modals/create-claim-modal/create-claim-inputs';
import { FinancingTerms } from '../../../data-lib/dto/bulla-finance-dto';
import { vendorFinancingEnabled } from '../../../tools/featureFlags';
import { NewInvoiceLabelText } from '../../modals/common';
import { EditFinancingTermsModal } from '../../modals/financing-terms-modal/financing-terms-modal';
import { useSendClaimEmail } from '../../../hooks/useEmail';
import { useExternalTransactionsApi, CreateInvoiceRequestParams, getBackendTxIdForItem } from '../../../hooks/useExternalTransactionsApi';
import { useMembership } from '../../../hooks/useMembership';
import { useCreateOffchainInvoice } from '../../../hooks/useOffchainInvoices';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { apply, getBase64 } from '../../../tools/common';
import { generateAttachment } from '../../../tools/ipfs';
import { useBullaToast } from '../../base/toast';
import { ChakraCompose } from '../../../tools/types';
import {
    BullaItemAttachment,
    NewAddressAlert,
    ccEmailErrorSchema,
    claimInfoSchemaFields,
    recipientErrorSchema,
} from '../../modals/create-claim-modal/create-claim-modal';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { addDaysToToday } from '../../../data-lib/helpers';
import { useCompanyDetailsRepo } from '../../../hooks/useCompanyDetailsRepo';
import { custodianId, useCustodianApi } from '../../../hooks/useCustodianApi';
import { EthAddress } from '../../../data-lib/ethereum';
import * as Yup from 'yup';
import { isAddress as validEthereumAddress } from '@ethersproject/address';

export type Tax = {
    taxRate: string;
    taxType: TaxType;
};

export type InvoiceLineItems = {
    token: TokenDto;
    description: string;
    referenceNumber: string;
    quantity: string;
    unitPrice: string;
};

export type CreateClaimFieldsNew = {
    lineItems: InvoiceLineItems[];
    tax?: Tax;
    claimAmount: string;
    token: TokenDto;
    recipient: string;
    description: string;
    dueBy: Date;
    tags: string[];
    attachment?: BullaItemAttachment | undefined;
    notes?: string;
    emailAddress?: string;
    emailMessage?: string;
    emailCC?: string;
    customCreditorAndDebtor?: { creditor: string; debtor: string };
};

type CreateClaimProps = ChakraCompose & {
    alertMessage?: string;
    header?: React.ReactNode;
    defaults?: Partial<typeof emptyFields> & { network?: ChainId; token?: TokenDto };
    fromLink?: boolean;
};

export enum NewInvoiceType {
    NFT = 'nft',
    OFFCHAIN = 'offchain',
    HYBRID = 'hybrid',
}

export const emptyFields = {
    lineItems: [] as InvoiceLineItems[],
    tax: undefined,
    claimAmount: '0',
    recipient: '',
    description: '',
    dueBy: addDaysToToday(30),
    tags: [] as string[],
    attachment: undefined,
    notes: '',
    emailAddress: '',
    emailMessage: '',
    emailCC: '',
    invoiceType: NewInvoiceType.HYBRID,
};

export const lineItemValidationSchema = Yup.object().shape({
    description: Yup.string().trim().required('Description is required'),
    quantity: Yup.string()
        .trim()
        .required('Quantity is required')
        .test('is-valid-quantity', 'Quantity must be more than 0', value => (value ? +value > 0 : false))
        .test('is-number', 'Must be a valid number', value => !isNaN(Number(value))),
    unitPrice: Yup.string()
        .trim()
        .required('Unit price is required')
        .test('is-valid-price', 'Price must be more than 0', value => (value ? +value > 0 : false))
        .test('is-number', 'Must be a valid number', value => !isNaN(Number(value))),
});

export const errorMessageSchemaNewInvoice = (senderAddress: EthAddress, isOffchainInvoice?: boolean) =>
    Yup.object().shape({
        recipient: isOffchainInvoice
            ? recipientErrorSchema(senderAddress)
            : recipientErrorSchema(senderAddress)
                  .required('Required')
                  .test('is-valid-address', 'Invalid wallet address', value => validEthereumAddress(value || '')),
        claimAmount: claimAmountValidationSchema,
        dueBy: Yup.date().typeError('Invalid date'),
        ...claimInfoSchemaFields,
        description: claimInfoSchemaFields.description.required('Required'),
        emailCC: ccEmailErrorSchema(),
        emailAddress: Yup.mixed().when('invoiceType', {
            is: NewInvoiceType.OFFCHAIN,
            then: () => claimInfoSchemaFields.emailAddress.required('Required'),
            otherwise: () => claimInfoSchemaFields.emailAddress.optional(),
        }),
        lineItems: Yup.mixed().when('invoiceType', {
            is: NewInvoiceType.HYBRID,
            then: () => Yup.array().of(lineItemValidationSchema),
            otherwise: () => Yup.mixed().notRequired(),
        }),
        taxRate: Yup.string().test('is-valid-rate', 'Tax rate must be 0 or greater', value => !value || +value >= 0),
    });

export const NewInvoice = ({ defaults }: CreateClaimProps) => {
    const { connectedNetwork } = useWeb3();
    const navigate = useNavigate();
    const { getAttachmentGenerationLink } = useCompanyDetailsRepo();
    const canChangeNetwork = useCanChangeNetwork();
    const { transactionPending } = useUIState();
    const { readyToTransact } = useAppState();
    const senderAddress = useActingWalletAddress();
    const sendClaimEmail = useSendClaimEmail();
    const { erc20sByChainId } = useTokenRepo();
    const [creatingClaim, { createClaim, createClaimWithSafeAndFinancing }] = useBullaBanker();
    const [creatingHybridClaim, setCreatingHybridClaim] = React.useState(false);
    const { changeNetwork } = useOnboard();
    const [showFinancingOptions, setShowFinancingOptions] = React.useState(false);
    const [sendingOffchainClaim, setSendingOffchainClaim] = React.useState(false);
    const isLoading = creatingClaim || sendingOffchainClaim || creatingHybridClaim;
    const { saveExternalTransactions } = useExternalTransactionsApi();
    const { safeInfo } = useGnosisSafe();
    const triggerToast = useBullaToast();
    const membership = useMembership();
    const createOffchainInvoice = useCreateOffchainInvoice();
    const pageRef = React.useRef<HTMLDivElement>(null);
    const { saveInvoiceData } = useCustodianApi();
    const initialDefaultTag = defaults?.tags ?? [];
    const isExpense = initialDefaultTag.includes('Expense');
    const initialERC20 = erc20sByChainId[connectedNetwork][0].token;
    const [financingTerms, setFinancingTerms] = React.useState<FinancingTerms | 'none'>('none');

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const recipientFromUrl = queryParams.get('recipient');

    const defaultsWithRecipientQuery = {
        ...defaults,
        recipient: recipientFromUrl || (defaults?.recipient as string),
    };

    const descriptionRequired = true;

    const handleOffchainSend = async (
        formikInputs: CreateClaimFieldsNew,
        createOffchainInvoice: (params: CreateInvoiceRequestParams) => Promise<boolean>,
    ) => {
        setSendingOffchainClaim(true);
        const values = { ...formikInputs };

        const attachment = values.attachment;
        const file = await (!attachment
            ? Promise.resolve(undefined)
            : attachment == 'generate'
            ? (() => {
                  const url = getAttachmentGenerationLink(
                      'Invoice',
                      values.recipient,
                      values.claimAmount,
                      values.token.symbol,
                      values.description,
                  );
                  const filename = 'Invoice-attachment.pdf';
                  return generateAttachment(url, filename);
              })()
            : Promise.resolve('file' in attachment && attachment.file !== 'not-uploaded' ? attachment.file : undefined));

        const fileDto = file && { name: file.name, base64: await getBase64(file) };

        const offchainResult = await createOffchainInvoice({
            dueDate: Math.floor(values.dueBy.getTime() / 1000),
            email: values.emailAddress!,
            confirmationEmail: values.emailCC,
            description: values.description == '' ? undefined : values.description,
            file: fileDto,
            tokenStrategy: {
                kind: 'fixed-token-and-chain',
                tokenSymbol: values.token.symbol,
                tokenAddress: values.token.address,
                tokenDecimals: values.token.decimals,
                chainId: connectedNetwork,
                amountStrategy: { kind: 'token-amount', amount: parseUnits(values.claimAmount, values.token.decimals).toString() },
            },
            notes: values.notes,
            categories: values.tags,
        });

        if (offchainResult) {
            triggerToast({ title: 'Invoice sent successfully.', position: 'top', variant: 'top-accent' });
            navigate('/?tab=Receivables');
        } else {
            triggerToast({ title: 'Failed to send invoice.', position: 'top', status: 'error' });
        }
        setSendingOffchainClaim(false);
    };

    const handleSend = async (formikInputs: CreateClaimFieldsNew) => {
        const values = {
            ...formikInputs,
            tags: [...formikInputs.tags, ...(isExpense && !formikInputs.tags.includes('Expense') ? ['Expense'] : [])].filter(x => x !== ''),
        };
        const financeTerms = financingTerms !== 'none' ? financingTerms : undefined;
        const result =
            safeInfo && financeTerms
                ? await createClaimWithSafeAndFinancing('Invoice', values, financeTerms)
                : await createClaim('Invoice', values, financeTerms);

        const sendNotes = async (id: string, notes: string) => {
            await saveExternalTransactions({
                [getBackendTxIdForItem(id, connectedNetwork)]: {
                    id,
                    notes: notes,
                    chainId: connectedNetwork,
                },
            });
        };
        if (result) {
            try {
                const claimEvents = sendClaimEmail('Invoice', [values], result);
                const claimId = claimEvents.length > 0 && claimEvents[0].tokenId;
                if (claimId && formikInputs.notes && formikInputs.notes !== '') {
                    await sendNotes(claimId, formikInputs.notes);
                }
                return {
                    claimId,
                    success: true,
                };
            } catch (e) {
                console.warn('error sending claim email and getting events. Cant save notes', e);
                return { success: true };
            }
        }
        return { success: false };
    };

    const handleCancel = () => {
        navigate('/');
    };

    const handleComplete = (resetForm: () => void) => {
        handleCancel();
        resetForm();
    };

    return (
        <PageLayoutProvider>
            <Flex p="12" direction="column" flex="1">
                <SummaryPanel>
                    <Flex justify="space-between" align="center">
                        <Flex align="center">
                            <IconButton
                                onClick={() => navigate('/')}
                                icon={<ArrowBackIcon boxSize={6} />}
                                borderRadius={'full'}
                                aria-label="Previous page"
                                variant="ghost"
                                size="lg"
                                p={4}
                            />

                            <Text color="heading" fontWeight={'700'} fontSize="21px" noOfLines={1}>
                                Invoice Details
                            </Text>
                        </Flex>
                        <Flex gap={2}>
                            <WhiteButton>Company Details</WhiteButton>
                            <WhiteButton>Preview Invoice</WhiteButton>
                        </Flex>
                    </Flex>
                </SummaryPanel>
                <MaxWidthWrapper display={'flex'} flexDirection="column" flex="1" mt={8}>
                    <Formik
                        initialValues={{
                            ...emptyFields,
                            ...defaultsWithRecipientQuery,
                            token: defaults?.token ?? initialERC20,
                            claimAmount: defaults?.claimAmount ?? '',
                            tags: initialDefaultTag,
                        }}
                        validate={values => {
                            try {
                                validateYupSchema(
                                    values,
                                    errorMessageSchemaNewInvoice(senderAddress, values.invoiceType == NewInvoiceType.OFFCHAIN),
                                    true,
                                );
                                return {};
                            } catch (err) {
                                return yupToFormErrors(err);
                            }
                        }}
                        validateOnMount={!!defaults}
                        validateOnBlur
                        onSubmit={async (fieldValues, { resetForm }) => {
                            if (fieldValues.invoiceType == NewInvoiceType.HYBRID || fieldValues.invoiceType == NewInvoiceType.NFT) {
                                try {
                                    setCreatingHybridClaim(true);
                                    let description = fieldValues.description;

                                    if (fieldValues.invoiceType == NewInvoiceType.HYBRID) {
                                        const payloadId = await saveInvoiceData(fieldValues);

                                        // description that will be posted on chain when creating a hybrid invoice
                                        description = `{"version": 1, "custodianId": "${custodianId}", "payloadId": "${payloadId}"}`;
                                    }

                                    const { success } = await handleSend({
                                        ...fieldValues,
                                        description,
                                        claimAmount: fieldValues.claimAmount,
                                    });

                                    if (success) {
                                        handleComplete(resetForm);
                                        navigate('/?tab=Receivables');
                                    }
                                } finally {
                                    setCreatingHybridClaim(false);
                                }
                            } else if (createOffchainInvoice !== 'loading') {
                                handleOffchainSend(fieldValues, createOffchainInvoice);
                            }
                        }}
                    >
                        {({ errors, touched, setFieldValue, isValid, values, setFieldTouched, dirty, setStatus, status }) => {
                            console.log('errors', errors);
                            const isNftInvoice = values.invoiceType == NewInvoiceType.NFT;
                            const isHybridInvoice = values.invoiceType == NewInvoiceType.HYBRID;
                            const includeNativeToken = !isNftInvoice;
                            const isDisabled =
                                !(dirty || !!defaults) || !isValid || isLoading || !readyToTransact || createOffchainInvoice == 'loading';

                            const columns = useBreakpointValue({ base: '1fr', md: '1fr 1fr' }, { ssr: false });

                            useEffect(() => {
                                if (!includeNativeToken && values.token.isNative) setFieldValue('token', initialERC20);
                            }, [includeNativeToken]);

                            return (
                                <>
                                    <Form placeholder={''}>
                                        <Grid templateColumns={columns} columnGap="6" rowGap="3" mb={'6'}>
                                            <GridItem key={'left'}>
                                                <Stack spacing="3">
                                                    {!safeInfo && (
                                                        <Box>
                                                            <NewInvoiceLabelText pb="3">Chain</NewInvoiceLabelText>
                                                            <ChainSelector
                                                                chainId={defaults?.network ?? connectedNetwork}
                                                                isDisabled={isLoading || !canChangeNetwork || !!defaults?.network}
                                                                selectableChains={SUPPORTED_NETWORKS}
                                                                w="fit-content"
                                                                textAlign={'left'}
                                                                onChainSelected={changeNetwork}
                                                                {...disabledInputProps}
                                                            />
                                                        </Box>
                                                    )}
                                                    <Stack spacing="0.5">
                                                        <NewInvoiceLabelText pb="3">
                                                            Invoice Type
                                                            <QuestionOutlineIcon ml="2" color={'gray.400'} />
                                                        </NewInvoiceLabelText>
                                                        <RadioGroup
                                                            onChange={value => setFieldValue('invoiceType', value as NewInvoiceType)}
                                                            value={values.invoiceType}
                                                            defaultValue={NewInvoiceType.NFT}
                                                        >
                                                            <Stack direction="column" ml="1" spacing={1}>
                                                                <Radio value={NewInvoiceType.NFT}>Onchain</Radio>
                                                                <Radio value={NewInvoiceType.OFFCHAIN}>Offchain</Radio>
                                                                <Radio value={NewInvoiceType.HYBRID}>Hybrid</Radio>
                                                            </Stack>
                                                        </RadioGroup>
                                                    </Stack>

                                                    <Divider color={'gray.200'} />
                                                    <NewInvoiceLabelText>
                                                        Recipient Info
                                                        <QuestionOutlineIcon ml="2" color={'gray.400'} />
                                                    </NewInvoiceLabelText>
                                                    <Collapse
                                                        in={!!touched.recipient && !!values.recipient && !errors.recipient}
                                                        unmountOnExit
                                                    >
                                                        <NewAddressAlert newAddress={values.recipient} />
                                                    </Collapse>
                                                    {(isNftInvoice || isHybridInvoice) && (
                                                        <HStack>
                                                            <Field name="recipient">
                                                                {({ field }: FieldProps) => (
                                                                    <RecipientField
                                                                        {...{
                                                                            field,
                                                                            initialValue: defaultsWithRecipientQuery.recipient,
                                                                            isDisabled:
                                                                                transactionPending ||
                                                                                !!defaultsWithRecipientQuery.recipient,
                                                                            error: errors.recipient,
                                                                            touched: touched.recipient,
                                                                            setRecipient: apply(setFieldValue, field.name),
                                                                            setEmailAddress: apply(setFieldValue, 'emailAddress'),
                                                                            label: 'Recipient Address',
                                                                            chainId: defaults?.network,
                                                                            required: isNftInvoice,
                                                                            disableScrollBehavior: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </HStack>
                                                    )}
                                                    <Field name="emailAddress">
                                                        {({ field }: FieldProps) => (
                                                            <EmailField
                                                                {...{
                                                                    field,
                                                                    isDisabled: transactionPending,
                                                                    error: errors.emailAddress,
                                                                    touched: touched.emailAddress,
                                                                    label: 'Recipient Email',
                                                                    required: values.invoiceType == NewInvoiceType.OFFCHAIN,
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Divider color={'gray.200'} />
                                                    <NewInvoiceLabelText>
                                                        Invoice Details
                                                        <QuestionOutlineIcon ml="2" color={'gray.400'} />
                                                    </NewInvoiceLabelText>
                                                    <Field name="description">
                                                        {({ field }: FieldProps) => (
                                                            <ClaimDescriptionField
                                                                {...{
                                                                    field,
                                                                    error: errors.description,
                                                                    touched: touched.description,
                                                                    label: 'Description',
                                                                    isDisabled: transactionPending || !!defaults?.description,
                                                                    required: descriptionRequired,
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field name="dueBy">
                                                        {({ field }: FieldProps) => (
                                                            <DueByField
                                                                {...{
                                                                    field: field,
                                                                    error: errors.dueBy,
                                                                    touched: !!touched.dueBy,
                                                                    setDueBy: apply(setFieldValue, 'dueBy'),
                                                                    isDisabled: transactionPending,
                                                                    label: 'Due Date',
                                                                    placement: 'top-right',
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {isHybridInvoice ? (
                                                        <>
                                                            <Divider color={'gray.200'} />
                                                            <NewInvoiceLabelText>
                                                                Line Items
                                                                <QuestionOutlineIcon ml="2" color={'gray.400'} />
                                                            </NewInvoiceLabelText>
                                                            <Box>
                                                                <Field name="lineItems">
                                                                    {({ field }: FieldProps) => (
                                                                        <LineItemsSection
                                                                            {...{
                                                                                claimType: 'Invoice',
                                                                                networkOverride: defaults?.network,
                                                                                field,
                                                                                isDisabled: transactionPending,
                                                                                includeNativeToken,
                                                                                error: errors.claimAmount,
                                                                                touched: touched.claimAmount,
                                                                                setAmount: apply(setFieldValue, 'claimAmount'),
                                                                                setToken: apply(setFieldValue, 'token'),
                                                                                setLineItems: apply(setFieldValue, 'lineItems'),
                                                                                token: values.token,
                                                                                setFieldTouched,
                                                                                lockToken: !!defaults?.token,
                                                                                label: 'Token',
                                                                                tax: values.tax,
                                                                                setTax: apply(setFieldValue, 'tax'),
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Box>
                                                        </>
                                                    ) : (
                                                        <Field name="claimAmount">
                                                            {({ field }: FieldProps) => (
                                                                <ClaimAmountField
                                                                    {...{
                                                                        claimType: 'Invoice',
                                                                        networkOverride: defaults?.network,
                                                                        field,
                                                                        isDisabled: transactionPending,
                                                                        includeNativeToken,
                                                                        error: errors.claimAmount,
                                                                        touched: touched.claimAmount,
                                                                        setAmount: apply(setFieldValue, 'claimAmount'),
                                                                        setToken: apply(setFieldValue, 'token'),
                                                                        amount: values.claimAmount,
                                                                        token: values.token,
                                                                        setFieldTouched,
                                                                        lockToken: !!defaults?.token,
                                                                        lockAmount: !!defaults?.claimAmount,
                                                                        label: 'Token',
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    )}
                                                </Stack>
                                                <Stack spacing="3" mt="4">
                                                    <Divider color={'gray.200'} />
                                                    <NewInvoiceLabelText>
                                                        Additional Details
                                                        <QuestionOutlineIcon ml="2" color={'gray.400'} />
                                                    </NewInvoiceLabelText>
                                                    <Field name="attachment">
                                                        {({ field }: FieldProps) => (
                                                            <AttachmentField
                                                                field={field}
                                                                amount={values.claimAmount}
                                                                description={values.description}
                                                                recipient={values.recipient}
                                                                tokenSymbol={values.token.symbol}
                                                                type={'Invoice'}
                                                                label="Attachment"
                                                                transactionPending={transactionPending}
                                                                attachment={values.attachment}
                                                                setAttachment={(file: BullaItemAttachment | undefined) =>
                                                                    setFieldValue('attachment', file)
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field name="tags">
                                                        {({ field }: FieldProps) => (
                                                            <AccountTagField
                                                                {...{
                                                                    field,
                                                                    isDisabled: transactionPending,
                                                                    error: errors.tags,
                                                                    touched: touched.tags,
                                                                    setTags: apply(setFieldValue, field.name),
                                                                    setStatus,
                                                                    label: 'Categories',
                                                                    creatingExpense: isExpense,
                                                                    mb: '-2',
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {membership !== null && (
                                                        <Field name="notes">
                                                            {({ field }: FieldProps) => (
                                                                <ClaimNotesField
                                                                    {...{
                                                                        field,
                                                                        isDisabled: transactionPending,
                                                                        error: errors.notes,
                                                                        touched: touched.notes,
                                                                        label: 'Notes',
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    )}
                                                    <Field name="emailCC" unmountOnExit>
                                                        {({ field }: FieldProps) => (
                                                            <EmailField
                                                                {...{
                                                                    field,
                                                                    isDisabled: transactionPending,
                                                                    error: errors.emailCC,
                                                                    touched: touched.emailCC,
                                                                    label: 'Confirmation Email',
                                                                    required: false,
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Stack>
                                            </GridItem>
                                        </Grid>

                                        <Divider width="calc(100%)" sx={{ height: '0.5px' }} mb="6" />

                                        <Flex justifyContent={'flex-end'}>
                                            <WhiteButton
                                                onClick={() => {
                                                    handleCancel();
                                                    navigate('/');
                                                }}
                                                w="min-content"
                                                mr="4"
                                            >
                                                Cancel
                                            </WhiteButton>

                                            {defaults?.network !== undefined && connectedNetwork !== defaults.network ? (
                                                <SwitchNetworksButton requiredNetwork={defaults.network!} />
                                            ) : (
                                                <OrangeButton
                                                    w={['100%', 'inherit', 'inherit']}
                                                    type="submit"
                                                    isLoading={isLoading}
                                                    isDisabled={isDisabled}
                                                >
                                                    Create
                                                </OrangeButton>
                                            )}
                                        </Flex>
                                        {vendorFinancingEnabled && (
                                            <EditFinancingTermsModal
                                                isOpen={showFinancingOptions}
                                                onClose={() => setShowFinancingOptions(false)}
                                                height={pageRef?.current?.getBoundingClientRect().height}
                                                terms={financingTerms}
                                                token={values.token}
                                                claimAmount={values.claimAmount}
                                                setClaimAmount={apply(setFieldValue, 'claimAmount')}
                                                onSubmit={terms => {
                                                    setFinancingTerms(terms);
                                                    setShowFinancingOptions(false);
                                                }}
                                            />
                                        )}
                                    </Form>
                                </>
                            );
                        }}
                    </Formik>
                </MaxWidthWrapper>
            </Flex>
        </PageLayoutProvider>
    );
};
