import { Alert, AlertIcon, Box, Container, HStack, ModalBody, Spacer, Stack, Text } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BullaSwapInfoWithUSDMark } from '../../../data-lib/data-model';
import { addressEquality, weiToDisplayAmt } from '../../../data-lib/ethereum';
import { NETWORKS, TokenDto } from '../../../data-lib/networks';
import { useAllowances } from '../../../hooks/useAllowances';
import { useBullaSwap } from '../../../hooks/useBullaSwap';
import { useCanChangeNetwork } from '../../../hooks/useCanChangeNetwork';
import { useTokenBalances } from '../../../hooks/useChainData';
import { useGnosisTransaction } from '../../../hooks/useGnosisTransaction';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { toDateWithTime, toUSD } from '../../../tools/common';
import { ChakraCompose } from '../../../tools/types';
import { TXHashLabel } from '../../base/address-label';
import { WithSkeleton } from '../../base/skeleton';
import { ChainSymbol } from '../../chain-symbol';
import { OrangeButton, SecondaryButton, TextButton } from '../../inputs/buttons';
import { CloseModalButton, ModalFooterWithShadow, ShareItemButton } from '../common';
import { InfoLabel, ItemDesc, PartyInfo, claimDetailVariants } from './item-details-components';

interface OfferDisplayProps {
    label: string;
    amount: BigNumber;
    token: {
        token: TokenDto;
    };
}

export const OfferDisplay: React.FC<OfferDisplayProps> = ({ label, amount, token }) => {
    const formattedAmount = formatUnits(amount, token.token.decimals);

    return (
        <Box ml="2">
            <InfoLabel>{label}</InfoLabel>
            <Text fontWeight={700} as="span" color="gray.700">
                {`${formattedAmount} ${token.token.symbol}`}
            </Text>
        </Box>
    );
};

interface USDInfoProps {
    usdMark: string | number;
    amount?: number;
}

const USDInfo: React.FC<USDInfoProps> = ({ usdMark, amount }) => {
    const isLoading = usdMark === 'Loading' || usdMark === 'Not Found';

    return (
        <>
            <WithSkeleton isLoading={usdMark === 'Loading'} fixedWidth="16em" height="32px">
                <Box ml="2">
                    <HStack>
                        <InfoLabel>USD Mark</InfoLabel>
                    </HStack>
                    <Text fontWeight={700} as="span" color="gray.700">
                        {isLoading ? usdMark : toUSD(Number(usdMark))}
                    </Text>
                </Box>
            </WithSkeleton>
            <WithSkeleton isLoading={usdMark === 'Loading'} fixedWidth="16em" height="32px">
                <Box ml="2">
                    <HStack>
                        <InfoLabel>USD Value</InfoLabel>
                    </HStack>
                    <Text fontWeight={700} as="span" color="gray.700">
                        {isLoading ? usdMark : toUSD(amount ? amount * Number(usdMark) : Number(usdMark) * Number(usdMark))}
                    </Text>
                </Box>
            </WithSkeleton>
        </>
    );
};

type OfferBoxLabels = {
    swap: BullaSwapInfoWithUSDMark;
};

const OfferBox = ({ swap, ...overrides }: OfferBoxLabels & ChakraCompose) => {
    const isMobile = useIsMobile();
    const leftRef = useRef<HTMLDivElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);
    const signerAmountDisplayNumber = weiToDisplayAmt({ amountWei: swap.signerAmount, token: swap.signerToken.token });
    const senderAmountDisplayNumber = weiToDisplayAmt({ amountWei: swap.senderAmount, token: swap.senderToken.token });
    const usdMarkSignerDisplay =
        swap.USDMarkSignerToken === 'fetching'
            ? 'Loading'
            : swap.USDMarkSignerToken === 'not-found'
            ? 'Not Found'
            : swap.USDMarkSignerToken;
    const usdMarkSenderDisplay =
        swap.USDMarkSenderToken === 'fetching'
            ? 'Loading'
            : swap.USDMarkSenderToken === 'not-found'
            ? 'Not Found'
            : swap.USDMarkSenderToken;
    const isExecuted = swap.status === 'Executed';

    const leftHeight = leftRef.current?.getBoundingClientRect().height;
    const rightHeight = rightRef.current?.getBoundingClientRect().height;

    const boxHeight = useMemo(() => {
        if (!!leftHeight && !!rightHeight) return Math.max(leftHeight, rightHeight);
        return 0;
    }, [leftHeight, rightHeight]);

    return (
        <HStack py="4" my="2" spacing="4" alignItems={'start'} h="fit-content" justifyContent={'space-between'} {...overrides}>
            <Stack minW="10em">
                <Text fontSize={'18px'} fontWeight={700} color="gray.700" lineHeight={'28px'}>
                    Offered by
                </Text>
                <Box h={boxHeight}>
                    <PartyInfo walletOrEmailAddress={swap.signerWallet} chainId={swap.chainId} ref={leftRef} />
                    <Spacer />
                </Box>
                <Spacer />
                <OfferDisplay label="Offer" amount={swap.signerAmount} token={swap.signerToken} />
                <USDInfo usdMark={usdMarkSignerDisplay} amount={signerAmountDisplayNumber} />
            </Stack>
            <Box borderWidth="1px" borderLeftColor="brand.bulla_grey" alignSelf={'stretch'} />
            <Stack minW="10em">
                <Text fontSize={'18px'} fontWeight={700} color="gray.700" lineHeight={'28px'}>
                    {isExecuted ? 'Accepted by' : 'To Execute'}
                </Text>
                <Box h={boxHeight}>
                    <PartyInfo walletOrEmailAddress={swap.senderWallet} chainId={swap.chainId} ref={rightRef} />
                    <Spacer />
                </Box>
                <Spacer />
                <OfferDisplay label={isExecuted ? 'Sent' : 'In exchange for'} amount={swap.senderAmount} token={swap.senderToken} />
                <USDInfo usdMark={usdMarkSenderDisplay} amount={senderAmountDisplayNumber} />
            </Stack>
            {!isMobile && <Box />}
        </HStack>
    );
};

export type SwapDetailsProps = {
    swap: BullaSwapInfoWithUSDMark;
    handleClose: () => void;
    modalContentRef?: React.RefObject<HTMLDivElement>;
};

export const SwapDetails = ({ swap, handleClose }: SwapDetailsProps) => {
    const { blockExplorer } = NETWORKS[swap.chainId!];
    const bodyRef = React.useRef<HTMLDivElement>(null);
    const actingWallet = useActingWalletAddress();
    const [executing, { executeOrder, deleteOrder, executeOrderInputToMultisendTxDTO, deleteOrderInputToMultisendTxDTO }] = useBullaSwap();
    const {
        connectedNetwork,
        connectedNetworkConfig: { label, bullaSwap },
    } = useWeb3();
    const canChangeNetwork = useCanChangeNetwork();
    const { changeNetwork } = useOnboard();
    const { safeInfo } = useGnosisSafe();
    const [executingSafeTx, setExecutingSafeTx] = useState<boolean>(false);
    const [allowance, setAllowance] = React.useState<'init' | BigNumber>('init');
    const [approvalPending, { getAllowanceForTokenAddress, approveTokens, approveModalOpen, closeApproveModal }] =
        useAllowances('exact-allowance');
    const isLoading = approvalPending || executingSafeTx || allowance == 'init' || executing;
    const isUserDueToExecute = addressEquality(actingWallet, swap.senderWallet);
    const [insufficientFunds, setInsufficientFunds] = React.useState<boolean>();
    const tokenBalances = useTokenBalances({ chainId: swap.chainId, poll: true });
    const { executeTransactions } = useGnosisTransaction();

    useEffect(() => {
        if (isUserDueToExecute && tokenBalances) {
            const insufficientFundsResult: boolean =
                +(tokenBalances.getBalanceForToken(swap.senderToken.token.address) ?? 0) <
                +formatUnits(swap.senderAmount, swap.senderToken.token.decimals);
            setInsufficientFunds(insufficientFundsResult);
        }
    }, [swap.senderAmount, tokenBalances]);

    const isApproved = useMemo(() => {
        if (allowance === 'init' || !isUserDueToExecute) return false;
        return allowance.gte(swap.senderAmount);
    }, [allowance, swap.senderAmount, swap.senderToken.token.decimals]);

    useEffect(() => {
        if (isUserDueToExecute) getAllowanceForTokenAddress(bullaSwap!, swap.chainId)(swap.senderToken.token.address).then(setAllowance);
    }, [swap.senderToken.token.address, swap.chainId]);

    const handleExecuteOrder = async () => {
        if (safeInfo) {
            setExecutingSafeTx(true);
            try {
                const { transaction } = await executeOrderInputToMultisendTxDTO(
                    bullaSwap!,
                    swap.orderId,
                    swap.senderAmount,
                    swap.senderToken.token,
                );
                const { success } = await executeTransactions(safeInfo, [transaction], true);
                if (success) handleClose();
            } finally {
                setExecutingSafeTx(false);
            }
        } else if (isApproved) {
            await executeOrder(swap.orderId).then(success => {
                if (success) handleClose();
            });
        } else {
            await approveTokens(bullaSwap!)([
                {
                    amount: swap.senderAmount,
                    token: swap.senderToken.token,
                },
            ]).then(({ success }) => {
                if (success) {
                    setAllowance(swap.senderAmount);
                }
            });
        }
    };

    const handleDeleteOrder = async () => {
        if (!safeInfo) {
            deleteOrder(swap.orderId).then(success => {
                if (success) handleClose();
            });
        } else {
            setExecutingSafeTx(true);
            try {
                const { transaction } = await deleteOrderInputToMultisendTxDTO(bullaSwap!, swap.orderId);
                const { success } = await executeTransactions(safeInfo, [transaction], true);
                if (success) handleClose();
            } finally {
                setExecutingSafeTx(false);
            }
        }
    };

    const buttonGroup = (
        <HStack spacing="4" justify="center" minH={'50px'} px="2em" width="full">
            {swap.status == 'Pending' && (
                <>
                    <SecondaryButton
                        isLoading={executing}
                        onClick={handleDeleteOrder}
                        textDecoration="none"
                        paddingLeft="16px"
                        paddingRight="16px"
                        isDisabled={swap.chainId !== connectedNetwork}
                        w="100%"
                    >
                        {isUserDueToExecute ? 'Reject offer' : 'Rescind offer'}
                    </SecondaryButton>
                    {isUserDueToExecute && (
                        <OrangeButton
                            isLoading={isLoading}
                            onClick={handleExecuteOrder}
                            isDisabled={swap.chainId !== connectedNetwork || insufficientFunds}
                            minW={'140px'}
                            w="100%"
                        >
                            {insufficientFunds
                                ? 'Insufficient funds'
                                : isApproved || safeInfo
                                ? 'Execute Swap'
                                : 'Execute Swap (Approve Token)'}
                        </OrangeButton>
                    )}
                </>
            )}
        </HStack>
    );

    const wrongNetworkLabel = swap.chainId !== connectedNetwork && swap.status === 'Pending' && (
        <Container w="fit-content" py="0">
            <Alert status="warning" bg={'white'} py="0">
                <AlertIcon />
                <span>
                    You are connected to {label} network.{' '}
                    {canChangeNetwork ? (
                        <TextButton onClick={() => changeNetwork(swap.chainId)}>{`Switch to ${NETWORKS[swap.chainId].label}`}</TextButton>
                    ) : (
                        `Switch to ${NETWORKS[swap.chainId].label}`
                    )}{' '}
                    to execute swap.
                </span>
            </Alert>
        </Container>
    );

    return (
        <>
            <CloseModalButton onClose={handleClose} zIndex={'modal'} />
            <ModalBody pb={10} pt={4} ref={bodyRef} width="200%" px="0" overflowY="auto">
                <AnimatePresence initial={false}>
                    <motion.div
                        key="details"
                        style={{
                            width: `50%`,
                            display: 'inline-block',
                            float: 'left',
                            padding: '0 2em',
                            maxHeight: 'fit-content',
                            height: '100%',
                        }}
                        initial={'active'}
                        animate={'active'}
                        transition={{
                            x: { type: 'just' },
                        }}
                        variants={claimDetailVariants}
                    >
                        <Text color="gray.700" fontWeight={'700'} fontSize="24px" noOfLines={1} lineHeight="32px">
                            Swap {swap.status == 'Pending' ? 'Request' : 'Details'}
                            <ShareItemButton item={swap} />
                        </Text>

                        <Box h="16px" />
                        <Stack spacing="3">
                            <ItemDesc title="Chain">
                                <HStack>
                                    <ChainSymbol chainId={swap.chainId} />
                                    <Text>{NETWORKS[swap.chainId].label}</Text>
                                </HStack>
                            </ItemDesc>
                            <ItemDesc title="Date">{toDateWithTime(swap.created)}</ItemDesc>
                            <ItemDesc title="Tx Hash">
                                <HStack>
                                    <TXHashLabel fontWeight={700} blockExplorerURL={blockExplorer} txHash={swap.txHash} as="b" />
                                </HStack>
                            </ItemDesc>
                            <ItemDesc title="Status">
                                <HStack>
                                    <Text>{swap.status.toString()}</Text>
                                </HStack>
                            </ItemDesc>
                            <OfferBox swap={swap} />
                        </Stack>
                        <Box h="2" />
                    </motion.div>
                </AnimatePresence>
            </ModalBody>
            <ModalFooterWithShadow>
                <Stack w="100%" spacing={4} alignItems="center">
                    {buttonGroup}
                    {wrongNetworkLabel}
                </Stack>
            </ModalFooterWithShadow>
        </>
    );
};
