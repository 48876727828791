import { BigNumber } from 'ethers';
import { getBullaSwapContract, IBullaSwap } from '../data-lib/dto/contract-interfaces';
import { EthAddress, toChecksumAddress } from '../data-lib/ethereum';
import { TokenDto, TXStatus } from '../data-lib/networks';
import {
    MinimalGnosisItemInfo,
    MultisendCreateOrderTransaction,
    MultisendDeleteOrderTransaction,
    MultisendExecuteOrderTransaction,
} from './useGnosisMultisend';
import { useSendTransaction } from './useSendTransaction';
import { useWeb3 } from './useWeb3';

export type CreateOrderParams = {
    expiry: BigNumber;
    signerWallet: string;
    signerToken: string;
    signerAmount: BigNumber;
    senderWallet: string;
    senderToken: string;
    senderAmount: BigNumber;
};

export const useBullaSwap = () => {
    const [pending, sendTransaction] = useSendTransaction();
    const {
        connectedNetworkConfig: { bullaSwap },
    } = useWeb3();

    const createOrder = async (order: CreateOrderParams) => {
        try {
            const transactionResult = await sendTransaction(
                signer => getBullaSwapContract(bullaSwap!).connect(signer).createOrder(order),
                false,
            );
            return transactionResult?.status == TXStatus.SUCCESS ? { success: true, transactionResult } : { success: false };
        } catch (e) {
            console.error(e);
            return { success: false };
        }
    };

    const executeOrder = async (orderId: BigNumber) => {
        try {
            await sendTransaction(signer => getBullaSwapContract(bullaSwap!).connect(signer).executeOrder(orderId), false);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const deleteOrder = async (orderId: BigNumber) => {
        try {
            await sendTransaction(signer => getBullaSwapContract(bullaSwap!).connect(signer).deleteOrder(orderId), false);
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const deleteOrderInputToMultisendTxDTO = async (
        bullaSwapAddress: EthAddress,
        orderId: BigNumber,
    ): Promise<{ transaction: MultisendDeleteOrderTransaction }> => {
        return { transaction: buildDeleteOrderMultisendTx(bullaSwapAddress, orderId) };
    };

    function buildDeleteOrderMultisendTx(bullaSwapAddress: EthAddress, orderId: BigNumber): MultisendDeleteOrderTransaction {
        const itemInfo: MinimalGnosisItemInfo = {
            __type: 'DeleteOrder',
            description: 'Bulla Swap Delete Order',
            creditor: '',
            debtor: '',
            id: '',
        };

        return {
            label: `Delete Swap Order`,
            transactionInput: getDeleteOrderTransaction(bullaSwapAddress, orderId),
            itemInfo,
            interaction: 'Delete Swap Order',
        };
    }

    const getDeleteOrderTransaction = (_bullaSwapAddress: EthAddress, orderId: BigNumber) => {
        const bullaSwapAddress = toChecksumAddress(_bullaSwapAddress);
        const value = '0';

        return {
            to: bullaSwapAddress,
            value,
            operation: 0,
            data: IBullaSwap.encodeFunctionData('deleteOrder', [orderId]),
        };
    };

    const executeOrderInputToMultisendTxDTO = async (
        bullaSwapAddress: EthAddress,
        orderId: BigNumber,
        senderAmount: BigNumber,
        senderToken: TokenDto,
    ): Promise<{ transaction: MultisendExecuteOrderTransaction }> => {
        return { transaction: buildExecuteOrderMultisendTx(bullaSwapAddress, orderId, senderAmount, senderToken) };
    };

    function buildExecuteOrderMultisendTx(
        bullaSwapAddress: EthAddress,
        orderId: BigNumber,
        senderAmount: BigNumber,
        senderToken: TokenDto,
    ): MultisendExecuteOrderTransaction {
        const itemInfo: MinimalGnosisItemInfo = {
            __type: 'ExecuteOrder',
            description: 'Bulla Swap Execute Order',
            creditor: '',
            debtor: '',
            id: '',
        };

        return {
            label: `Execute Swap Order`,
            transactionInput: getExecuteOrderTransaction(bullaSwapAddress, orderId),
            itemInfo,
            interaction: 'Execute Swap Order',
            approvalNeeded: {
                amount: senderAmount,
                spendingContract: bullaSwapAddress,
            },
            token: senderToken,
        };
    }

    const getExecuteOrderTransaction = (_bullaSwapAddress: EthAddress, orderId: BigNumber) => {
        const bullaSwapAddress = toChecksumAddress(_bullaSwapAddress);
        const value = '0';

        return {
            to: bullaSwapAddress,
            value,
            operation: 0,
            data: IBullaSwap.encodeFunctionData('executeOrder', [orderId]),
        };
    };

    const createOrderInputToMultisendTxDTO = async (
        bullaSwapAddress: EthAddress,
        orderParams: CreateOrderParams,
        offerToken: TokenDto,
    ): Promise<{ transaction: MultisendCreateOrderTransaction }> => {
        return { transaction: buildCreateOrderMultisendTx(bullaSwapAddress, orderParams, offerToken) };
    };

    function buildCreateOrderMultisendTx(
        bullaSwapAddress: EthAddress,
        orderParams: CreateOrderParams,
        offerToken: TokenDto,
    ): MultisendCreateOrderTransaction {
        const itemInfo: MinimalGnosisItemInfo = {
            __type: 'CreateOrder',
            description: 'Bulla Swap Create Order',
            creditor: '',
            debtor: '',
            id: '',
        };

        return {
            label: `Create Swap Order`,
            transactionInput: getCreateOrderTransaction(bullaSwapAddress, orderParams),
            itemInfo,
            interaction: 'Create Swap Order',
            approvalNeeded: {
                amount: orderParams.signerAmount,
                spendingContract: bullaSwapAddress,
            },
            token: offerToken,
        };
    }

    const getCreateOrderTransaction = (_bullaSwapAddress: EthAddress, orderParams: CreateOrderParams) => {
        const bullaSwapAddress = toChecksumAddress(_bullaSwapAddress);
        const value = '0';

        return {
            to: bullaSwapAddress,
            value,
            operation: 0,
            data: IBullaSwap.encodeFunctionData('createOrder', [orderParams]),
        };
    };

    return [
        pending,
        {
            createOrder,
            executeOrder,
            deleteOrder,
            createOrderInputToMultisendTxDTO,
            executeOrderInputToMultisendTxDTO,
            deleteOrderInputToMultisendTxDTO,
        },
    ] as const;
};
