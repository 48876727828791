import { BigNumber } from 'ethers';
import { MetaTransaction } from 'ethers-multisend';
import React, { useMemo } from 'react';
import { BullaItemInfo, BullaItemType } from '../data-lib/data-model';
import { EthAddress } from '../data-lib/ethereum';
import { TokenDto } from '../data-lib/networks';
import { useGnosisSafe } from '../state/gnosis-state';
import { quickHash } from '../tools/common';
import { Replace } from '../tools/types';
import { useGnosisTransaction } from './useGnosisTransaction';
import { useWeb3 } from './useWeb3';

type TransactionHash = string;

export type MinimalGnosisItemInfo = {
    __type:
        | BullaItemType
        | 'FrendLend'
        | 'Deposit'
        | 'Redeem'
        | 'FundInvoice'
        | 'ApproveInvoice'
        | 'UnfactorInvoice'
        | 'CreateOrder'
        | 'ExecuteOrder'
        | 'DeleteOrder';
} & Pick<BullaItemInfo, 'description' | 'creditor' | 'debtor' | 'id'>;

export type NonPayingMultisendTransaction = {
    label: string;
    transactionInput: MetaTransaction;
    itemInfo: MinimalGnosisItemInfo;
    interaction: 'Reject' | 'Rescind' | 'Update Tag' | 'Reject Loan Offer' | 'Rescind Loan Offer' | 'Claim Financing';
};

export type MultisendPayTransaction = {
    label: string;
    transactionInput: MetaTransaction;
    itemInfo: MinimalGnosisItemInfo;
    interaction: 'Pay' | 'Accept Financing';
    paymentAmount: BigNumber;
    token: TokenDto;
    approvalNeeded: {
        amount: BigNumber;
        spendingContract: EthAddress;
    };
};

export type MultisendOfferLoanTransaction = {
    interaction: 'Offer Loan';
} & Omit<MultisendPayTransaction, 'paymentAmount' | 'interaction'>;

export type MultisendCreateOrderTransaction = {
    interaction: 'Create Swap Order';
} & Omit<MultisendPayTransaction, 'paymentAmount' | 'interaction'>;

export type MultisendExecuteOrderTransaction = {
    interaction: 'Execute Swap Order';
} & Omit<MultisendPayTransaction, 'paymentAmount' | 'interaction'>;

export type MultisendDeleteOrderTransaction = {
    interaction: 'Delete Swap Order';
} & Omit<NonPayingMultisendTransaction, 'paymentAmount' | 'interaction'>;

export type MultisendDepositTransaction = {
    interaction: 'Deposit to Bulla Fund';
    depositAmount: BigNumber;
} & Omit<MultisendPayTransaction, 'paymentAmount' | 'interaction'>;

export type MultisendRedeemTransaction = {
    label: string;
    transactionInput: MetaTransaction;
    itemInfo: MinimalGnosisItemInfo;
    interaction: 'Redeem from Bulla Fund';
    redeemAmount: BigNumber;
    token: TokenDto;
};

export type MultisendFundInvoiceTransaction = {
    label: string;
    transactionInput: MetaTransaction;
    itemInfo: MinimalGnosisItemInfo;
    interaction: 'Fund Invoice';
    invoiceId: BigNumber;
    factorerUpfrontBps: number;
};

export type MultisendUnfactorInvoiceTransaction = {
    label: string;
    transactionInput: MetaTransaction;
    itemInfo: MinimalGnosisItemInfo;
    interaction: 'Unfactor Invoice';
    invoiceId: BigNumber;
    approvalNeeded: {
        amount: BigNumber;
        spendingContract: EthAddress;
    };
    token: TokenDto;
};

export type MultisendApproveInvoiceTransaction = {
    label: string;
    transactionInput: MetaTransaction;
    itemInfo: MinimalGnosisItemInfo;
    interaction: 'Approve Invoice';
    invoiceId: BigNumber;
};

export type MultisendAcceptLoanTransaction = Replace<NonPayingMultisendTransaction, 'interaction', 'Accept Loan'>;

export type MultisendRejectLoanTransaction = Replace<NonPayingMultisendTransaction, 'interaction', 'Accept Loan'>;

export type MultisendTransaction =
    | NonPayingMultisendTransaction
    | MultisendPayTransaction
    | MultisendOfferLoanTransaction
    | MultisendAcceptLoanTransaction
    | MultisendDepositTransaction
    | MultisendRedeemTransaction
    | MultisendFundInvoiceTransaction
    | MultisendApproveInvoiceTransaction
    | MultisendUnfactorInvoiceTransaction
    | MultisendCreateOrderTransaction
    | MultisendExecuteOrderTransaction
    | MultisendDeleteOrderTransaction;

export type ItemInteraction = MultisendTransaction['interaction'];

const getTransactionKey = (transaction: MultisendTransaction) => quickHash(transaction.transactionInput.data);

export type MultisendContext = {
    isLoading: boolean;
    error: string | undefined;
    transactions: Record<TransactionHash, MultisendTransaction>;
    getTransactionKey: typeof getTransactionKey;
    addTransaction: (tx: MultisendTransaction) => void;
    removeTransaction: (tx: MultisendTransaction) => void;
    sendTransaction: (includeDescription: boolean, theseTransactions?: MultisendTransaction[]) => Promise<{ success: boolean }>;
    isTokenIdInBundle: (tokenId: string) => boolean;
    isFull: boolean;
};
export const MultisendContext = React.createContext<MultisendContext>({
    isLoading: false,
    error: undefined,
    transactions: {},
    getTransactionKey,
    addTransaction: () => {},
    removeTransaction: () => {},
    sendTransaction: _ => Promise.resolve({ success: false }),
    isTokenIdInBundle: _ => false,
    isFull: false,
});

export const useGnosisMultisendInstance = (): MultisendContext => {
    const { safeInfo } = useGnosisSafe();
    const {
        connectedNetworkConfig: { batchCreate },
    } = useWeb3();

    const [transactions, setTransactions] = React.useState<Record<TransactionHash, MultisendTransaction>>({});
    const { loading: isLoading, error, executeTransactions } = useGnosisTransaction();

    /** Hash the txData as the key */
    const addTransaction = (transaction: MultisendTransaction) =>
        setTransactions(txns => ({ ...txns, [getTransactionKey(transaction)]: transaction }));

    const removeTransaction = (transaction: MultisendTransaction) => {
        setTransactions(txns => {
            const { [getTransactionKey(transaction)]: deletedTransaction, ...newTransactions } = txns;
            return newTransactions;
        });
    };

    const sendTransaction = async (includeDescription: boolean, theseTransactions?: MultisendTransaction[]) => {
        if (safeInfo) {
            const txs = theseTransactions ?? Object.values(transactions);
            const { success } = await executeTransactions(safeInfo, txs, includeDescription);
            if (success) setTransactions({});
            return { success };
        }
        return { success: false };
    };

    const isTokenIdInBundle = (tokenId: string) => !!Object.values(transactions).find(tx => tx.itemInfo?.id === tokenId);
    const isFull = batchCreate && Object.values(transactions).length == batchCreate.maxClaims * 2;

    return {
        isLoading,
        addTransaction,
        transactions,
        removeTransaction,
        getTransactionKey,
        error,
        sendTransaction,
        isTokenIdInBundle,
        isFull,
    };
};

export const MultisendProvider = ({ children }: { children: React.ReactNode }) => {
    const {
        isLoading,
        addTransaction,
        transactions,
        removeTransaction,
        getTransactionKey,
        error,
        sendTransaction,
        isTokenIdInBundle,
        isFull,
    } = useGnosisMultisendInstance();
    const context = useMemo(
        () => ({
            isLoading,
            addTransaction,
            transactions,
            removeTransaction,
            getTransactionKey,
            error,
            sendTransaction,
            isTokenIdInBundle,
            isFull,
        }),
        [isLoading, addTransaction, transactions, removeTransaction, error, sendTransaction],
    );

    return <MultisendContext.Provider value={context}>{children}</MultisendContext.Provider>;
};

export const useGnosisGlobalMultisend = () => {
    const context = React.useContext(MultisendContext);
    if (context === undefined) throw new Error('useGnosisMultisend the MultisendProvider');
    return context;
};
