import axios from 'axios';
import { useMemo } from 'react';
import { WalletChainSelection } from '../components/modals/common-reporting/add-wallets-chains-card';
import { ExternalTxServiceResponse, TransferMetadata } from '../data-lib/dto/external-transactions-dto';
import { ChainId, TokenInfo } from '../data-lib/networks';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints } from '../tools/common';
import { useCurrentChainUserData } from './useUserData';
import { useWeb3 } from './useWeb3';
import { LedgerAccountAdjustments } from '../components/modals/ledger-export-wizard-modal/ledger-account';

export const getBackendTxIdForItem = (id: string, chainId?: ChainId) => `${id}${!!chainId ? `#${chainId}` : ''}`;

export type MinimalTokenDto = { address: string; decimals: number; symbol: string };

export type ClaimLedgerEntryDto = {
    chainId: number;
    claimId: string;
    amount: string;
    created: number;
    eventName: string;
    eventTimestamp: number;
    token: MinimalTokenDto;
    debtor: string;
    creditor: string;
    description: string;
    categories: string;
    usdMark: string | null;
    usdValue: string | null;
    txHash: string;
    direction: 'Payable' | 'Receivable' | 'OriginalCreditorOfFactoredClaim' | 'Transfer';
    adjustments: LedgerAccountAdjustments;
};

export type PaymentLedgerEntryDto = {
    chainId: number;
    instantPaymentId: string;
    amountWei: string;
    timestamp: number;
    token: MinimalTokenDto;
    from: string;
    to: string;
    description: string;
    categories: string[];
    usdMark: string | null;
    usdValue: string | null;
    txHash: string;
    direction: 'Payable' | 'Receivable' | 'Transfer';
    adjustments: LedgerAccountAdjustments;
};

export type CashDisbursementDto = Omit<PaymentLedgerEntryDto, 'adjustments' | 'instantPaymentId'>;

export type LedgerExportResponse = {
    claimEntries: ClaimLedgerEntryDto[];
    paymentEntries: PaymentLedgerEntryDto[];
    cashTransfers: CashDisbursementDto[];
};

export type Build1099Request = {
    chainIds: ChainId[];
    ownAddresses: Set<string>;
    recipients: Set<string>;
};

export type _1099PaymentEntryDto = {
    chainId: number;
    amountWei: string;
    timestamp: number;
    token: MinimalTokenDto;
    from: string;
    to: string;
    description: string;
    categories: string[];
    txHash: string;
    direction: 'Payable' | 'Receivable' | 'Transfer';
};

export type Build1099Response = _1099PaymentEntryDto[];

type TokenStrategyDto = {
    kind: 'fixed-token-and-chain';
    tokenSymbol: string;
    tokenAddress: string;
    tokenDecimals: number;
    chainId: number;
    amountStrategy: {
        kind: 'token-amount';
        amount: string;
    };
};

export type CreateInvoiceRequestParams = {
    email: string;
    dueDate: number;
    description?: string;
    categories?: string[];
    notes?: string;
    tokenStrategy: TokenStrategyDto;
    confirmationEmail?: string;
    file?: { name: string; base64: string };
};

export type TokenStrategyInfo = {
    kind: 'fixed-token-and-chain';
    tokenInfo: TokenInfo;
    amountStrategy: {
        kind: 'token-amount';
        amount: string;
    };
};

export type OffchainInvoiceDto = {
    creditor: string;
    id: string;
    email: string;
    dueDate: number;
    description: string;
    categories: string[];
    notes?: string | null;
    tokenStrategy: TokenStrategyDto;
    created: number;
    paidDate?: number | null;
    debtor?: string | null;
    cancellationDate?: number | null;
    file?: { name: string };
};

export type OffchainInvoiceMetadataUpdate = {
    notes?: string;
    tags?: string[];
};

export const useExternalTransactionsApi = () => {
    const { connectedSafeAddress } = useGnosisSafe();
    const { connectedNetwork, userAddress } = useWeb3();
    const { refetchExternalTransactions } = useCurrentChainUserData();

    const externalTransactionsServiceEndpoint = useMemo(() => `${endpoints.externalTransactionsApi}/external-transactions`, []);

    const gnosisSafeQueryArgs = !!connectedSafeAddress ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';
    const actingWallet = !!connectedSafeAddress ? connectedSafeAddress : userAddress;

    const fetchExternalTransactions = async (network: ChainId): Promise<ExternalTxServiceResponse> => {
        const { data } = await axios.get<ExternalTxServiceResponse>(
            `${externalTransactionsServiceEndpoint}/${
                !!connectedSafeAddress ? connectedSafeAddress : userAddress
            }/chain/${network}${gnosisSafeQueryArgs}`,
            {
                withCredentials: true,
            },
        );
        const erc721Events = data.transactions.flatMap(x => x?.erc721TransferEvents) ?? [];
        if (erc721Events.length !== 0) console.log({ network, erc721: erc721Events });
        return data;
    };

    const saveExternalTransactions = async (
        externalTransactions: Record<string, TransferMetadata>,
        skipRefresh?: 'skip-refresh',
        chainId?: ChainId,
    ): Promise<boolean> => {
        const { data } = await axios.post(
            `${externalTransactionsServiceEndpoint}/${actingWallet}${gnosisSafeQueryArgs}`,
            externalTransactions,
            {
                withCredentials: true,
            },
        );
        await (skipRefresh !== 'skip-refresh' ? refetchExternalTransactions(chainId) : Promise.resolve());
        return true;
    };

    const hasFreeImport = async (): Promise<boolean> => {
        const {
            data: { hasFreeImport },
        } = await axios.get<{ hasFreeImport: boolean }>(
            `${externalTransactionsServiceEndpoint}/${actingWallet}/free-import${gnosisSafeQueryArgs}`,
            {
                withCredentials: true,
            },
        );

        return hasFreeImport;
    };

    const createOffchainInvoice = async (params: CreateInvoiceRequestParams): Promise<OffchainInvoiceDto | undefined> => {
        try {
            const { data } = await axios.post<{ invoice: OffchainInvoiceDto }>(
                `${endpoints.externalTransactionsApi}/invoice/${actingWallet}/create${gnosisSafeQueryArgs}`,
                params,
                {
                    withCredentials: true,
                },
            );

            console.debug(`Invoice created`, data);

            return data.invoice;
        } catch (e) {
            console.error('failed to create offchain invoice', e);
            return undefined;
        }
    };

    const fetchOffchainInvoices = async (): Promise<OffchainInvoiceDto[]> => {
        try {
            const { data } = await axios.get<{ invoices: OffchainInvoiceDto[] }>(
                `${endpoints.externalTransactionsApi}/invoice/${actingWallet}${gnosisSafeQueryArgs}`,
                {
                    withCredentials: true,
                },
            );

            console.debug(`fetched invoices`, data.invoices);

            return data.invoices;
        } catch (e) {
            console.error('error while fetching offchain invoices', e);
            return [];
        }
    };

    const fetchOffchainInvoiceById = async (invoiceId: string, chainId: ChainId): Promise<OffchainInvoiceDto | undefined> => {
        try {
            const { data } = await axios.get<{ invoice: OffchainInvoiceDto }>(
                `${endpoints.externalTransactionsApi}/invoice/${actingWallet}/chainId/${chainId}/id/${invoiceId}${gnosisSafeQueryArgs}`,
                {
                    withCredentials: true,
                },
            );

            console.debug(`fetched invoice`, data.invoice);

            return data.invoice;
        } catch (e) {
            console.warn('error while fetching offchain invoices', e);
            return undefined;
        }
    };

    const fetchUnassignedOffchainInvoices = async (invoiceIds: string[], chainId: ChainId): Promise<OffchainInvoiceDto[]> => {
        try {
            const { data } = await axios.post<{ invoices: OffchainInvoiceDto[] }>(
                `${endpoints.externalTransactionsApi}/invoice/${actingWallet}/debtor/chainId/${chainId}${gnosisSafeQueryArgs}`,
                { invoiceIds },
                {
                    withCredentials: true,
                },
            );

            return data.invoices;
        } catch (e) {
            console.warn('error while fetching offchain invoices', e);
            return [];
        }
    };

    const getAttachmentUriForInvoice = (invoiceId: string): string =>
        `${endpoints.externalTransactionsApi}/invoice/${actingWallet}/id/${invoiceId}/file${gnosisSafeQueryArgs}`;

    const cancelInvoice = async (invoiceId: string): Promise<OffchainInvoiceDto | undefined> => {
        try {
            const { data } = await axios.delete<{ invoice: OffchainInvoiceDto }>(
                `${endpoints.externalTransactionsApi}/invoice/${actingWallet}/id/${invoiceId}${gnosisSafeQueryArgs}`,
                {
                    withCredentials: true,
                },
            );

            console.debug(`fetched invoice`, data.invoice);

            return data.invoice;
        } catch (e) {
            console.warn('error while fetching canceling invoice', e);
            return undefined;
        }
    };

    const updateMetadata = async (
        metadataUpdatesByInvoiceId: Record<string, OffchainInvoiceMetadataUpdate>,
    ): Promise<OffchainInvoiceDto[]> => {
        try {
            const { data } = await axios.post<{ invoices: OffchainInvoiceDto[] }>(
                `${endpoints.externalTransactionsApi}/invoice/${actingWallet}/metadata${gnosisSafeQueryArgs}`,
                metadataUpdatesByInvoiceId,
                {
                    withCredentials: true,
                },
            );

            return data.invoices;
        } catch (e) {
            console.warn('error while updating offchain invoice metadata', e);
            return [];
        }
    };

    const buildLedgerExport = async (ledgerExportRequest: WalletChainSelection): Promise<LedgerExportResponse> => {
        try {
            const { data } = await axios.post<LedgerExportResponse>(
                `${endpoints.externalTransactionsApi}/report/ledger/${actingWallet}${gnosisSafeQueryArgs}`,
                { ...ledgerExportRequest, addresses: [...ledgerExportRequest.addresses] },
                {
                    withCredentials: true,
                },
            );

            return data;
        } catch (e) {
            console.warn('error while building ledger export', e);
            return { claimEntries: [], paymentEntries: [], cashTransfers: [] };
        }
    };

    const build1099Export = async (build1099Request: Build1099Request): Promise<Build1099Response> => {
        try {
            const { data } = await axios.post<Build1099Response>(
                `${endpoints.externalTransactionsApi}/report/1099/${actingWallet}${gnosisSafeQueryArgs}`,
                {
                    ...build1099Request,
                    ownAddresses: [...build1099Request.ownAddresses],
                    recipients: [...build1099Request.recipients],
                },
                {
                    withCredentials: true,
                },
            );

            return data;
        } catch (e) {
            console.warn('error while building 1099 export', e);
            return [];
        }
    };

    return {
        fetchExternalTransactions,
        saveExternalTransactions,
        hasFreeImport,
        createOffchainInvoice,
        fetchOffchainInvoices,
        fetchOffchainInvoiceById,
        fetchUnassignedOffchainInvoices,
        getAttachmentUriForInvoice,
        cancelInvoice,
        updateMetadata,
        buildLedgerExport,
        build1099Export,
    };
};
