import { BigNumber } from 'ethers';
import moment from 'moment';
import { API_ENDPOINTS } from '../data-lib/endpoints';
import { TransactionHash } from '../state/ui-state';
import { Truthy } from './types';
import { EthAddress } from '../data-lib/ethereum';
import { COOKIE_KEYS } from './storage';
import { CUSTODIAN_IDS } from '../hooks/useCustodianApi';
import { IN_DEV_ENV, IN_STAGING_ENV } from './env-constants';

export const BULLA_NETWORK_DISCORD_INVITE = 'https://discord.gg/fZTfavP4EV';

// https://stackoverflow.com/a/52311051
export function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '') ?? '';
            if (encoded.length % 4 > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
        };
        reader.onerror = error => reject(error);
    });
}

export const toNumberSafe = (value: string | number | null | undefined): number => (!!value && !isNaN(Number(value)) ? Number(value) : 0);

export const toDateTimeFormat = (dateTime: Date, type?: 'long' | 'short') => {
    const longOpt: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };
    const shortOpt: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const options = type === 'short' ? shortOpt : longOpt;
    return new Intl.DateTimeFormat('default', options).format(dateTime);
};

// https://gist.github.com/emptyother/1fd97db034ef848f38eca3354fa9ee90
export const isValidGuid = (str: string): boolean => {
    const validRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return validRegex.test(str);
};

export const calculateDateDifference = (date: Date) => {
    const currentDate = new Date();
    const givenDate = new Date(date);
    const diffTime = Math.abs(currentDate.getTime() - givenDate.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
        return 'Today';
    } else if (diffDays === 1) {
        return currentDate > givenDate ? 'Yesterday' : 'Tomorrow';
    } else {
        return currentDate > givenDate ? `${diffDays} days ago` : `in ${diffDays} days`;
    }
};

export const quickHash = (_str: string | object) => {
    const str = typeof _str === 'object' ? JSON.stringify(_str) : _str;
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash;
    }
    return new Uint32Array([hash])[0].toString(36);
};

export const DEFAULT_QUICK_HASH = quickHash('');

export const isBrave = () => {
    if (!!window.navigator?.brave) {
        if (window.navigator.brave?.isBrave?.name == 'isBrave') {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const isEdge = () => /Edg/.test(navigator.userAgent);
export const getExpectedCookieName = (userAddress: EthAddress) =>
    `${IN_DEV_ENV || IN_STAGING_ENV ? 'dev' : 'prod'}-${userAddress.toLowerCase()}-${COOKIE_KEYS.auth}`;

export const getCookies = () =>
    document.cookie.split('; ').reduce<{ [cookieName: string]: string }>((acc, cookie) => {
        const [name, value] = cookie.split('=');
        if (!!name && !!value) return { ...acc, [name]: value };
        else return acc;
    }, {});

export const deleteBullaCookie = (userAddress: string) => {
    document.cookie = `${getExpectedCookieName(userAddress)}=; expires=Thu, 01 Jan 1970 00:00:01 UTC; domain=.bulla.network;`;
};

export const userIsViewingPage = () => document.visibilityState === 'visible';
export const toDateDisplay = (date: Date | undefined) => (date ? moment(date).format('DD MMM YYYY') : '');
export const toDateWithTime = (date: Date | undefined) => (date ? moment(date).format('DD MMM YYYY HH:mm:ss') : '');
export const toUSD = (number: BigNumber | number, atLeastOneDigit?: boolean, maximumFractionDigits?: number) =>
    number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumSignificantDigits: (atLeastOneDigit && 1) || undefined,
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: maximumFractionDigits ?? 4,
    });

export function toRecord<
    T extends { [K in keyof T]: string | number | symbol }, // added constraint
    K extends keyof T,
>(array: T[], selector: K): Record<T[K], T> {
    return array.reduce((acc, item) => ((acc[item[selector]] = item), acc), {} as Record<T[K], T>);
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

export function truthy<T>(value: T): value is Truthy<T> {
    return !!value;
}

export const baseEndpoints = IN_DEV_ENV ? API_ENDPOINTS.dev : IN_STAGING_ENV ? API_ENDPOINTS.staging : API_ENDPOINTS.prod;

export const endpoints = {
    ...baseEndpoints,
    custodianApi: (custodianId: string) =>
        custodianId.toLowerCase() === CUSTODIAN_IDS.DEV.toLowerCase()
            ? `${API_ENDPOINTS.dev.custodianApi}`
            : `${API_ENDPOINTS.prod.custodianApi}`,
};

export const returnIfDevEnv = (args: any) => (IN_DEV_ENV ? args : undefined);

export const reloadWindow = () => window.location.reload();

export const tryParseJson = (value: any) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return undefined;
    }
};

export const onboardAnimationTimeout = async () => new Promise(resolve => setTimeout(resolve, 1700));

export const handleErrorMessages = (message: string) => {
    if (message.includes('execution reverted:')) return message.split('execution reverted:')[1].split('"')[0];
    if (message.includes('UNPREDICTABLE_GAS_LIMIT')) return 'Cannot estimate gas.';
    if (message.includes('nonce has already been used'))
        return "Nonce error occured within your wallet's RPC provider:\nPlease try resetting your wallet's network or account.";

    return message;
};

export const apply =
    <T1, T2, R>(func: (_1: T1, _2: T2) => R, arg: T1) =>
    (secondArg: T2) =>
        func(arg, secondArg);

export const zip3 = <T1, T2, T3, R>(list1: T1[], list2: T2[], list3: T3[], func: (index: number, _1: T1, _2: T2, _3: T3) => R) => {
    if (list1.length != list2.length && list2.length != list3.length) throw new Error('Array lengths need to match');

    return list1.map((l1Item, i, _) => func(i, l1Item, list2[i], list3[i]));
};

export const fillToCount = (list: any[], count: number) => {
    return list.length < count ? [...list, ...new Array(count - list.length).map(_ => undefined)] : list;
};

export const wrapTxWithLoadingState =
    <T>(execute: (params: any) => Promise<T>, setLoading: (loading: boolean) => void) =>
    async (...params: any): Promise<T> => {
        setLoading(true);
        try {
            return await execute.apply(this, params);
        } finally {
            setLoading(false);
        }
    };

export type TxHashBitmap = Record<TransactionHash, boolean | undefined>;

export const truncateToDecimals = (numStr: string, decimals: number) => {
    const [integerPart, decimalPart = ''] = numStr.split('.');
    return `${integerPart}.${decimalPart.slice(0, decimals)}`;
};

export type PremiumTier = 'basic' | 'pro';

export const toDisplayLabel = (tier: PremiumTier) => tier.charAt(0).toUpperCase() + tier.slice(1);
