import { Flex } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { BullaSwapInfoWithUSDMark } from '../../../data-lib/data-model';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useGlobalUserData } from '../../../hooks/useUserData';
import {
    shadowProps,
    ListViewCard,
    AMOUNT_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListViewCardProps,
    VIEW_COLUMN_WIDTH,
    TOKEN_COLUMN_WIDTH,
    CHAIN_COLUMN_WIDTH,
    STATUS_COLUMN_WIDTH,
} from '../../layout/cards';
import { ResponsiveStack } from '../responsive-stack';
import { ClearFilterPillsStack } from './filters/common';
import { SwapFilter, swapFilterToPills, emptySwapFilterValues, getInitialSwapFilterValues, SwapFilterValues } from './filters/swap-filter';
import { ListItemProps } from '../../layout/cards';
import { TokenAmount } from '../../currency/token-display-amount';
import { toDateDisplay, toUSD } from '../../../tools/common';
import { FromAndToWallet } from '../../base/address-label';
import { ChainSymbol } from '../../chain-symbol';
import { ViewDetailsButton } from '../../inputs/buttons';
import { addressEquality, weiToDisplayAmt } from '../../../data-lib/ethereum';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useOpenSwapDetails } from '../../../hooks/useClaimDetailDisclosure';
import { BaseBadge } from '../../base/status-badge';

const SWAP_HEADERS: ListViewCardProps['headers'] = [
    { label: 'from / to', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'status', relativeColumnWidth: STATUS_COLUMN_WIDTH },
    { label: 'Amount In', relativeColumnWidth: TOKEN_COLUMN_WIDTH },
    { label: 'USD amount in', relativeColumnWidth: TOKEN_COLUMN_WIDTH },
    { label: 'Amount Out', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'USD amount out', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];
const MOBILE_HEADERS = ['date', 'amountIn', 'amountOut'];

const renderUSDAmount = (usdMark: any, amount: number) => (
    <Flex>{usdMark === 'fetching' ? 'Loading' : usdMark === 'not-found' ? 'Not Found' : toUSD(amount * usdMark)}</Flex>
);

export const SwapsTable: React.FC = () => {
    const { swapsWithUSDMark } = useGlobalUserData('exclude-originating-claims');
    const [filters, setFilters] = React.useState<SwapFilterValues>(emptySwapFilterValues);
    const listViewCardRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    const actingWallet = useActingWalletAddress();
    const openSwap = useOpenSwapDetails();

    const displayedListItems = swapsWithUSDMark.map((item): ListItemProps => {
        const {
            signerWallet,
            signerAmount,
            signerToken,
            senderWallet,
            senderAmount,
            senderToken,
            chainId,
            created,
            status,
            USDMarkSignerToken,
            USDMarkSenderToken,
            orderId,
        } = item;

        const isActingSigner = addressEquality(signerWallet, actingWallet);

        const amountInfo = {
            amountIn: isActingSigner ? senderAmount : signerAmount,
            tokenIn: isActingSigner ? senderToken : signerToken,
            amountOut: isActingSigner ? signerAmount : senderAmount,
            tokenOut: isActingSigner ? signerToken : senderToken,
            usdMarkIn: isActingSigner ? USDMarkSenderToken : USDMarkSignerToken,
            usdMarkOut: isActingSigner ? USDMarkSignerToken : USDMarkSenderToken,
        };

        const amountInDisplay = <TokenAmount amount={amountInfo.amountIn} tokenInfo={amountInfo.tokenIn} />;
        const amountOutDisplay = <TokenAmount amount={amountInfo.amountOut} tokenInfo={amountInfo.tokenOut} />;

        const amountInDisplayNumber = weiToDisplayAmt({
            amountWei: amountInfo.amountIn,
            token: amountInfo.tokenIn.token,
        });
        const amountOutDisplayNumber = weiToDisplayAmt({
            amountWei: amountInfo.amountOut,
            token: amountInfo.tokenOut.token,
        });

        const usdMarkInDisplay = renderUSDAmount(amountInfo.usdMarkIn, amountInDisplayNumber);
        const usdMarkOutDisplay = renderUSDAmount(amountInfo.usdMarkOut, amountOutDisplayNumber);

        const createdDate = toDateDisplay(created);
        const statusBadge =
            status == 'Pending' ? (
                <BaseBadge bg="#FFFAEB" color="#B54708" border="1px solid #FEDF89">
                    Pending
                </BaseBadge>
            ) : status == 'Executed' ? (
                <BaseBadge bg="#ECFDF3" color="#067647" border="1px solid #ABEFC6">
                    Executed
                </BaseBadge>
            ) : (
                <BaseBadge bg="#FDE7E7" color="#B91C1C" border="1px solid #FCA5A5">
                    Canceled
                </BaseBadge>
            );

        return {
            columnValues: isMobile
                ? [createdDate, amountInDisplay, amountOutDisplay]
                : [
                      <FromAndToWallet chainId={chainId} from={isActingSigner ? senderWallet : signerWallet} to={actingWallet} />,
                      createdDate,
                      <ChainSymbol chainId={chainId} />,
                      statusBadge,
                      amountInDisplay,
                      usdMarkInDisplay,
                      amountOutDisplay,
                      usdMarkOutDisplay,
                      <ViewDetailsButton onClick={() => openSwap(orderId.toString(), chainId)} />,
                  ],
        };
    });

    return (
        <>
            <ResponsiveStack align="flex-start">
                <SwapFilter filters={filters} setFilters={setFilters} />
            </ResponsiveStack>
            <ClearFilterPillsStack
                pb="4"
                clearAll={() => setFilters(getInitialSwapFilterValues())}
                filters={filters}
                filtersToPills={swapFilterToPills(setFilters)}
            />
            <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto" overflow={'visible'} ref={listViewCardRef}>
                <ListViewCard
                    headers={isMobile ? MOBILE_HEADERS : SWAP_HEADERS}
                    displayedListItems={displayedListItems}
                    bordered={false}
                    totalItemCount={displayedListItems.length}
                    emptyMessage="No swaps to display"
                />
            </Flex>
        </>
    );
};
