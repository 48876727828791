import React from 'react';
import { Box, Text, HStack, Flex } from '@chakra-ui/react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ReportingBadge } from '../../pages/reporting/reporting-explorer';
import { WithSkeleton } from '../base/skeleton';
import { TokenDto } from '../../data-lib/networks';
import { subDays, eachDayOfInterval, format, differenceInCalendarDays } from 'date-fns';
import { PriceDataPoint, TimeRange, TimeRangeSelector } from './common';

interface FactoringPriceChartProps {
    tokenName: string;
    currentPrice: number | null;
    priceChange: number | null;
    priceData: PriceDataPoint[];
    timeRange: TimeRange;
    setTimeRange: (range: TimeRange) => void;
    yAxisDomain: number[];
    underlyingToken: TokenDto;
}

const getChartPriceDataForTimeRange = (priceData: PriceDataPoint[], timeRange: TimeRange) => {
    const today = new Date();
    let startDate: Date;
    let interval: number;

    switch (timeRange) {
        case '1W':
            startDate = subDays(today, 7);
            interval = 1;
            break;
        case '1M':
            startDate = subDays(today, 30);
            interval = 2;
            break;
        case '1Y':
            startDate = subDays(today, 365);
            interval = 7;
            break;
    }

    const reversedPriceData = [...priceData].reverse();

    const [expandedPriceData] = reversedPriceData.reduce<[PriceDataPoint[], Date, number]>(
        ([acc, currentDate, lastPrice], item) => {
            if (acc.find(d => d.date.toDateString() == item.date.toDateString())) return [acc, currentDate, lastPrice]; // remove duplicates, take latest data point within the same day

            const nextDate = subDays(item.date, 1);
            const daysDifference = differenceInCalendarDays(item.date, currentDate);
            console.log({ itemDate: item.date, currentDate, daysDifference });

            return daysDifference == 0
                ? [[...acc, item], nextDate, item.price]
                : [
                      [
                          ...acc,
                          ...eachDayOfInterval({ start: item.date, end: currentDate }).map(d => ({
                              date: d,
                              price: item.price,
                          })),
                      ],
                      nextDate,
                      item.price,
                  ];
        },
        [[], new Date(today), 0],
    );

    const dateRange = eachDayOfInterval({ start: startDate, end: today });
    let lastKnownPrice = 1;
    const filteredDateRange = dateRange
        .reverse() // reversing the array to start counting from the end date
        .filter((_, index) => index % interval === 0)
        .reverse();

    return filteredDateRange // reversing back to maintain chronological order, as displayed in the chart
        .map(date => {
            const matchingDataPoint = expandedPriceData.find(d => d.date.toDateString() === date.toDateString());
            if (matchingDataPoint) {
                // @notice this is to keep displaying the latest price up to the current date
                lastKnownPrice = matchingDataPoint.price;
            }
            return {
                date: date.getTime(),
                price: lastKnownPrice,
            };
        });
};

export const formatXAxis = (timestamp: number, timeRange: TimeRange) => {
    const date = new Date(timestamp);
    switch (timeRange) {
        case '1W':
        case '1M':
            return format(date, 'MMM dd');
        case '1Y':
            return format(date, 'MMM');
    }
};

const getXAxisTicks = (adjustedPriceData: { date: number; price: number }[], timeRange: TimeRange) => {
    const dataLength = adjustedPriceData.length;
    const tickCount = timeRange === '1Y' ? 12 : 6;
    const step = Math.floor(dataLength / tickCount);
    return adjustedPriceData.filter((_, index) => index % step === 0).map(d => d.date);
};

export const FactoringPriceChart: React.FC<FactoringPriceChartProps> = ({
    tokenName,
    currentPrice,
    priceChange,
    priceData,
    underlyingToken,
    timeRange,
    setTimeRange,
    yAxisDomain,
}) => {
    const chartPriceDataFromTimeRange = React.useMemo(() => getChartPriceDataForTimeRange(priceData, timeRange), [priceData, timeRange]);

    return (
        <Box p="2">
            <Flex direction="row" justifyContent="space-between" mb="4">
                <Text fontSize="xl" fontWeight="bold">
                    {tokenName}
                </Text>
                <TimeRangeSelector timeRange={timeRange} setTimeRange={setTimeRange} />
            </Flex>
            <HStack>
                {currentPrice ? (
                    <Text fontSize="3xl" fontWeight="bold">
                        {currentPrice?.toFixed(6)} {underlyingToken.symbol}
                    </Text>
                ) : (
                    <WithSkeleton isLoading={true} fixedWidth="80px">
                        <Text>Can't see me</Text>
                    </WithSkeleton>
                )}

                {priceChange !== null && (
                    <ReportingBadge
                        backgroundColor={priceChange >= 0 ? '#ECFDF3' : '#FDF2F8'}
                        color={priceChange >= 0 ? '#067647' : '#9D174D'}
                        borderColor={priceChange >= 0 ? '#ABEFC6' : '#FBCFE8'}
                        borderRadius="3xl"
                        ml="2"
                    >
                        {priceChange >= 0 ? '↑' : '↓'} {Math.abs(priceChange).toFixed(2)}%
                    </ReportingBadge>
                )}
            </HStack>

            <Box h="300px">
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={chartPriceDataFromTimeRange} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                        <defs>
                            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#E36409" stopOpacity={0.1} />
                                <stop offset="75%" stopColor="#E36409" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="date"
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={timestamp => formatXAxis(timestamp, timeRange)}
                            ticks={getXAxisTicks(chartPriceDataFromTimeRange, timeRange)}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            domain={yAxisDomain}
                            tickFormatter={value => `${value.toFixed(2)}`}
                            axisLine={false}
                            tickLine={false}
                        />
                        <CartesianGrid vertical={false} stroke="#E2E8F0" />
                        <Tooltip
                            labelFormatter={label => new Date(label).toLocaleDateString()}
                            formatter={value => [`${Number(value).toFixed(6)} ${underlyingToken.symbol}`, 'Price']}
                            contentStyle={{ backgroundColor: 'white', border: '1px solid #E2E8F0' }}
                            labelStyle={{ color: '#718096' }}
                            itemStyle={{ color: '#2e2e2d' }}
                        />
                        <Area
                            type="monotone"
                            dataKey="price"
                            stroke="#E36409"
                            fillOpacity={1}
                            fill="url(#colorValue)"
                            strokeWidth={3}
                            yAxisId="right"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};
