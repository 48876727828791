import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Box,
    Collapse,
    Flex,
    FormControl,
    FormErrorMessage,
    Input,
    MenuItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react';
import { utils } from 'ethers';
import React, { useEffect } from 'react';
import { ClaimInfo, FinancedClaimInfo } from '../../../data-lib/data-model';
import { termLengthToDays } from '../../../data-lib/dto/bulla-finance-dto';
import { ZERO_BIGNUMBER } from '../../../data-lib/helpers';
import { NETWORKS } from '../../../data-lib/networks';
import { useCanChangeNetwork } from '../../../hooks/useCanChangeNetwork';
import { useTokenBalances } from '../../../hooks/useChainData';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { PayButton } from '../../display/claim-action-buttons';
import { MenuDropdownButton, TextButton } from '../../inputs/buttons';
import { CloseModalButton } from '../common';
import { canConvertToBigNumber, isNumberInputValid, isTokenAmountValid } from '../create-claim-modal/create-claim-inputs';

type MakePaymentModalProps = {
    onClose: VoidFunction;
    isOpen: boolean;
    claim: FinancedClaimInfo;
};

type InfoRowProps = { labelText: string; displayAmount: string; symbol: string };
const InfoRow = ({ labelText, displayAmount, symbol }: InfoRowProps) => (
    <Flex>
        <Text fontWeight={700}>{labelText}</Text>
        <Spacer></Spacer>
        <Text>{`${displayAmount} ${symbol}`}</Text>
    </Flex>
);

type MakePaymentModalState = 'remaining-amount' | 'other-amount';
const states: MakePaymentModalState[] = ['remaining-amount', 'other-amount'];

const getLabelForState = (state: MakePaymentModalState): string => {
    switch (state) {
        case 'remaining-amount':
            return 'Total Payoff Amount';
        case 'other-amount':
            return 'Other Amount';
    }
};

export const MakePaymentModal = ({ onClose, isOpen, claim }: MakePaymentModalProps) => {
    const isMobile = useIsMobile();
    const tokenBalances = useTokenBalances({ chainId: claim.chainId, poll: isOpen }) ?? {};
    const token = claim.tokenInfo.token;
    const symbol = token.symbol;
    const [selectedState, setSelectedState] = React.useState<MakePaymentModalState>('remaining-amount');
    const [otherAmountValue, setOtherAmountValue] = React.useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (isNumberInputValid(value)) setOtherAmountValue(value);
    };

    const remainingWei = React.useMemo(() => claim.claimAmount.sub(claim.paidAmount), [claim.claimAmount, claim.paidAmount]);

    const currentDueAmountWei = React.useMemo(() => {
        const downPayment = claim.financingState.terms.downPayment;
        const loanValuePlusInterest = claim.claimAmount.sub(downPayment);
        const termLengthDays = termLengthToDays(claim.financingState.terms.termLength);
        const timeElapsed = Math.abs(new Date().getTime() - claim.created.getTime());
        const daysElapsed = Math.min(Math.ceil(timeElapsed / (1000 * 3600 * 24)), termLengthDays);
        const percentageOfCompletion = (daysElapsed / termLengthDays).toFixed(2);
        const expectedPaidAmount = loanValuePlusInterest
            .mul(Math.floor(+percentageOfCompletion * 100))
            .div(100)
            .add(downPayment);

        if (claim.paidAmount.gte(expectedPaidAmount)) return 'up-to-date';
        return expectedPaidAmount.sub(claim.paidAmount);
    }, [remainingWei]);

    const switchText = (claim: ClaimInfo) => `Switch to ${NETWORKS[claim.chainId].label}`;

    const { changeNetwork } = useOnboard();
    const { connectedNetwork, connectedNetworkConfig } = useWeb3();
    const canChangeNetwork = useCanChangeNetwork();

    const wrongNetworkLabel = claim && claim.chainId !== connectedNetwork && (
        <Box p="0">
            <Alert status="warning" bg={'white'} py="0">
                <AlertIcon />
                <span>
                    You are connected to {connectedNetworkConfig.label} network.{' '}
                    {canChangeNetwork ? (
                        <TextButton onClick={() => changeNetwork(claim.chainId)}>{switchText(claim)}</TextButton>
                    ) : (
                        switchText(claim)
                    )}{' '}
                    to Pay.
                </span>
            </Alert>
        </Box>
    );

    const amountToPay = React.useMemo(() => {
        switch (selectedState) {
            case 'other-amount':
                if (!isNumberInputValid(otherAmountValue)) return 'not-a-number';
                if (otherAmountValue === '') return 'no-number';
                if (!canConvertToBigNumber(otherAmountValue)) return 'invalid-number';
                if (!isTokenAmountValid(otherAmountValue, token.decimals)) return 'too-many-decimals';

                const parsed = utils.parseUnits(otherAmountValue, token.decimals);
                if (parsed.eq(0)) return 'cant-be-zero';
                if (parsed.gt(remainingWei)) return 'more-than-remaining';

                return parsed;
            case 'remaining-amount':
                return remainingWei;
        }
    }, [selectedState, otherAmountValue, remainingWei]);

    const otherAmountError = React.useMemo(
        () =>
            selectedState !== 'other-amount'
                ? undefined
                : (() => {
                      if (amountToPay == 'cant-be-zero') return `Cannot pay 0 ${symbol}`;
                      if (amountToPay == 'invalid-number') return 'Please enter a valid number';
                      if (amountToPay == 'more-than-remaining') return 'Cannot pay more than amount remaining';
                      if (amountToPay == 'no-number') return 'Please enter a number';
                      if (amountToPay == 'not-a-number') return 'Please enter a valid number';
                      if (amountToPay == 'too-many-decimals') return `Too many decimals for ${symbol}`;
                      return undefined;
                  })(),
        [selectedState, amountToPay],
    );

    useEffect(() => {
        typeof amountToPay !== 'string' && amountToPay.eq(ZERO_BIGNUMBER) && onClose();
    }, [amountToPay]);

    const remainingDisplayAmount = React.useMemo(() => utils.formatUnits(remainingWei, claim.tokenInfo.token.decimals), [remainingWei]);

    const actions = React.useMemo(() => {
        const statesAndDisplayAmounts: [MakePaymentModalState, string | undefined][] = [
            ['remaining-amount', remainingDisplayAmount],
            ['other-amount', undefined],
        ];
        return statesAndDisplayAmounts.map(([state, display]) => (
            <MenuItem key={state} onClick={() => setSelectedState(state)}>
                <Flex w="100%">
                    {getLabelForState(state)}
                    <Spacer />
                    {!!display && `${display} ${symbol}`}
                </Flex>
            </MenuItem>
        ));
    }, [remainingDisplayAmount]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={isMobile ? 'full' : 'lg'}
            closeOnOverlayClick={false}
            isCentered
            closeOnEsc={false}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent py="4" px="2" bg={'white'}>
                <ModalHeader display="flex">
                    <Text color="gray.700" fontWeight={'700'} fontSize="18px" noOfLines={1} alignSelf="center">
                        Make a payment
                    </Text>
                    <Spacer />
                    <CloseModalButton onClose={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Stack spacing="6" w={'100%'}>
                        <InfoRow
                            key={'wallet balance'}
                            labelText={'Wallet Balance'}
                            displayAmount={tokenBalances.getBalanceForToken(token.address)?.toString() ?? '---'}
                            symbol={symbol}
                        />
                        <InfoRow key={'payoff'} labelText={'Total Payoff Amount'} displayAmount={remainingDisplayAmount} symbol={symbol} />
                        <MenuDropdownButton
                            text={getLabelForState(selectedState)}
                            icon={<ChevronDownIcon w="6" h="6" />}
                            actions={actions}
                            bg="white"
                            border="1px solid #E2E8F0"
                            fontWeight={400}
                            menuProps={{ matchWidth: true, placement: 'bottom' }}
                            h="12"
                            py="5.5"
                        />
                        <Collapse in={selectedState == 'other-amount'}>
                            <FormControl isInvalid={typeof amountToPay === 'string'}>
                                <Input
                                    autoComplete="off"
                                    placeholder="Enter amount"
                                    value={otherAmountValue}
                                    onChange={handleChange}
                                    color={'black'}
                                    width={'100%'}
                                />
                                {otherAmountError && <FormErrorMessage>{otherAmountError}</FormErrorMessage>}
                            </FormControl>
                        </Collapse>
                        <PayButton
                            claimInfo={claim}
                            w="100%"
                            parentIsDisabled={typeof amountToPay == 'string'}
                            amountToPay={typeof amountToPay == 'string' ? undefined : amountToPay}
                        />
                        {wrongNetworkLabel}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
