import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    Center,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
} from '@chakra-ui/react';
import { formatUnits } from 'ethers/lib/utils';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WarningIcon } from '../../../assets/warning';
import { ClaimType } from '../../../data-lib/data-model';
import { addressEquality, EthAddress, isValidAddress } from '../../../data-lib/ethereum';
import { ChainId, NETWORKS, SUPPORTED_NETWORKS } from '../../../data-lib/networks';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { BullaItemInfoWithPayment } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { openCreateClaimParams } from '../../../state/ui-state';
import { getSessionStorage, STORAGE_KEYS } from '../../../tools/storage';
import { truncateString } from '../../base/address-label';
import { BullaBlueTextButton, SecondaryButton } from '../../inputs/buttons';
import { CreateClaimModal } from './create-claim-modal';
import { CreatePaymentModal } from './create-payment-modal';
import { enableNewInvoiceCreation } from '../../../tools/featureFlags';

export const PastPaymentsTable = ({
    payments,
    readyToTransact,
    network,
    recipient,
    buttonLabel,
}: {
    payments: BullaItemInfoWithPayment[];
    readyToTransact: boolean;
    network: ChainId;
    recipient: EthAddress;
    buttonLabel?: string;
}) => {
    const blockExplorerUrl = `${NETWORKS[network].blockExplorer}/address/${recipient}`;
    const noPastTransactionWarning = payments.length === 0;

    return (
        <>
            <Spacer />
            <Flex border="1px solid #E2E8F0" borderRadius={'8px'} flexDir="column" p="5" boxShadow="md">
                <Flex justifyContent={'space-between'} mb="2">
                    <Text fontSize={'16px'} fontWeight="700">
                        Past Transactions
                    </Text>
                    <BullaBlueTextButton textDecoration={'none'} onClick={() => window.open(blockExplorerUrl, '_blank')}>
                        {buttonLabel ?? 'View'}
                    </BullaBlueTextButton>
                </Flex>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th fontSize={'12px'}>Date</Th>
                            <Th fontSize={'12px'}>Amount</Th>
                            <Th fontSize={'12px'}>Description</Th>
                        </Tr>
                    </Thead>
                    {!readyToTransact ? (
                        <Tr>
                            <Td colSpan={3}>
                                <Flex align={'center'} justifyContent="center" height="80px">
                                    <Center>
                                        <Spinner />
                                    </Center>
                                </Flex>
                            </Td>
                        </Tr>
                    ) : !noPastTransactionWarning ? (
                        <Tbody>
                            {payments.map((payment, index) => (
                                <Tr key={index}>
                                    <Td>{new Date(payment.paymentTimestamp).toLocaleDateString()}</Td>
                                    <Td>
                                        {`${formatUnits(payment.payment, payment.tokenInfo.token.decimals)} ${
                                            payment.tokenInfo.token.symbol
                                        }`}
                                    </Td>

                                    <Td isTruncated maxW="200px">
                                        {payment.description}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    ) : (
                        <Tr>
                            <Td colSpan={3}>
                                <Flex align={'center'} justifyContent="center" height="80px">
                                    <Center color="red.500">
                                        <WarningIcon mr="2" /> No transactions found with recipient
                                    </Center>
                                </Flex>
                            </Td>
                        </Tr>
                    )}
                </Table>
            </Flex>
        </>
    );
};

export const CreateItemLinkHandler = () => {
    const { search } = useLocation();
    const userAddress = useActingWalletAddress();
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const { connectedNetwork } = useWeb3();
    const params = new URLSearchParams(search);
    const cache = getSessionStorage<Record<string, string | undefined>>(STORAGE_KEYS.capturedParams) ?? {};
    const [_network, itemType, recipient, payer, description, name, amount, _token, offchainInvoiceId, fromLink, fromEmail] =
        openCreateClaimParams.map(p => params.get(p) ?? cache?.[p]);
    const isPayerValid = payer && addressEquality(payer!, userAddress);
    const network = _network ? +_network : connectedNetwork;
    const token = _token && network ? getTokenByChainIdAndAddress(network as ChainId)(_token)?.token! : undefined;
    const [modalOpen, setModalOpen] = React.useState(true);
    const navigate = useNavigate();

    const hasRequiredFields = !!network && !!itemType && !!recipient;
    const isNetworkValid = (_network: number): _network is ChainId =>
        !!_network && !isNaN(_network) && SUPPORTED_NETWORKS.includes(_network as ChainId);
    const isItemTypeValid = (_type: string): _type is ClaimType => _type === 'Invoice' || _type === 'Payment';

    const shouldRender = !!hasRequiredFields && isNetworkValid(network) && isItemTypeValid(itemType) && isValidAddress(recipient);

    if (!shouldRender) return null;

    const header = (
        <Text fontWeight={700} fontSize="24px" lineHeight={'32px'} color="gray.700" w="100%">
            {`Pay ${!!name ? name : ''} with Bulla on ${NETWORKS[network].label}`}
            <Tooltip
                placement="top"
                label="We cannot guarantee this identity relates to this wallet address. Please confirm all wallet addresses before sending payment"
            >
                <InfoOutlineIcon ml="4px" mt="-18px" w="14px" />
            </Tooltip>
        </Text>
    );

    return (
        <>
            {!payer || isPayerValid ? (
                itemType === 'Payment' ? (
                    <CreatePaymentModal
                        header={header}
                        defaults={{
                            recipient,
                            network: _network == undefined ? undefined : network,
                            paymentAmount: amount,
                            token,
                            description,
                        }}
                        fromLink={fromLink == 'true'}
                    />
                ) : enableNewInvoiceCreation ? (
                    navigate(
                        `/new-invoice?recipient=${recipient}&network=${network}${amount ? `&amount=${amount}` : ''}${
                            token?.address ? `&token=${token.address}` : ''
                        }`,
                    )
                ) : (
                    <CreateClaimModal
                        claimType={itemType}
                        header={header}
                        defaults={{
                            recipient,
                            network: _network == undefined ? undefined : network,
                            claimAmount: amount,
                            token,
                            description,
                        }}
                        fromLink={fromLink == 'true'}
                    />
                )
            ) : (
                <Modal
                    isCentered
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    motionPreset="slideInBottom"
                    size="2xl"
                    closeOnEsc
                    scrollBehavior="inside"
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{header}</ModalHeader>
                        <ModalBody fontSize={'14px'} fontWeight="600" p="5">
                            <HStack backgroundColor={'#f9dedc'} border="2px" borderColor={'#b7322a'} borderRadius={8} px={5} py={3}>
                                <WarningIcon mr="1" />
                                <Text fontSize="14px" lineHeight={'28px'} textAlign="left" color="#b7322a">
                                    The address you're connected to is not associated with this payment. <br />
                                    Please switch to the {payer && truncateString(payer)} to view details.
                                </Text>
                            </HStack>

                            <Box h="4" />
                            <Flex justifyContent={'flex-end'}>
                                <SecondaryButton
                                    onClick={() => {
                                        setModalOpen(false);
                                        navigate('/');
                                    }}
                                    w="min-content"
                                >
                                    Cancel
                                </SecondaryButton>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};
