import { CalendarIcon, ChevronDownIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Checkbox,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuListProps,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    StyleProps,
    Text,
    Wrap,
    WrapItem,
    WrapProps,
} from '@chakra-ui/react';
import React from 'react';
import { ClaimStatus, ClaimStatuses } from '../../../../data-lib/data-model';
import { isContactsReady, useExtendedContacts } from '../../../../hooks/useExtendedContacts';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { NftTransfer, useGlobalUserData } from '../../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../../hooks/useWalletAddress';
import { shortAddress } from '../../../base/address-label';
import { MultipleDatePicker } from '../../../date-picker';
import { PillCloseIcon } from '../../../inputs/account-tag-input';
import { BullaBlueTextButton } from '../../../inputs/buttons';
import { filterButtonProps, NftFilterValues } from './claim-filter';
import { toLedgerAccountDisplayValue } from '../../../modals/ledger-export-wizard-modal/ledger-export-success-card';
import { LedgerAccount, LEDGER_ACCOUNTS } from '../../../modals/ledger-export-wizard-modal/ledger-account';

export type SetDate = (type: 'startDate' | 'endDate', date: Date | undefined) => void;

const DirectionFilterValues = ['In And Out', 'In', 'Out'] as const;
export type DirectionFilterValue = typeof DirectionFilterValues[number];
type DirectionFilterProps = { direction: DirectionFilterValue; setDirection: (direction: DirectionFilterValue) => void };
export const DirectionFilter = ({ direction, setDirection }: DirectionFilterProps) => (
    <Menu>
        <MenuButton rightIcon={<ChevronDownIcon />} as={Button} {...filterButtonProps}>
            {direction}
        </MenuButton>
        <MenuList {...filterMenuProps}>
            {DirectionFilterValues.map(direction => (
                <MenuItem key={direction} {...filterMenuItemFontProps} onClick={() => setDirection(direction)}>
                    {direction}
                </MenuItem>
            ))}
        </MenuList>
    </Menu>
);

type CollectionFilterProps = {
    selectedCollections: Set<string>;
    setFilter: (key: keyof NftFilterValues, value: NftFilterValues[keyof NftFilterValues]) => void;
    nftTransfers: NftTransfer[];
};

export const CollectionFilter: React.FC<CollectionFilterProps> = ({ selectedCollections, setFilter, nftTransfers }) => {
    return (
        <Menu closeOnSelect={false}>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} {...filterButtonProps}>
                {selectedCollections.size > 0
                    ? `${selectedCollections.size} collection${selectedCollections.size > 1 ? 's' : ''}`
                    : 'Collections'}
            </MenuButton>
            <MenuList {...filterMenuProps}>
                {[...new Set(nftTransfers.map(transfer => transfer.tokenName))].map(collection => (
                    <MenuItem
                        key={collection}
                        {...filterMenuItemFontProps}
                        onClick={() =>
                            setFilter(
                                'selectedCollections',
                                selectedCollections.has(collection)
                                    ? (() => {
                                          selectedCollections.delete(collection);
                                          return new Set(selectedCollections);
                                      })()
                                    : new Set(selectedCollections.add(collection)),
                            )
                        }
                    >
                        <Checkbox
                            isDisabled={false}
                            isChecked={selectedCollections.has(collection)}
                            onChange={() =>
                                setFilter(
                                    'selectedCollections',
                                    selectedCollections.has(collection)
                                        ? (() => {
                                              selectedCollections.delete(collection);
                                              return new Set(selectedCollections);
                                          })()
                                        : new Set(selectedCollections.add(collection)),
                                )
                            }
                            size="lg"
                        >
                            {collection}
                        </Checkbox>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export const filterMenuItemFontProps = { fontSize: '16px', fontWeight: 500, py: 3 };
export const filterMenuProps = { maxH: '300px', w: '320px', overflowY: 'auto' as MenuListProps['overflowY'] };

type ClaimStatusFilterProps = { status: ClaimStatus | undefined; setStatus: (claimStatus: ClaimStatus | undefined) => void };
export const ClaimStatusFilter = ({ status, setStatus }: ClaimStatusFilterProps) => (
    <Menu>
        <MenuButton rightIcon={<ChevronDownIcon />} as={Button} {...filterButtonProps}>
            {status ?? 'Status'}
        </MenuButton>
        <MenuList {...filterMenuProps}>
            {[undefined, ...ClaimStatuses].map(claimStatusValue => {
                const claimStatusDisplayValue = claimStatusValue ?? 'All';

                return (
                    <MenuItem key={claimStatusDisplayValue} onClick={() => setStatus(claimStatusValue)} {...filterMenuItemFontProps}>
                        {claimStatusDisplayValue}
                    </MenuItem>
                );
            })}
        </MenuList>
    </Menu>
);

type SearchFilterProps = { value?: string; setValue: (str: string) => void; placeholder?: string } & Partial<StyleProps>;
export const SearchFilter = ({ value, setValue, placeholder, ...props }: SearchFilterProps) => {
    const isMobile = useIsMobile();

    return (
        <InputGroup maxW={isMobile ? '100%' : '20%'} {...props}>
            <InputLeftElement pointerEvents="none" children={<SearchIcon color="rgba(52, 64, 84, 1)" transform="scaleX(-1)" />} />
            <Input
                value={value} //filters['search']}
                onChange={e => setValue(e.target.value)} //e => setFilter('search', e.target.value)}
                placeholder={placeholder ? `Search ${placeholder}...` : 'Search...'}
                {...filterButtonProps}
                fontWeight="500"
                color="rgba(52, 64, 84, 1)"
                backgroundColor="white"
                _placeholder={{ fontWeight: '500', color: 'rgba(52, 64, 84, 1)' }}
            />
        </InputGroup>
    );
};

export type PillProps = { clear: VoidFunction; label: string };
type ClearFilterPillsStackProps<T> = {
    filters: T;
    filtersToPills: (filters: T) => PillProps[];
    clearAll: VoidFunction;
};

const Pill: React.FC<PillProps> = ({ clear, label }) => (
    <WrapItem borderRadius={'8px'} backgroundColor="rgba(226, 232, 240, 1)" boxShadow="2xl">
        <HStack spacing="3" px="4" py="2.5">
            <Text fontSize="14px" fontWeight={600}>
                {label}
            </Text>
            <PillCloseIcon onClick={clear} />
        </HStack>
    </WrapItem>
);

export const ClearFilterPillsStack = <T,>({
    filters,
    filtersToPills,
    clearAll,
    ...props
}: ClearFilterPillsStackProps<T> & Partial<WrapProps>) => {
    const pills = filtersToPills(filters);
    return (
        <Wrap {...props}>
            {pills.map(pillProps => (
                <Pill key={pillProps.label} {...pillProps} />
            ))}
            {pills.length !== 0 && <BullaBlueTextButton onClick={clearAll}>Clear filters</BullaBlueTextButton>}
        </Wrap>
    );
};

type DateSelectProps = {
    startDate: Date | undefined;
    endDate: Date | undefined;
    setDate: SetDate;
    resetDates: VoidFunction;
    paymentTimestamps: Date[];
};
export type TableFilter<T> = (item: T) => boolean;
export const DateSelect = ({ startDate, endDate, setDate, resetDates, paymentTimestamps = [] }: DateSelectProps) => {
    const onChange = (dates: [Date, Date]): any => {
        const [start, end] = dates;
        setDate('startDate', start);
        setDate('endDate', end);
    };
    const handleClearDates = () => {
        setDate('startDate', undefined);
        setDate('endDate', undefined);
        resetDates();
    };

    return (
        <Box w="250px">
            <Popover placement="bottom-start" variant="responsive" isLazy>
                <PopoverTrigger>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <CalendarIcon color="rgba(52, 64, 84, 1)" fontWeight={500} />
                        </InputLeftElement>
                        <Input
                            placeholder="Select date range"
                            defaultValue={
                                startDate && endDate
                                    ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                                    : startDate
                                    ? `After: ${startDate.toLocaleDateString()}`
                                    : endDate
                                    ? `Before: ${endDate.toLocaleDateString()}`
                                    : ''
                            }
                            {...filterButtonProps}
                            fontWeight={500}
                            color="rgba(52, 64, 84, 1)"
                            backgroundColor={'white'}
                            _placeholder={{ fontWeight: '500', color: 'rgba(52, 64, 84, 1)' }}
                        />
                    </InputGroup>
                </PopoverTrigger>
                <PopoverContent width="100%">
                    <PopoverBody>
                        {endDate !== null && (
                            <Button leftIcon={<CloseIcon color="gray.300" />} variant="outline" onClick={handleClearDates} w="100%">
                                Clear dates
                            </Button>
                        )}
                        <MultipleDatePicker
                            selectedDate={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            highlightDates={paymentTimestamps}
                        />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    );
};

type WalletFilterProps = { selectedWallets: Set<string>; onClick: (wallet: string) => void; selectableWallets?: Set<string> };
export const WalletFilter: React.FC<WalletFilterProps> = ({ selectedWallets, onClick, selectableWallets }) => {
    const { bullaItems } = useGlobalUserData('include-originating-claims');
    const actingWallet = useActingWalletAddress();
    const contactsContext = useExtendedContacts();
    const [search, setSearch] = React.useState('');

    const _walletDropdownItems = React.useMemo(() => {
        const set = selectableWallets ?? new Set(bullaItems.flatMap(x => [x.creditor, x.debtor]).map(x => x.toLowerCase()));
        !selectableWallets && set.delete(actingWallet.toLowerCase());
        const contacts = !isContactsReady(contactsContext)
            ? []
            : contactsContext.contacts.filter(x => !selectableWallets || selectableWallets.has(x.walletAddress.toLowerCase()));

        contacts.forEach(x => set.delete(x.walletAddress.toLowerCase()));

        return [
            ...contacts.map(x => ({ label: x.name, address: x.walletAddress.toLowerCase() })),
            ...[...set].map(address => ({ label: shortAddress(address, 3), address })),
        ];
    }, [...(selectableWallets ? [] : [bullaItems.length]), contactsContext]);

    const walletDropdownItems = React.useMemo(() => {
        const trimmedSearch = search.trim().toLowerCase();
        return trimmedSearch == ''
            ? _walletDropdownItems
            : _walletDropdownItems.filter(x => [x.label.toLowerCase(), x.address].some(x => x.includes(trimmedSearch)));
    }, [search, _walletDropdownItems.length]);

    return (
        <Popover placement="bottom-start">
            <PopoverTrigger>
                <Button rightIcon={<ChevronDownIcon />} {...filterButtonProps}>
                    Wallets
                </Button>
            </PopoverTrigger>
            <PopoverContent {...filterMenuProps}>
                <PopoverBody>
                    <Box p="3" w="100%">
                        <SearchFilter value={search} setValue={setSearch} maxW="100%" />
                    </Box>
                    {walletDropdownItems.map(x => (
                        <HStack key={x.address} spacing="3" px="3" {...filterMenuItemFontProps}>
                            <Checkbox isChecked={selectedWallets.has(x.address)} onChange={() => onClick(x.address)} size="lg" />
                            <Text>{x.label}</Text>
                        </HStack>
                    ))}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

type LedgerAccountFilterProps = {
    selectedAccount?: LedgerAccount;
    onSelect: (account: LedgerAccount | undefined) => void;
};

export const LedgerAccountFilter: React.FC<LedgerAccountFilterProps> = ({ selectedAccount, onSelect }) => {
    const displayValue = selectedAccount ? toLedgerAccountDisplayValue(selectedAccount) : 'Ledger Account';

    return (
        <Menu>
            <MenuButton rightIcon={<ChevronDownIcon />} as={Button} {...filterButtonProps}>
                {displayValue}
            </MenuButton>
            <MenuList {...filterMenuProps}>
                <MenuItem key="all" {...filterMenuItemFontProps} onClick={() => onSelect(undefined)}>
                    All Accounts
                </MenuItem>
                {LEDGER_ACCOUNTS.map(account => (
                    <MenuItem key={account} {...filterMenuItemFontProps} onClick={() => onSelect(account)}>
                        {toLedgerAccountDisplayValue(account)}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};
