export const LEDGER_ACCOUNTS = [
    'cash',
    'ar',
    'loanreceivable',
    'duefromfactor',
    'unearnedincome',
    'ap',
    'duetooriginalcreditor',
    'loanpayable',
    'sales',
    'factoringincome',
    'interestearned',
    'currencygain',
    'unknownincome',
    'expense',
    'factoringexpense',
    'interestexpense',
    'currencyloss',
] as const;

export type LedgerAccount = typeof LEDGER_ACCOUNTS[number];

export type LedgerAccountAdjustments = {
    dr: LedgerAccount[];
    cr: LedgerAccount[];
};

export const toLedgerAccountNumber = (account: LedgerAccount | undefined) => {
    switch (account) {
        case 'ap':
            return 410000;
        case 'ar':
            return 110100;
        case 'cash':
            return 185000;
        case 'sales':
            return 130100;
        case 'expense':
            return 450100;
        case 'factoringexpense':
            return 450200;
        case 'factoringincome':
            return 130400;
        case 'interestearned':
            return 150100;
        case 'interestexpense':
            return 600100;
        case 'loanreceivable':
            return 140100;
        case 'loanpayable':
            return 500100;
        case 'duefromfactor':
            return 110200;
        case 'duetooriginalcreditor':
            return 410100;
        case 'currencygain':
            return 130200;
        case 'currencyloss':
            return 600200;
        case 'unknownincome':
            return 130300;
        case 'unearnedincome':
            return 110300;
        case undefined:
            return '';
    }
};
