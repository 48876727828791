import { ERC20 } from '@bulla-network/contracts/typechain/ERC20';
import { BigNumber, constants } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import React, { useState } from 'react';
import { getERC20Contract } from '../data-lib/dto/contract-interfaces';
import { EthAddress } from '../data-lib/ethereum';
import { ZERO_BIGNUMBER } from '../data-lib/helpers';
import { ChainId, TokenDto, waitForTransaction } from '../data-lib/networks';
import { useGnosisSafe } from '../state/gnosis-state';
import { useUIState } from '../state/ui-state';
import { useWeb3 } from './useWeb3';

export type ApprovalsRequired = {
    [tokenAddress: EthAddress]: BigNumber;
};

export const useAllowances = (
    approvalType: 'max-allowance' | 'exact-allowance',
): [
    boolean,
    {
        approveTokens: (allowingContract: string) => (
            amounts: {
                amount: string | BigNumber;
                token: TokenDto;
            }[],
        ) => Promise<{
            success: boolean;
        }>;
        getAllowancesRequired: (
            allowingContract: string,
            chainId: ChainId,
        ) => (
            amounts: {
                token: TokenDto;
                amount: string | BigNumber;
            }[],
        ) => Promise<{
            tokensRequireApproval: boolean;
            allowancesRequired: ApprovalsRequired;
            approveTxCount: number;
        }>;
        getAllowanceForTokenAddress: (allowingContract: string, chainId: ChainId) => (tokenAddress: string) => Promise<BigNumber>;
        approveModalOpen: boolean;
        closeApproveModal: () => void;
    },
] => {
    const { safeInfo } = useGnosisSafe();
    const { signer, userAddress, signerProvider, connectedNetwork, providersByChainId } = useWeb3();
    const [executing, setExecuting] = React.useState(false);
    const { addPendingTxn, addErrorMessage } = useUIState();
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const closeApproveModal = () => {
        setApproveModalOpen(false);
    };

    const getAllowanceForTokenAddress = (allowingContract: string, chainId: ChainId) => async (tokenAddress: string) => {
        const tokenContract: ERC20 = getERC20Contract(tokenAddress).connect(providersByChainId[chainId]);
        try {
            return await tokenContract.allowance(userAddress, allowingContract);
        } catch (e) {
            console.warn('cant fetch allowance', e);
            return BigNumber.from(0);
        }
    };

    // if amount is string -> display amount, if BigNumber -> wei
    const getAllowancesRequired =
        (allowingContract: string, chainId: ChainId) => async (amounts: { token: TokenDto; amount: string | BigNumber }[]) => {
            //if you're connected to a gnosis safe, you can bypass the need for a token approval transaction
            if (!!safeInfo)
                return Promise.resolve({ tokensRequireApproval: false, allowancesRequired: {} as ApprovalsRequired, approveTxCount: 0 });
            else {
                const tokenTotals = amounts.reduce<Record<string, BigNumber>>((acc, { token, amount }) => {
                    const claimAmount = typeof amount === 'string' ? parseUnits(amount, token.decimals) : amount;
                    const total = acc[token.address] ?? BigNumber.from(0);
                    return {
                        ...acc,
                        [token.address]: total.add(claimAmount),
                    };
                }, {});
                console.log('tokenTotals', tokenTotals);
                const allowancesRequired = await Object.entries(tokenTotals).reduce<Promise<ApprovalsRequired>>(
                    async (acc, [tokenAddress, amount]) => {
                        // native tokens don't need approval
                        if (tokenAddress === constants.AddressZero) return acc;

                        const allowance = await getAllowanceForTokenAddress(allowingContract, chainId)(tokenAddress);
                        console.log('allowance', allowance);
                        console.log('amount', amount);

                        const allowanceNeeded: BigNumber = allowance.eq(ZERO_BIGNUMBER)
                            ? amount
                            : allowance.gte(amount)
                            ? ZERO_BIGNUMBER
                            : amount;
                        console.log('allowanceNeeded', allowanceNeeded);
                        return allowanceNeeded.eq(ZERO_BIGNUMBER) ? acc : { ...(await acc), [tokenAddress]: allowanceNeeded };
                    },
                    Promise.resolve({}),
                );

                const tokensRequireApproval = Object.values(allowancesRequired).filter(amount => amount.gt(ZERO_BIGNUMBER)).length;

                return {
                    tokensRequireApproval: !!tokensRequireApproval,
                    allowancesRequired,
                    approveTxCount: tokensRequireApproval,
                };
            }
        };

    const approveTokens = (allowingContract: string) => async (amounts: { amount: string | BigNumber; token: TokenDto }[]) => {
        setExecuting(true);
        setApproveModalOpen(true);
        try {
            const { allowancesRequired } = await getAllowancesRequired(allowingContract, connectedNetwork)(amounts);
            console.log('allowancesRequired', allowancesRequired);
            const approvals = Object.entries(allowancesRequired).map(([tokenAddress, allowanceNeeded]) =>
                getERC20Contract(tokenAddress)
                    .connect(signer)
                    .approve(allowingContract, approvalType === 'exact-allowance' ? allowanceNeeded : constants.MaxUint256),
            );
            console.log('approvals', approvals);
            for (const approval of approvals) {
                const _approval = await approval;
                addPendingTxn(signerProvider, connectedNetwork, _approval.hash);
                await waitForTransaction(connectedNetwork, signerProvider, _approval.hash);
            }
            return { success: true };
        } catch (e: any) {
            console.error(e);
            addErrorMessage(e);
            return { success: false };
        } finally {
            setExecuting(false);
            closeApproveModal();
        }
    };

    return [executing, { approveTokens, getAllowancesRequired, getAllowanceForTokenAddress, approveModalOpen, closeApproveModal }];
};
