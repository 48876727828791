import { usePrevious } from '@chakra-ui/react';
import { BigNumber, providers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { getERC20Contract } from '../data-lib/dto/contract-interfaces';
import { PricesServiceRequest } from '../data-lib/dto/external-prices-dto';
import { addressEquality, EthAddress } from '../data-lib/ethereum';
import { ChainId, NETWORKS, SUPPORTED_NETWORKS, TokenInfo, TokenVariant } from '../data-lib/networks';
import { KnownTokenVariant, TokenBalances, TokenPricesBySymbol, TokenPricesByVariant } from '../data-lib/tokens';
import { userIsViewingPage } from '../tools/common';
import { usePricesApi } from './usePricesApi';
import { useSelectedNetworks } from './useSelectedNetworks';
import { useTokenRepo } from './useTokenRepo';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

type Subscription<T> = {
    unsubscribe: () => void;
    subscribe: () => void;
    current: T;
};

type ChainDataContext =
    | 'uninitialized'
    | {
          chainDataStatesByChainId: Record<number, ChainDataState<TokenBalances>>;
      };

export type TokenBalancesDictionary = { [addy: string]: number | undefined };

type ChainDataState<T> = {
    consumerCount: number;
    current: T;
    lastUpdatedBlock: number;
    fetchingTokens: Set<string>;
    setNetworkBlock: (blockNumber: number) => void;
    setState: (configurator: (prev: ChainDataState<T>) => ChainDataState<T>) => void;
};

const ChainDataContext = React.createContext<ChainDataContext>('uninitialized');

export type GetTokenPrice = (tokenInfo: TokenInfo) => number | undefined;

type TokenPricesContext = {
    getTokenPrice: GetTokenPrice;
    hash: string;
};

const TokenPricesContext = React.createContext<TokenPricesContext>({
    getTokenPrice: _ => undefined,
    hash: '',
});

const emptyTokenPricesByVariant = Object.values(TokenVariant).reduce(
    (acc, item) => ({ ...acc, [item]: undefined }),
    {},
) as unknown as TokenPricesByVariant;

export const TokenPricesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [tokenPricesByVariant, setTokenPricesByVariant] = useState<TokenPricesByVariant>(emptyTokenPricesByVariant);
    const [tokenPricesBySymbol, setTokenPricesBySymbol] = useState<TokenPricesBySymbol>({});
    const [fetching, setFetching] = useState(false);
    const [pendingTokens, setPendingTokens] = useState<TokenInfo[]>([]);

    const tokensToFetch = pendingTokens
        .filter(
            token =>
                (token.variant !== TokenVariant.UNKNOWN && !tokenPricesByVariant[token.variant]) ||
                (token.variant == TokenVariant.UNKNOWN && !tokenPricesBySymbol[token.token.symbol]),
        )
        .reduce<TokenInfo[]>(
            (acc, item) => [
                ...acc,
                ...(function () {
                    if (item.variant == TokenVariant.UNKNOWN) {
                        return acc.map(x => x.token.symbol).includes(item.token.symbol) ? [] : [item];
                    }
                    return acc.map(x => x.variant).includes(item.variant) ? [] : [item];
                })(),
            ],
            [],
        );

    const { fetchExternalPrices } = usePricesApi();

    useEffect(() => {
        if (tokensToFetch.length == 0 || fetching) {
            return;
        }
        setFetching(true);

        fetchAllTokens();

        async function fetchTokens(tokenInfos: TokenInfo[]): Promise<[TokenInfo, number | undefined][]> {
            const request: PricesServiceRequest = {
                currency: 'usd',
                body: tokenInfos.map(tokenInfo => ({
                    chainId: tokenInfo.chainId,
                    contractAddress: tokenInfo.token.address,
                    amount: '1',
                    timestamp: Math.floor(Date.now() / 1000),
                })),
            };

            const prices = await fetchExternalPrices(request, 'get-current-prices');
            return tokenInfos.map((tokenInfo, index) => {
                const priceValue = prices[index]?.price;
                const price = priceValue !== undefined && !isNaN(parseFloat(priceValue)) ? parseFloat(priceValue) : undefined;
                return [tokenInfo, price];
            });
        }

        async function fetchAllTokens() {
            const pricesByToken = await fetchTokens(tokensToFetch);
            console.log('pricesByToken', pricesByToken);
            const tokensToSet = pricesByToken.filter(([_, price]) => !!price);
            const tokensToSetByVariant = tokensToSet.filter(([token]) => token.variant !== TokenVariant.UNKNOWN);
            const tokensToSetBySymbol = tokensToSet.filter(([token]) => token.variant === TokenVariant.UNKNOWN);

            if (tokensToSetByVariant.length !== 0)
                setTokenPricesByVariant(prev => ({
                    ...tokensToSetByVariant.reduce<Record<KnownTokenVariant, number | undefined>>(
                        (acc, item) => ({ ...acc, [item[0].variant]: item[1] }),
                        prev,
                    ),
                }));

            if (tokensToSetBySymbol.length !== 0)
                setTokenPricesBySymbol(prev => ({
                    ...prev,
                    ...tokensToSetBySymbol.reduce<Record<string, number | undefined>>(
                        (acc, item) => ({ ...acc, [item[0].token.symbol]: item[1] }),
                        {},
                    ),
                }));

            setPendingTokens([]);
            setFetching(false);
        }
    }, [tokensToFetch]);

    const getTokenPrice = (tokenInfo: TokenInfo) => {
        if (tokenInfo.variant == TokenVariant.UNKNOWN) {
            const maybePrice = tokenPricesBySymbol[tokenInfo.token.symbol];
            if (!!maybePrice) return maybePrice;
        } else {
            const maybePrice = tokenPricesByVariant[tokenInfo.variant];
            if (!!maybePrice) return maybePrice;
        }

        setPendingTokens(prev => [...prev, tokenInfo]);
        return undefined;
    };

    const hash = JSON.stringify({ tokenPricesBySymbol, tokenPricesByVariant });

    const context = useMemo(() => ({ getTokenPrice, hash }), [hash]);
    return <TokenPricesContext.Provider value={context}>{children}</TokenPricesContext.Provider>;
};

const initialChainDataState: ChainDataState<TokenBalances | undefined> = {
    consumerCount: 0,
    current: undefined,
    lastUpdatedBlock: 0,
    fetchingTokens: new Set<string>(),
    setNetworkBlock: _ => {},
    setState: _ => {},
};

const initialNetworkBlocksByChainId = Object.fromEntries(SUPPORTED_NETWORKS.map(chainId => [chainId, 0]));
const initialTokensToTrackByChainId = Object.fromEntries(SUPPORTED_NETWORKS.map(chainId => [chainId, new Set<string>()]));
const initialTokensBalancesByChainId = Object.fromEntries(SUPPORTED_NETWORKS.map(chainId => [chainId, {}]));

const ChainDataProvider = ({ children }: { children: React.ReactNode }) => {
    const { connectedNetwork, connectedNetworkConfig, providersByChainId } = useWeb3();
    const { getTokenByChainIdAndAddress } = useTokenRepo();

    const actingWallet = useActingWalletAddress();
    const lastActingWalletAddress = usePrevious(actingWallet);
    const [networkBlockByChainId, setNetworkBlockByChainId] = React.useState<Record<number, number>>(initialNetworkBlocksByChainId);
    const { selectedNetworks } = useSelectedNetworks();
    const [tokensToTrackByChainId, setTokensToTrackByChainId] = React.useState<Record<number, Set<string>>>(initialTokensToTrackByChainId);
    const [tokenBalancesByChainId, setTokenBalancesByChainId] =
        React.useState<Record<number, TokenBalancesDictionary>>(initialTokensBalancesByChainId);

    const setNetworkBlockForChainId = (chainId: ChainId) => (blockNumber: number) =>
        setNetworkBlockByChainId(prev => ({ ...prev, [chainId]: blockNumber }));

    const getBalanceForToken =
        (network: ChainId, tokenBalancesByChainId: Record<number, TokenBalancesDictionary>) => (_tokenAddress: string) => {
            const tokenAddress = _tokenAddress.toLowerCase();
            const currentBalance = tokenBalancesByChainId[network][tokenAddress];

            if (currentBalance !== undefined) return currentBalance;

            if (!tokensToTrackByChainId[network].has(tokenAddress))
                setTokensToTrackByChainId(prev => ({ ...prev, [network]: prev[network].add(tokenAddress) }));

            return undefined;
        };

    const getInitialStateValue = (): Record<number, ChainDataState<TokenBalances>> =>
        Object.fromEntries(
            SUPPORTED_NETWORKS.map(network => [
                network,
                {
                    ...initialChainDataState,
                    setNetworkBlock: setNetworkBlockForChainId(network),
                    setState: configurator => setChainDataStatesByChainId(prev => ({ ...prev, [network]: configurator(prev[network]) })),
                    current: {
                        nonce: 0,
                        getBalanceForToken: getBalanceForToken(network, tokenBalancesByChainId),
                    },
                },
            ]),
        );
    const [chainDataStatesByChainId, setChainDataStatesByChainId] =
        React.useState<Record<number, ChainDataState<TokenBalances>>>(getInitialStateValue);

    const _fetchTokenBalances = async (tokens: TokenInfo[], provider: providers.Provider, initialActingWalletAddress: EthAddress) =>
        await tokens.reduce<Promise<TokenBalancesDictionary>>(
            async (tokenBalances, { token }) => ({
                ...(await tokenBalances),
                [token.address.toLowerCase()]: +formatUnits(
                    token.isNative
                        ? await provider.getBalance(initialActingWalletAddress)
                        : await (async () => {
                              try {
                                  return await getERC20Contract(token.address).connect(provider).balanceOf(initialActingWalletAddress);
                              } catch (e) {
                                  console.warn({ e, token });
                                  return BigNumber.from(0);
                              }
                          })(),
                    token.decimals,
                ),
            }),
            Promise.resolve({}),
        );

    const onBlockUpdate = async (chainId: ChainId, tokensToFetch: Set<string>, currentBlock: number) => {
        const tokenBalances = chainDataStatesByChainId[chainId];
        const provider = providersByChainId[chainId];

        if (tokenBalances.fetchingTokens.size == 0 || lastActingWalletAddress !== actingWallet) {
            setChainDataStatesByChainId(prev => ({ ...prev, [chainId]: { ...prev[chainId], fetchingTokens: tokensToFetch } }));
            const balances = await _fetchTokenBalances(
                [...tokensToFetch].map(x => getTokenByChainIdAndAddress(chainId)(x)).filter((x): x is TokenInfo => x !== undefined),
                provider,
                actingWallet,
            );
            console.debug(`${NETWORKS[chainId].label} balances`, { balances });
            setTokenBalancesByChainId(prev => {
                const newBalances = { ...prev, [chainId]: { ...prev[chainId], ...balances } };
                setChainDataStatesByChainId(prev => ({
                    ...prev,
                    [chainId]: {
                        ...prev[chainId],
                        lastUpdatedBlock: currentBlock,
                        fetchingTokens: new Set<string>(),
                        current: { getBalanceForToken: getBalanceForToken(chainId, newBalances), nonce: prev[chainId].current.nonce + 1 },
                    },
                }));

                return newBalances;
            });
        }
    };

    const getTokensToFetchSetForChain = (chainId: ChainId) => {
        const tokenSet = tokensToTrackByChainId[chainId];
        const chainDataState = chainDataStatesByChainId[chainId];
        [...chainDataState.fetchingTokens].forEach(x => tokenSet.delete(x));
        Object.keys(chainDataState.current ?? {}).forEach(x => tokenSet.delete(x));

        return tokenSet;
    };

    useEffect(() => {
        if (lastActingWalletAddress !== actingWallet) {
            setNetworkBlockByChainId(initialNetworkBlocksByChainId);
            setChainDataStatesByChainId(getInitialStateValue());
            setTokenBalancesByChainId(initialTokensBalancesByChainId);
        }
    }, [actingWallet]);

    useEffect(() => {
        const networkBlock = networkBlockByChainId[connectedNetwork];
        const provider = providersByChainId[connectedNetwork];
        const currentBlockPromise = networkBlock === 0 ? provider.getBlockNumber() : Promise.resolve(networkBlock);

        currentBlockPromise.then(currentBlock => {
            const tokenBalances = chainDataStatesByChainId[connectedNetwork];
            const isInitialFetch = tokenBalances.lastUpdatedBlock === 0;
            const updateDue = tokenBalances.lastUpdatedBlock + connectedNetworkConfig.updateEveryNBlock <= currentBlock;
            const newTokensAdded = getTokensToFetchSetForChain(connectedNetwork);

            const requiresFullFetch =
                isInitialFetch || (userIsViewingPage() && updateDue) || !addressEquality(lastActingWalletAddress, actingWallet);

            if (requiresFullFetch || newTokensAdded.size !== 0) {
                onBlockUpdate(
                    connectedNetwork,
                    requiresFullFetch ? tokensToTrackByChainId[connectedNetwork] : newTokensAdded,
                    currentBlock,
                );
            }
        });
    }, [networkBlockByChainId[connectedNetwork], actingWallet, tokensToTrackByChainId[connectedNetwork].size]);

    useEffect(() => {
        const otherNetworks = selectedNetworks.filter(x => x !== connectedNetwork);
        const networksAndTokens = otherNetworks
            .map((chainId): [ChainId, Set<string>] => [chainId, getTokensToFetchSetForChain(chainId)])
            .filter(([_, tokenSet]) => tokenSet.size !== 0);
        Promise.all(networksAndTokens.map(([chainId, tokenSet]) => onBlockUpdate(chainId, tokenSet, 1))); // Current block doesn't actually matter for non-connected networks, it's mostly for polling networks (connected network)
    }, [
        actingWallet,
        selectedNetworks.join(),
        Object.values(chainDataStatesByChainId).map(x => x.fetchingTokens.size),
        tokensToTrackByChainId,
    ]);

    const context = useMemo(() => ({ chainDataStatesByChainId }), [chainDataStatesByChainId]);

    return <ChainDataContext.Provider value={context}>{children}</ChainDataContext.Provider>;
};

const defaultTokenBalances: TokenBalances = { getBalanceForToken: _ => undefined, nonce: -1 };

const useChainData = (chainId: ChainId) => {
    const context = React.useContext(ChainDataContext);
    const { providersByChainId } = useWeb3();

    if (!context) throw new Error('Error: you must call useChainData with the ChainDataProvider');

    if (context === 'uninitialized')
        return {
            tokenBalances: {
                unsubscribe: () => {},
                subscribe: function () {},
                current: defaultTokenBalances,
            },
        };

    const chainDataState = context.chainDataStatesByChainId[chainId];

    if (!chainDataState)
        return {
            tokenBalances: {
                unsubscribe: () => {},
                subscribe: function () {},
                current: defaultTokenBalances,
            },
        };

    const { current, consumerCount, setNetworkBlock, setState } = chainDataState;
    const provider = providersByChainId[chainId];

    const tokenBalances: Subscription<TokenBalances> = useMemo(
        () => ({
            unsubscribe: () => {},
            subscribe: function () {
                if (consumerCount === 0) provider.on('block', setNetworkBlock);

                setState(prev => ({ ...prev, consumerCount: prev.consumerCount + 1 }));

                this.unsubscribe = () => {
                    setState(prev => {
                        const newConsumers = prev.consumerCount - 1;
                        if (newConsumers === 0) provider.removeListener('block', setNetworkBlock);
                        return { ...prev, consumerCount: newConsumers };
                    });
                };
            },
            current,
        }),
        [current],
    );

    return { tokenBalances };
};

export const useTokenBalances = ({ chainId, poll }: { chainId: ChainId; poll?: boolean }) => {
    const { tokenBalances } = useChainData(chainId);

    useEffect(() => {
        if (poll) tokenBalances.subscribe();
        return () => {
            if (poll) tokenBalances.unsubscribe();
        };
    }, [poll]);

    return useMemo(() => tokenBalances.current, [tokenBalances, poll]);
};

export const useTokenPrices = () => {
    const context = React.useContext(TokenPricesContext);
    if (!context) throw new Error('Error: you must call useTokenPrices with the TokenPricesProvider');
    return context;
};

export { ChainDataProvider, useChainData };
