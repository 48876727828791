import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Divider, Flex, Heading, HStack, Spacer, Text } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { ArrowLeft } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import { ContactNameOrAddress, CopyableTextLabel, shortAddress } from '../../components/base/address-label';
import { WithSkeleton } from '../../components/base/skeleton';
import { BaseBadge } from '../../components/base/status-badge';
import { TokenAmount } from '../../components/currency/token-display-amount';
import { mapBullaItemInfoToTableItem, PageSelector, TableItem } from '../../components/display/claim-table';
import { TabSwitcher } from '../../components/display/views/account-tag-view';
import {
    buildInvoiceFactoringTableFilters,
    factoringInvoiceFilterToPills,
    InvoiceFactoringTableFilter,
} from '../../components/display/views/filters/claim-filter';
import { ClearFilterPillsStack } from '../../components/display/views/filters/common';
import { CashDataPoint, CashViewChart } from '../../components/factoring/cash-chart';
import {
    calculatePriceChange,
    calculateTodayReturn,
    calculateTotalReturn,
    filterCashDataByTimeRange,
    filterPriceDataByTimeRange,
    mapTimestampAndCashHistory,
    mapTimestampAndPrice,
    PriceDataPoint,
    TimeRange,
} from '../../components/factoring/common';
import { DepositRedeemButtons } from '../../components/factoring/deposit-redeem-buttons';
import { DepositRedemptionTotals } from '../../components/factoring/deposit-redemption-totals';
import { InfoText, poolInfoState } from '../../components/factoring/factoring-pool-card';
import { AverageCostCard, PositionCard } from '../../components/factoring/position-and-average-cost-card';
import { FactoringPriceChart } from '../../components/factoring/price-chart';
import { ViewDetailsButton } from '../../components/inputs/buttons';
import { ColumnDefinition, ListViewCard, VIEW_COLUMN_WIDTH } from '../../components/layout/cards';
import { MaxWidthWrapper, PageLayoutProvider } from '../../components/layout/page-layout';
import { ClaimInfo, ClaimStatus, PoolDepositInfo, PoolEventInfo, PoolRedemptionInfo } from '../../data-lib/data-model';
import { InvoiceFundedEvent, InvoiceReconciledEvent, InvoiceUnfactoredEvent } from '../../data-lib/domain/factoring-domain';
import { addressEquality } from '../../data-lib/ethereum';
import { isUnfactored } from '../../data-lib/helpers';
import { FactoringConfig, NETWORKS, TokenVariant } from '../../data-lib/networks';
import { TOKEN_ROUNDING } from '../../data-lib/tokens';
import { fetchFactoringHistoricalCash, fetchFactoringTokenHistoricalPrices, useBullaFactoring } from '../../hooks/useBullaFactoring';
import { useTokenBalances } from '../../hooks/useChainData';
import { useOpenBullaItem } from '../../hooks/useClaimDetailDisclosure';
import { usePagination } from '../../hooks/usePagination';
import { usePoolDetailsRepo } from '../../hooks/usePoolDetailsRepo';
import { useTokenRepo } from '../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { toDateDisplay } from '../../tools/common';
import { handleExport, toReportDateTimeString } from '../../tools/excelExport';
import ExportMenu from '../../tools/exportMenu';

interface PoolDetailsState {
    status: 'loading' | 'error' | 'success';
    priceData: PriceDataPoint[];
    cashData: CashDataPoint[];
    latestCashIndicators: CashDataPoint | null;
    currentPrice: number | null;
    priceChange: number | null;
    poolInfo: poolInfoState;
    averageCost: number | null;
    todayReturn: { absolute: number; percentage: number };
    totalReturn: { absolute: number; percentage: number };
}

interface PoolDetailsViewProps {
    factoringConfig: FactoringConfig;
    onBack: () => void;
    hasDepositPermissions: boolean;
}

type PoolTableDetailTab = 'DepositRedemptions' | 'Invoices';
type PoolGraphTab = 'BullaFinanceToken' | 'CashView';

const getHeaders = (tab: PoolTableDetailTab): ColumnDefinition[] => {
    switch (tab) {
        case 'DepositRedemptions':
            return [
                { label: 'WALLET', relativeColumnWidth: '1fr' },
                { label: 'DATE', relativeColumnWidth: '1fr' },
                { label: 'ACTION', relativeColumnWidth: '1fr' },
                { label: 'AMOUNT', relativeColumnWidth: '1fr' },
                { label: 'SHARE PRICE', relativeColumnWidth: '1fr' },
                { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
            ];
        case 'Invoices':
            return [
                { label: 'DEBTOR', relativeColumnWidth: 'auto' },
                { label: 'ORIGINAL CREDITOR', relativeColumnWidth: 'auto' },
                { label: 'INVOICE ID', relativeColumnWidth: 'auto' },
                { label: 'STATUS', relativeColumnWidth: 'auto' },
                { label: 'FUNDED', relativeColumnWidth: 'auto' },
                { label: 'DUE', relativeColumnWidth: 'auto' },
                { label: 'DESCRIPTION', relativeColumnWidth: 'auto' },
                { label: 'EXPECTED FUNDING DAYS', relativeColumnWidth: '120px' },
                { label: 'ACTUAL FUNDING DAYS', relativeColumnWidth: '120px' },
                { label: 'INVOICE AMOUNT', relativeColumnWidth: 'auto' },
                { label: 'FUNDED AMOUNT', relativeColumnWidth: 'auto' },
                { label: 'TARGET ADMIN FEE', relativeColumnWidth: 'auto' },
                { label: 'TARGET INTEREST', relativeColumnWidth: 'auto' },
                { label: 'TARGET PROTOCOL FEE', relativeColumnWidth: 'auto' },
                { label: 'TARGET TAX', relativeColumnWidth: 'auto' },
                { label: 'TARGET KICKBACK', relativeColumnWidth: 'auto' },
                { label: 'TRUE ADMIN FEE', relativeColumnWidth: 'auto' },
                { label: 'TRUE INTEREST', relativeColumnWidth: 'auto' },
                { label: 'TRUE PROTOCOL FEE', relativeColumnWidth: 'auto' },
                { label: 'TRUE TAX', relativeColumnWidth: 'auto' },
                { label: 'TRUE KICKBACK', relativeColumnWidth: 'auto' },
                { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
            ];
    }
};

const filterPoolEvents = (
    events: (PoolDepositInfo | PoolRedemptionInfo)[],
    factoringConfig: FactoringConfig,
): (PoolDepositInfo | PoolRedemptionInfo)[] => {
    return events.filter(
        event =>
            event.chainId === factoringConfig.bullaFactoringToken.chainId &&
            addressEquality(event.poolAddress, factoringConfig.bullaFactoringToken.token.address),
    );
};

const filterFactoredInvoices = (claims: ClaimInfo[], factoringConfig: FactoringConfig): ClaimInfo[] => {
    return claims.filter(
        claim =>
            claim.chainId === factoringConfig.bullaFactoringToken.chainId &&
            claim.logs.some(
                log => 'poolAddress' in log && addressEquality(log.poolAddress, factoringConfig.bullaFactoringToken.token.address),
            ),
    );
};

export type InvoiceTableFactoringFilterValues = {
    search: string | undefined;
    date: { startDate?: Date; endDate?: Date };
    selectedWallets: Set<string>;
    claimStatus?: ClaimStatus;
};

export const emptyFactoringInvoiceTableFilterValues: InvoiceTableFactoringFilterValues = {
    search: '',
    date: { startDate: undefined, endDate: undefined },
    selectedWallets: new Set(),
};

export const PoolDetailsView: React.FC<PoolDetailsViewProps> = ({ factoringConfig, onBack, hasDepositPermissions }) => {
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const [_, { getPoolInfo, getTotalSupply, calculateAverageCostPerUser }] = useBullaFactoring(factoringConfig);
    const userAddress = useActingWalletAddress();
    const fundTokenInfo = factoringConfig.bullaFactoringToken.token;
    const openItem = useOpenBullaItem();
    const { poolDeposits, poolRedemptions, factoredInvoices } = usePoolDetailsRepo();

    const { sortedTableItems: sortedDepositAndRedemptionTableItems, summary: depositAndRedemptionSummary } = useMemo(() => {
        const currentPoolDeposits = filterPoolEvents(poolDeposits, factoringConfig);
        const currentPoolRedemptions = filterPoolEvents(poolRedemptions, factoringConfig);
        const poolDepositsAndRedemption = [...currentPoolDeposits, ...currentPoolRedemptions];
        const sortedTableItems = mapBullaItemInfoToTableItem(poolDepositsAndRedemption, userAddress, openItem).sort(
            (a, b) => b.displayDate.getTime() - a.displayDate.getTime(),
        );

        const summary = poolDepositsAndRedemption.reduce(
            (acc, event) => {
                const amount = BigNumber.from(event.paidAmount);
                if (event.__type === 'PoolDeposit') {
                    acc.totalDeposits = acc.totalDeposits.add(amount);
                } else {
                    acc.totalRedemptions = acc.totalRedemptions.add(amount);
                }
                return acc;
            },
            { totalDeposits: BigNumber.from(0), totalRedemptions: BigNumber.from(0) },
        );

        const total = summary.totalDeposits.sub(summary.totalRedemptions);
        return { sortedTableItems, summary: { ...summary, total } };
    }, [poolDeposits, poolRedemptions, factoringConfig]);

    const currentFactoredInvoices = useMemo(
        () => filterFactoredInvoices(factoredInvoices, factoringConfig),
        [factoredInvoices, factoringConfig],
    );

    const [filters, setFilters] = useState<InvoiceTableFactoringFilterValues>(emptyFactoringInvoiceTableFilterValues);
    const [claimFilters, setClaimFilters] = useState<((item: TableItem) => boolean)[]>([]);

    useEffect(() => {
        setClaimFilters(buildInvoiceFactoringTableFilters(filters));
    }, [filters]);

    const invoiceTableItems: TableItem[] = mapBullaItemInfoToTableItem(currentFactoredInvoices, userAddress, openItem);
    const filterItems = (items: TableItem[]) => claimFilters.reduce((claims, filterFunc) => claims.filter(filterFunc), [...items]);
    const filteredInvoiceTableItems = filterItems(invoiceTableItems);

    const poolChainId = factoringConfig.bullaFactoringToken.chainId;
    const underlyingTokenInfo = factoringConfig.poolUnderlyingToken.token;
    const tokenBalances = useTokenBalances({ chainId: poolChainId, poll: true });
    const tokenRouding =
        TOKEN_ROUNDING[getTokenByChainIdAndAddress(poolChainId)(fundTokenInfo.address.toLowerCase())?.variant ?? TokenVariant.UNKNOWN];

    const currentTokenBalance =
        tokenBalances
            .getBalanceForToken(fundTokenInfo.address)
            ?.toFixed(tokenRouding + 1)
            .toString() ?? '0';

    const holdsNoBFT = currentTokenBalance == '0';

    const [timeRange, setTimeRange] = useState<TimeRange>('1W');
    const [state, setState] = useState<PoolDetailsState>({
        status: 'loading',
        priceData: [],
        cashData: [],
        latestCashIndicators: null,
        currentPrice: null,
        priceChange: null,
        poolInfo: { type: 'init' },
        averageCost: null,
        todayReturn: { absolute: 0, percentage: 0 },
        totalReturn: { absolute: 0, percentage: 0 },
    });
    const [activeTableTab, setActiveTableTab] = useState<PoolTableDetailTab>('DepositRedemptions');
    const [activeGraphTab, setActiveGraphTab] = useState<PoolGraphTab>('BullaFinanceToken');

    const filteredPriceData = useMemo(() => filterPriceDataByTimeRange(state.priceData, timeRange), [state.priceData, timeRange]);
    const filteredCashData = useMemo(() => filterCashDataByTimeRange(state.cashData, timeRange), [state.cashData, timeRange]);

    const yAxisPriceDomain = useMemo(() => {
        if (filteredPriceData.length === 0) return [0, 2];

        const maxPrice = Math.max(...filteredPriceData.map(d => d.price));
        const minPrice = Math.min(...filteredPriceData.map(d => d.price));

        const padding = maxPrice == minPrice ? 0.1 : (maxPrice - minPrice) * 0.5;
        return [Math.max(0, minPrice - padding), Math.min(2, maxPrice + padding)];
    }, [filteredPriceData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [historicalPrices, historicalCash, poolInfoResult, tokensOutstanding, averageCost] = await Promise.all([
                    fetchFactoringTokenHistoricalPrices(poolChainId, fundTokenInfo.address),
                    fetchFactoringHistoricalCash(poolChainId, fundTokenInfo.address),
                    getPoolInfo(),
                    getTotalSupply(),
                    userAddress ? calculateAverageCostPerUser(userAddress) : null,
                ]);

                const mappedPrices: PriceDataPoint[] = mapTimestampAndPrice(historicalPrices, fundTokenInfo.decimals);
                const mappedHistoricalCash: CashDataPoint[] = mapTimestampAndCashHistory(historicalCash, fundTokenInfo.decimals);
                const latestPrice = mappedPrices.length > 0 ? mappedPrices[mappedPrices.length - 1].price : null;
                const latestCashIndicators: CashDataPoint | null =
                    mappedHistoricalCash.length > 0 ? mappedHistoricalCash[mappedHistoricalCash.length - 1] : null;
                const priceChange = calculatePriceChange(mappedPrices);

                const todayReturn = calculateTodayReturn(mappedPrices, latestPrice);
                const totalReturn = calculateTotalReturn(averageCost, latestPrice, currentTokenBalance);

                setState({
                    status: 'success',
                    priceData: mappedPrices,
                    cashData: mappedHistoricalCash,
                    latestCashIndicators,
                    currentPrice: latestPrice,
                    priceChange,
                    poolInfo:
                        poolInfoResult && tokensOutstanding
                            ? { type: 'fetched', poolInfo: { ...poolInfoResult, tokensOutstanding } }
                            : { type: 'not-found' },
                    averageCost,
                    todayReturn,
                    totalReturn,
                });
            } catch (error) {
                setState(prevState => ({ ...prevState, status: 'error' }));
            }
        };

        fetchData();
    }, [poolChainId, fundTokenInfo.address, fundTokenInfo.decimals, userAddress, currentTokenBalance]);

    const pageSelectorPropsDepositAndRedemption = usePagination(sortedDepositAndRedemptionTableItems);
    const visibleDepositAndRedemptionTableItems = pageSelectorPropsDepositAndRedemption.shownItems;

    const depositAndRedemptionTableItems = React.useMemo(() => {
        return visibleDepositAndRedemptionTableItems.map(item => {
            const isDeposit = item.__type === 'PoolDeposit';
            const amountDisplay = (
                <TokenAmount amount={BigNumber.from(item.paidAmount)} tokenInfo={factoringConfig.poolUnderlyingToken} withRounding />
            );
            const priceDisplay = (
                <TokenAmount
                    amount={BigNumber.from((item as PoolEventInfo).priceAfterTransaction)}
                    tokenInfo={factoringConfig.poolUnderlyingToken}
                    withRounding
                />
            );
            const date = toDateDisplay(item.displayDate);
            const action = isDeposit ? (
                <BaseBadge bg="#ECFDF3" color="#067647" border="1px solid #ABEFC6">
                    Deposit
                </BaseBadge>
            ) : (
                <BaseBadge bg="#FDE7E7" color="#B91C1C" border="1px solid #FCA5A5">
                    Redemption
                </BaseBadge>
            );

            return {
                columnValues: [
                    <CopyableTextLabel
                        displayValue={shortAddress(isDeposit ? item.debtor : item.creditor, 8)}
                        children={isDeposit ? item.debtor : item.creditor}
                        variant="address"
                    />,
                    date,
                    action,
                    amountDisplay,
                    priceDisplay,
                    <ViewDetailsButton onClick={item.onClick} />,
                ],
            };
        });
    }, [visibleDepositAndRedemptionTableItems, fundTokenInfo, underlyingTokenInfo, poolChainId, factoringConfig]);

    const sortedFactoringInvoicesTableItems = [...filteredInvoiceTableItems].sort(
        (a, b) => b.displayDate.getTime() - a.displayDate.getTime(),
    );

    const handleInvoicesExport = React.useCallback(
        async (method: 'excel' | 'csv') => {
            const headers = [
                'DEBTOR',
                'ORIGINAL CREDITOR',
                'INVOICE ID',
                'STATUS',
                'FUNDED DATE',
                'DUE DATE',
                'DESCRIPTION',
                'EXPECTED FUNDING DAYS',
                'ACTUAL FUNDING DAYS',
                'TOKEN SYMBOL',
                'INVOICE AMOUNT',
                'FUNDED AMOUNT',
                'TARGET ADMIN FEE',
                'TARGET INTEREST',
                'TARGET PROTOCOL FEE',
                'TARGET TAX',
                'TARGET KICKBACK',
                'TRUE ADMIN FEE',
                'TRUE INTEREST',
                'TRUE PROTOCOL FEE',
                'TRUE TAX',
                'TRUE KICKBACK',
            ];

            const rows = [...filteredInvoiceTableItems]
                .sort((a, b) => a.displayDate.getTime() - b.displayDate.getTime())
                .filter((x): x is ClaimInfo & TableItem => x.__type == 'Claim')
                .map(item => {
                    const unfactoredClaim = isUnfactored(item);

                    const status = unfactoredClaim ? 'Unfactored' : item.itemStatus === 'Paid' ? 'Paid' : 'Pending';
                    const dueDate = toReportDateTimeString(item.displayDate);
                    const invoiceFundedEvent = item.logs.filter((x): x is InvoiceFundedEvent => x.__typename == 'InvoiceFundedEvent')[0]!;

                    const invoiceReconciledEvents = item.logs.filter(
                        (x): x is InvoiceReconciledEvent => x.__typename == 'InvoiceReconciledEvent',
                    );
                    const invoiceReconciledEvent = invoiceReconciledEvents.length > 0 ? invoiceReconciledEvents[0] : undefined;

                    const invoiceUnfactoredEvents = item.logs.filter(
                        (x): x is InvoiceUnfactoredEvent => x.__typename == 'InvoiceUnfactoredEvent',
                    );
                    const invoiceUnfactoredEvent = invoiceUnfactoredEvents.length > 0 ? invoiceUnfactoredEvents[0] : undefined;

                    const factoringEndingEvent = invoiceReconciledEvent ?? invoiceUnfactoredEvent;

                    const factoringDate = toReportDateTimeString(invoiceFundedEvent.blocktime!);

                    const expectedFundingDays = Math.ceil(
                        (item.dueBy.getTime() - invoiceFundedEvent.blocktime!.getTime()) / (3600 * 24 * 1000),
                    );

                    const actualFundingDays = factoringEndingEvent
                        ? Math.ceil(
                              (factoringEndingEvent!.blocktime!.getTime() - invoiceFundedEvent.blocktime!.getTime()) / (3600 * 24 * 1000),
                          )
                        : '';

                    const trueFaceValue = item.claimAmount.sub(item.paidAmount);
                    const expectedKickback = trueFaceValue
                        .sub(invoiceFundedEvent.targetInterest!)
                        .sub(invoiceFundedEvent.targetAdminFee!)
                        .sub(invoiceFundedEvent.targetProtocolFee!)
                        .sub(invoiceFundedEvent.fundedAmount);

                    const trueKickback = factoringEndingEvent
                        ? item.claimAmount
                              .sub(factoringEndingEvent.trueInterest!)
                              .sub(factoringEndingEvent.trueAdminFee!)
                              .sub(factoringEndingEvent.trueProtocolFee!)
                              .sub(invoiceFundedEvent.fundedAmount)
                        : '';

                    return [
                        item.debtor,
                        item.originalCreditor,
                        item.id,
                        status,
                        factoringDate,
                        dueDate,
                        item.description,
                        expectedFundingDays,
                        actualFundingDays,
                        item.tokenInfo.token.symbol,
                        formatUnits(item.claimAmount, item.tokenInfo.token.decimals),
                        formatUnits(invoiceFundedEvent.fundedAmount, item.tokenInfo.token.decimals),
                        invoiceFundedEvent.targetAdminFee
                            ? formatUnits(invoiceFundedEvent.targetAdminFee, item.tokenInfo.token.decimals)
                            : '',
                        invoiceFundedEvent.targetInterest
                            ? formatUnits(invoiceFundedEvent.targetInterest, item.tokenInfo.token.decimals)
                            : '',
                        invoiceFundedEvent.targetProtocolFee
                            ? formatUnits(invoiceFundedEvent.targetProtocolFee, item.tokenInfo.token.decimals)
                            : '',
                        invoiceFundedEvent.targetTax ? formatUnits(invoiceFundedEvent.targetTax, item.tokenInfo.token.decimals) : '',
                        formatUnits(expectedKickback, item.tokenInfo.token.decimals),
                        factoringEndingEvent?.trueAdminFee
                            ? formatUnits(factoringEndingEvent.trueAdminFee, item.tokenInfo.token.decimals)
                            : '',
                        factoringEndingEvent?.trueInterest
                            ? formatUnits(factoringEndingEvent.trueInterest, item.tokenInfo.token.decimals)
                            : '',
                        factoringEndingEvent?.trueProtocolFee
                            ? formatUnits(factoringEndingEvent.trueProtocolFee, item.tokenInfo.token.decimals)
                            : '',
                        factoringEndingEvent?.trueTax ? formatUnits(factoringEndingEvent.trueTax, item.tokenInfo.token.decimals) : '',
                        factoringEndingEvent ? formatUnits(trueKickback, item.tokenInfo.token.decimals) : '',
                    ];
                });

            await handleExport(method, `Invoices -${factoringConfig.poolName}`, headers, rows);
        },
        [filteredInvoiceTableItems],
    );

    const pageSelectorPropsFactoringInvoices = usePagination(sortedFactoringInvoicesTableItems);
    const visibleFactoringInvoicesItems = pageSelectorPropsFactoringInvoices.shownItems;

    const visibleFactoringInvoicesRows = React.useMemo(
        () =>
            visibleFactoringInvoicesItems
                .filter((x): x is ClaimInfo & TableItem => x.__type == 'Claim')
                .map(item => {
                    const amountDisplay = (
                        <TokenAmount amount={item.claimAmount} tokenInfo={factoringConfig.poolUnderlyingToken} withRounding />
                    );
                    const dueDate = toDateDisplay(item.displayDate);
                    const invoiceFundedEvent = item.logs.filter((x): x is InvoiceFundedEvent => x.__typename == 'InvoiceFundedEvent')[0]!;

                    const invoiceReconciledEvents = item.logs.filter(
                        (x): x is InvoiceReconciledEvent => x.__typename == 'InvoiceReconciledEvent',
                    );
                    const invoiceReconciledEvent = invoiceReconciledEvents.length > 0 ? invoiceReconciledEvents[0] : undefined;

                    const invoiceUnfactoredEvents = item.logs.filter(
                        (x): x is InvoiceUnfactoredEvent => x.__typename == 'InvoiceUnfactoredEvent',
                    );
                    const invoiceUnfactoredEvent = invoiceUnfactoredEvents.length > 0 ? invoiceUnfactoredEvents[0] : undefined;

                    const factoringEndingEvent = invoiceReconciledEvent ?? invoiceUnfactoredEvent;

                    const factoringDate = toDateDisplay(invoiceFundedEvent.blocktime!);

                    const expectedFundingDays = Math.ceil(
                        (item.dueBy.getTime() - invoiceFundedEvent.blocktime!.getTime()) / (3600 * 24 * 1000),
                    );

                    const actualFundingDays = factoringEndingEvent
                        ? Math.ceil(
                              (factoringEndingEvent!.blocktime!.getTime() - invoiceFundedEvent.blocktime!.getTime()) / (3600 * 24 * 1000),
                          )
                        : '---';
                    const fundedAmount = <TokenAmount amount={invoiceFundedEvent.fundedAmount} tokenInfo={item.tokenInfo} withRounding />;

                    const trueFaceValue = item.claimAmount.sub(item.paidAmount);
                    // full amount to be received minus the upfront amount
                    const expectedKickback = trueFaceValue
                        .sub(invoiceFundedEvent.targetInterest!)
                        .sub(invoiceFundedEvent.targetAdminFee!)
                        .sub(invoiceFundedEvent.targetProtocolFee!)
                        .sub(invoiceFundedEvent.fundedAmount);

                    const expectedKickbackDisplay =
                        invoiceFundedEvent.targetAdminFee && invoiceFundedEvent.targetInterest && invoiceFundedEvent.targetProtocolFee ? (
                            <TokenAmount amount={expectedKickback} tokenInfo={item.tokenInfo} withRounding />
                        ) : (
                            '---'
                        );

                    const targetAdminFee = invoiceFundedEvent.targetAdminFee ? (
                        <TokenAmount amount={invoiceFundedEvent.targetAdminFee} tokenInfo={item.tokenInfo} withRounding />
                    ) : (
                        '---'
                    );

                    const targetInterest = invoiceFundedEvent.targetInterest ? (
                        <TokenAmount amount={invoiceFundedEvent.targetInterest} tokenInfo={item.tokenInfo} withRounding />
                    ) : (
                        '---'
                    );

                    const targetProtocolFee = invoiceFundedEvent.targetProtocolFee ? (
                        <TokenAmount amount={invoiceFundedEvent.targetProtocolFee} tokenInfo={item.tokenInfo} withRounding />
                    ) : (
                        '---'
                    );

                    const targetTax = invoiceFundedEvent.targetTax ? (
                        <TokenAmount amount={invoiceFundedEvent.targetTax} tokenInfo={item.tokenInfo} withRounding />
                    ) : (
                        '---'
                    );

                    const trueAdminFee = factoringEndingEvent?.trueAdminFee ? (
                        <TokenAmount amount={factoringEndingEvent.trueAdminFee} tokenInfo={item.tokenInfo} withRounding />
                    ) : (
                        '---'
                    );

                    const trueInterest = factoringEndingEvent?.trueInterest ? (
                        <TokenAmount amount={factoringEndingEvent.trueInterest} tokenInfo={item.tokenInfo} withRounding />
                    ) : (
                        '---'
                    );

                    const trueProtocolFee = factoringEndingEvent?.trueProtocolFee ? (
                        <TokenAmount amount={factoringEndingEvent.trueProtocolFee} tokenInfo={item.tokenInfo} withRounding />
                    ) : (
                        '---'
                    );

                    const trueTax = factoringEndingEvent?.trueTax ? (
                        <TokenAmount amount={factoringEndingEvent.trueTax} tokenInfo={item.tokenInfo} withRounding />
                    ) : (
                        '---'
                    );

                    const trueKickback = factoringEndingEvent
                        ? item.claimAmount
                              .sub(factoringEndingEvent.trueInterest!)
                              .sub(factoringEndingEvent.trueAdminFee!)
                              .sub(factoringEndingEvent.trueProtocolFee!)
                              .sub(invoiceFundedEvent.fundedAmount)
                        : BigNumber.from(0);

                    const trueKickbackDisplay = factoringEndingEvent ? (
                        <TokenAmount amount={trueKickback} tokenInfo={item.tokenInfo} withRounding />
                    ) : (
                        '---'
                    );

                    const unfactoredClaim = isUnfactored(item);

                    const status = unfactoredClaim ? (
                        <BaseBadge bg="#FEF3F2" color="#B42318" border="1px solid #FECDCA">
                            Unfactored
                        </BaseBadge>
                    ) : item.itemStatus === 'Paid' ? (
                        <BaseBadge bg="#ECFDF3" color="#067647" border="1px solid #ABEFC6">
                            Paid
                        </BaseBadge>
                    ) : (
                        <BaseBadge bg="#FFFAEB" color="#B54708" border="1px solid #FEDF89">
                            Pending
                        </BaseBadge>
                    );

                    return {
                        columnValues: [
                            <ContactNameOrAddress chainId={item.chainId}>{item.debtor}</ContactNameOrAddress>,
                            <ContactNameOrAddress chainId={item.chainId}>{item.originalCreditor!}</ContactNameOrAddress>,
                            <Text>{item.id}</Text>,
                            status,
                            <Text whiteSpace={'nowrap'}>{factoringDate}</Text>,
                            <Text whiteSpace={'nowrap'}>{dueDate}</Text>,
                            <Text whiteSpace={'nowrap'}>{item.description}</Text>,
                            expectedFundingDays,
                            actualFundingDays,
                            amountDisplay,
                            fundedAmount,
                            targetAdminFee,
                            targetInterest,
                            targetProtocolFee,
                            targetTax,
                            expectedKickbackDisplay,
                            trueAdminFee,
                            trueInterest,
                            trueProtocolFee,
                            trueTax,
                            trueKickbackDisplay,
                            <ViewDetailsButton onClick={item.onClick} />,
                        ],
                    };
                }),
        [visibleFactoringInvoicesItems],
    );

    useEffect(() => {
        const change = calculatePriceChange(filteredPriceData);
        setState(prevState => ({ ...prevState, priceChange: change }));
    }, [filteredPriceData]);

    return (
        <PageLayoutProvider>
            <Flex p="12" direction="column" flex="1">
                <MaxWidthWrapper>
                    <Flex direction={'row'} w="100%" justifyContent="space-between" pt="8">
                        <Breadcrumb textColor={'gray.700'}>
                            <BreadcrumbItem>
                                <BreadcrumbLink onClick={onBack}>Bulla Financing Pools</BreadcrumbLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem isCurrentPage>
                                <Text fontWeight="semibold" fontSize="md">
                                    {factoringConfig.poolName}
                                </Text>
                            </BreadcrumbItem>
                        </Breadcrumb>

                        <HStack cursor="pointer" onClick={onBack} color="gray.600">
                            <ArrowLeft size={18} weight="bold" />
                            <Text fontWeight="semibold" fontSize="md">
                                Back to Pool List
                            </Text>
                        </HStack>
                    </Flex>

                    <Flex direction={'row'} w="100%" justifyContent="space-between" pt="8">
                        <Flex>
                            <Heading w="100%">{factoringConfig.poolName}</Heading>
                        </Flex>
                        <DepositRedeemButtons
                            factoringConfig={factoringConfig}
                            hasDepositPermissions={hasDepositPermissions}
                            holdsNoBFT={holdsNoBFT}
                        />
                    </Flex>

                    {state.status === 'success' && state.poolInfo.type === 'fetched' && fundTokenInfo.name ? (
                        <Box mt="8" borderWidth="1px" borderRadius="lg" p="6" shadow="md">
                            <Box mb="6">
                                <TabSwitcher
                                    tab={activeGraphTab}
                                    setTab={setActiveGraphTab}
                                    options={[
                                        {
                                            label: 'Bulla Finance Token',
                                            value: 'BullaFinanceToken',
                                        },
                                        {
                                            label: 'Cash View',
                                            value: 'CashView',
                                        },
                                    ]}
                                    activeColor="brand.bulla_orange"
                                />
                            </Box>
                            {activeGraphTab === 'BullaFinanceToken' ? (
                                <FactoringPriceChart
                                    tokenName={fundTokenInfo.name}
                                    currentPrice={state.currentPrice}
                                    priceChange={state.priceChange}
                                    priceData={filteredPriceData}
                                    timeRange={timeRange}
                                    setTimeRange={setTimeRange}
                                    yAxisDomain={yAxisPriceDomain}
                                    underlyingToken={underlyingTokenInfo}
                                />
                            ) : (
                                <CashViewChart
                                    underlyingToken={underlyingTokenInfo}
                                    chartData={filteredCashData}
                                    latestCashIndicators={state.latestCashIndicators}
                                    timeRange={timeRange}
                                    setTimeRange={setTimeRange}
                                />
                            )}
                            <Divider mt="8" mb="4" width="calc(103%)" mx="-6" />
                            <Box p="2">
                                <Text fontSize="xl" fontWeight="bold" mb="6">
                                    Pool Stats
                                </Text>
                                <Flex direction="row" wrap="wrap" gap={24}>
                                    <InfoText
                                        title="Fund Balance"
                                        subtitle={`${Number(
                                            formatUnits(state.poolInfo.poolInfo.fundBalance, underlyingTokenInfo.decimals),
                                        ).toLocaleString('en-US', {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3,
                                        })} ${underlyingTokenInfo.symbol}`}
                                    />
                                    <InfoText
                                        title="Capital Account"
                                        subtitle={`${Number(
                                            formatUnits(state.poolInfo.poolInfo.capitalAccount, underlyingTokenInfo.decimals),
                                        ).toLocaleString('en-US', {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3,
                                        })} ${underlyingTokenInfo.symbol}`}
                                    />
                                    <InfoText
                                        title="Total Supply"
                                        subtitle={`${Number(
                                            formatUnits(state.poolInfo.poolInfo.tokensOutstanding, fundTokenInfo.decimals),
                                        ).toLocaleString('en-US', {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3,
                                        })} ${fundTokenInfo.symbol}`}
                                    />
                                    <InfoText title="Currency" subtitle={underlyingTokenInfo.symbol} />
                                    <InfoText title="Network" subtitle={NETWORKS[factoringConfig.bullaFactoringToken.chainId].name} />
                                    <InfoText title="Token" subtitle={fundTokenInfo.symbol} />
                                </Flex>
                            </Box>
                        </Box>
                    ) : (
                        <Box mt="6">
                            <WithSkeleton isLoading={true} fixedWidth="100%" height="600px">
                                <Text>Can't see me</Text>
                            </WithSkeleton>
                        </Box>
                    )}

                    {hasDepositPermissions && !holdsNoBFT && (
                        <Flex direction="row" justify="space-between" mt="6" gap="8">
                            <PositionCard
                                currentTokenBalance={currentTokenBalance}
                                currentPrice={state.currentPrice}
                                todayReturn={state.todayReturn}
                                totalReturn={state.totalReturn}
                                underlyingTokenSymbol={underlyingTokenInfo.symbol}
                            />
                            <AverageCostCard
                                averageCost={state.averageCost}
                                currentTokenBalance={currentTokenBalance}
                                tokensAvailableForRedemption={
                                    state.poolInfo.type === 'fetched' ? state.poolInfo.poolInfo.tokensAvailableForRedemption : null
                                }
                                fundTokenInfo={fundTokenInfo}
                                underlyingTokenSymbol={underlyingTokenInfo.symbol}
                            />
                        </Flex>
                    )}

                    <Box my="6">
                        <TabSwitcher
                            tab={activeTableTab}
                            setTab={setActiveTableTab}
                            options={[
                                {
                                    label: 'Deposit & Redemptions',
                                    value: 'DepositRedemptions',
                                },
                                {
                                    label: 'Invoices',
                                    value: 'Invoices',
                                },
                            ]}
                            activeColor="brand.bulla_orange"
                        />
                        <Divider transform="translate(0, -2px)" />
                    </Box>

                    {activeTableTab === 'DepositRedemptions' ? (
                        <>
                            <DepositRedemptionTotals
                                summary={depositAndRedemptionSummary}
                                poolUnderlyingToken={factoringConfig.poolUnderlyingToken}
                            />

                            <Box my="6">
                                <ListViewCard
                                    headers={getHeaders(activeTableTab)}
                                    displayedListItems={depositAndRedemptionTableItems}
                                    emptyMessage="No pool data available"
                                    rowSizeOverride="5em"
                                    alternateRowColor="#F4F6F9"
                                    totalItemCount={depositAndRedemptionTableItems.length}
                                />
                                <PageSelector {...pageSelectorPropsDepositAndRedemption} justifySelf="center" pt="6" />
                            </Box>
                        </>
                    ) : (
                        <Box my="6">
                            <HStack align="flex-start">
                                <InvoiceFactoringTableFilter searchPlaceholder="invoices" filters={filters} setFilters={setFilters} />
                                <Spacer />
                                <ExportMenu handleExport={handleInvoicesExport} />
                            </HStack>
                            <ClearFilterPillsStack
                                pb="4"
                                clearAll={() => setFilters(emptyFactoringInvoiceTableFilterValues)}
                                filters={filters}
                                filtersToPills={factoringInvoiceFilterToPills(setFilters)}
                            />
                            <ListViewCard
                                headers={getHeaders(activeTableTab)}
                                displayedListItems={visibleFactoringInvoicesRows}
                                emptyMessage="No invoices available"
                                rowSizeOverride="5em"
                                showOverflowX
                                alternateRowColor="#F4F6F9"
                            />
                            <PageSelector {...pageSelectorPropsFactoringInvoices} justifySelf="center" pt="6" />
                        </Box>
                    )}
                </MaxWidthWrapper>
            </Flex>
        </PageLayoutProvider>
    );
};
