import dev from './dev.json';
import staging from './staging.json';
import prod from './prod.json';

export type EndpointConfig = {
    authApi: string;
    settingsApi: string;
    externalTransactionsApi: string;
    contactsApi: string;
    namingServiceApi: string;
    pricesApi: string;
    spectralApi: string;
    underwriterApi: string;
    emailApi: string;
    custodianApi: string;
};

export const API_ENDPOINTS: Record<'dev' | 'staging' | 'prod', EndpointConfig> = {
    dev,
    staging,
    prod,
};
