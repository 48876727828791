{
    "settingsApi": "https://apisettings.bulla.network",
    "authApi": "https://apiauth.bulla.network",
    "externalTransactionsApi": "https://apiextx.bulla.network",
    "contactsApi": "https://apicontacts.bulla.network",
    "namingServiceApi": "https://apins.bulla.network",
    "pricesApi": "https://apitoken.bulla.network/prices",
    "spectralApi": "https://api.spectral.finance",
    "underwriterApi": "https://apiuw.bulla.network",
    "emailApi": "https://apiemail.bulla.network",
    "custodianApi": "https://apicust.bulla.network"
}
