import { Box, CloseButton, Divider, Flex, Image, ModalFooter, Stack, TextProps, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'phosphor-react';
import { BullaSwapInfo, ClaimInfo, InstantPaymentInfo } from '../../data-lib/data-model';
import { OffchainInvoiceInfo } from '../../hooks/useOffchainInvoiceFactory';
import { ChakraCompose } from '../../tools/types';
import CopyElement from '../base/copy-element';
import { addressEquality } from '../../data-lib/ethereum';
import { chainIds, NETWORKS } from '../../data-lib/networks';

export const BullaCloseButton = ({ onClose, ...props }: { onClose: VoidFunction } & ChakraCompose) => (
    <CloseButton color="gray.900" _hover={{ bg: 'gray.100' }} onClick={onClose} {...props} />
);

export const getBullaItemLink = (item: ClaimInfo | InstantPaymentInfo | BullaSwapInfo) =>
    `${window.location.origin}/#/?${item.__type === 'Claim' ? 'claim' : item.__type == 'Swap' ? 'swap' : 'instantPayment'}=${
        item.id
    }&network=${item.chainId}`;

export const getOffchainInvoiceLink = (invoice: OffchainInvoiceInfo) =>
    `${window.location.origin}/#/?invoice=${invoice.id}&network=${invoice.chainId}`;

export const ShareItemButton = ({ item }: { item: ClaimInfo | InstantPaymentInfo | OffchainInvoiceInfo | BullaSwapInfo }) => {
    const url = item.__type == 'OffchainInvoiceInfo' ? getOffchainInvoiceLink(item) : getBullaItemLink(item);
    return (
        <CopyElement
            ml="2"
            valueToCopy={url}
            borderRadius="md"
            h="32px"
            w="32px"
            size={'sm'}
            aria-label="share"
            bg="white"
            color="gray.900"
            _hover={{ bg: 'gray.100' }}
        >
            <Link height={'80%'} width={'80%'} />
        </CopyElement>
    );
};

export const CloseModalButton = ({ onClose, ...props }: { onClose: VoidFunction } & ChakraCompose) => (
    <BullaCloseButton onClose={onClose} mt="2" mr="3" zIndex={'modal'} position="absolute" bg="white" right="0" top="0" {...props} />
);

export const ModalFooterWithShadow = ({ children }: { children: React.ReactNode }) => {
    return (
        <ModalFooter p="0" display="block">
            <Box zIndex={1} pos="relative" boxShadow={'0px -1px 4px 1px #efefef'} w="100%" />
            <Flex px="6" py="4">
                {children}
            </Flex>
        </ModalFooter>
    );
};

interface LabelTextProps extends TextProps {
    children: React.ReactNode;
}

export const LabelText: React.FC<LabelTextProps> = ({ children, ...props }) => {
    return (
        <Stack spacing="0.5" {...props}>
            <Text color={'gray.900'} fontSize={'18px'} fontWeight={'600'}>
                {children}
            </Text>
            <Divider />
        </Stack>
    );
};

export const NewInvoiceLabelText: React.FC<LabelTextProps> = ({ children, ...props }) => {
    return (
        <Stack spacing="0.5" {...props}>
            <Text color={'gray.700'} fontSize={'14px'} fontWeight={'600'}>
                {children}
            </Text>
        </Stack>
    );
};

export const isTcsToken = (chainId: number, tokenAddress: string): boolean => {
    return chainId === chainIds.MATIC && addressEquality(tokenAddress, NETWORKS[chainIds.MATIC].supportedTokens.TCS.token.address);
};
