export const ledgerExportTabs = [
    'Summary',
    'TAccounts',
    'CashDisbursements',
    'AccountsReceivables',
    'AccountsPayables',
    'LoanReceivables',
    'LoanPayables',
    'Transfers',
] as const;

export type LedgerExportTab = typeof ledgerExportTabs[number];
