import { useBoolean } from '@chakra-ui/hooks';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, HStack, Stack, Text } from '@chakra-ui/layout';
import {
    Button,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    StackProps,
    Tooltip,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { BullaLineItems, TAG_SEPARATOR } from '../../data-lib/data-model';
import { useBullaBanker } from '../../hooks/useBullaBanker';
import { useInstantPayment } from '../../hooks/useInstantPayment';
import { useTemporaryCategories } from '../../hooks/useTemporaryCategories';
import { useUserSummary } from '../../hooks/useUserSummary';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useAppState } from '../../state/app-state';
import { HighlightedText } from '../base/highlighted-text';
import { getEmojiForDetectedType } from '../detected-type';
import { CATEGORIES_COLUMN_WIDTH } from '../layout/cards';
import { usePageScrollRef } from '../layout/page-layout';
import { DropdownSearch } from '../modals/create-claim-modal/create-claim-inputs';
import { SaveClaimButton } from '../modals/item-details-modal/item-details-components';
import { BullaBlueTextButton, TextButton } from './buttons';

export type SetEditing = { on: VoidFunction; off: VoidFunction };

export const PillCloseIcon: React.FC<{ onClick: VoidFunction }> = ({ onClick }) => (
    <CloseIcon onClick={onClick} _hover={{ cursor: 'pointer' }} h="2" w="2" fontSize={500} />
);

type TagPillProps = { tag: string; onRemove: VoidFunction | 'cant-be-removed' };
export const TagPill = ({ tag, onRemove }: TagPillProps) => {
    const [bg, color] = tag == 'Expense' || tag == 'Rental' ? ['#48BB78', 'white'] : ['#F5F4F3', '#515257'];

    return (
        <Box borderRadius="6px" bg={bg} py="1" px="2">
            <HStack fontSize={'0.9em'} fontWeight="500" color={color}>
                <Tooltip label={tag}>
                    <Text maxW={'150px'} textOverflow="ellipsis" noOfLines={1}>
                        {tag}
                    </Text>
                </Tooltip>
                {onRemove == 'cant-be-removed' ? <></> : <PillCloseIcon onClick={onRemove} />}
            </HStack>
        </Box>
    );
};

export const OneTagPillOrMore = ({ tags }: { tags: string[] }) => {
    const stackRef = React.useRef<HTMLDivElement>(null);
    const tagsDisplayed = tags.slice(0, 1);
    const otherTags = tags.slice(1);
    const [scrollTop, setScrollTop] = React.useState(0);

    const scrollRef = usePageScrollRef();
    const scrollRefCurrent = scrollRef?.current;

    const onScroll = React.useCallback((e: any) => {
        setScrollTop(e.target.scrollTop ?? 0);
    }, []);

    React.useEffect(() => {
        scrollRefCurrent?.addEventListener('scroll', onScroll);
        return () => scrollRefCurrent?.removeEventListener('scroll', onScroll);
    }, [scrollRefCurrent ?? 'none']);

    const popoverContent = () => {
        const rect = stackRef?.current?.getBoundingClientRect();

        return (
            <PopoverContent
                mt={`30px`}
                w={CATEGORIES_COLUMN_WIDTH}
                position="absolute"
                top={`${rect?.top ?? 0}px`}
                left={`${rect?.left ?? 0}px`}
            >
                <PopoverBody>
                    <Wrap>
                        {otherTags.map(tag => (
                            <WrapItem key={tag}>
                                <TagPill tag={tag} onRemove={'cant-be-removed'} />
                            </WrapItem>
                        ))}
                    </Wrap>
                </PopoverBody>
            </PopoverContent>
        );
    };

    return (
        <Wrap ref={stackRef}>
            {tagsDisplayed.map(tag => (
                <WrapItem key={tag}>
                    <TagPill tag={getEmojiForDetectedType(tag)} onRemove="cant-be-removed" />
                </WrapItem>
            ))}

            {otherTags.length !== 0 && (
                <Popover placement="bottom">
                    <PopoverTrigger>
                        <TextButton color={'#3376C2'}>{`+ ${otherTags.length} more`}</TextButton>
                    </PopoverTrigger>
                    <Portal>{popoverContent()}</Portal>
                </Popover>
            )}
        </Wrap>
    );
};

type AccountTagEditState = 'ready' | 'viewing only';
type AccountTagViewerProps = {
    tags: string[];
    removeTag: (tag: string) => void;
    addTag: (tag: string) => void;
    creatingExpense: boolean;
    setEditing: SetEditing;
    isDisabled: boolean;
    fieldName: string;
    editState: AccountTagEditState;
    dropdownPortalRef?: React.RefObject<HTMLDivElement>;
    maxTagsDisplayed?: number;
    placeholder?: string;
};

export const AccountTagViewer = ({
    tags,
    addTag,
    creatingExpense,
    removeTag,
    setEditing,
    isDisabled,
    fieldName,
    editState,
    dropdownPortalRef,
    maxTagsDisplayed,
    placeholder = 'Find or create new category',
    ...props
}: AccountTagViewerProps & Partial<StackProps>) => {
    const actingWallet = useActingWalletAddress();
    const stackRef = React.useRef<HTMLDivElement>(null);
    const scrollRef = usePageScrollRef(); // popover needs to re-render on every scroll of main page
    const [triggerScroll, setTriggerScroll] = React.useState(0);
    const { tempCategories, setTempCategories } = useTemporaryCategories();
    const { accountTagSummary } = useUserSummary();
    const currentTags = Object.keys(accountTagSummary);

    const allTags = React.useMemo(
        () => [...new Set(['Expense', 'Rental', ...currentTags, ...(tempCategories ?? [])])],
        [tempCategories.length, currentTags.length],
    );
    const tagsDisplayed = tags.slice(0, maxTagsDisplayed);
    const otherTags = !!maxTagsDisplayed ? tags.slice(maxTagsDisplayed) : [];

    const onChange = (_newStr: string) => {
        const newStr = _newStr.trim();
        if (newStr === '') {
            setEditing.off();
        } else {
            setEditing.on();
        }
    };

    const onSubmit = (_newTag: string, setInputValue: (str: string) => void) => {
        const newTag = _newTag.trim();
        if (newTag == '' || tags.map(x => x.toLowerCase()).includes(newTag.toLowerCase())) return;
        setEditing.off();
        addTag(newTag);
        setInputValue('');
        setTempCategories(tags => [...tags, newTag]);
    };

    const onScroll = _.debounce(() => {
        setTriggerScroll(prev => prev + 1);
    }, 200);

    React.useEffect(() => {
        scrollRef?.current?.addEventListener('scroll', onScroll);
        return () => scrollRef?.current?.removeEventListener('scroll', onScroll);
    }, [scrollRef?.current ?? 'none']);

    const popoverContent = () => {
        const rect = stackRef?.current?.getBoundingClientRect();

        return (
            <PopoverContent
                mt={`${(rect?.height ?? 0) + 6}px`}
                w={stackRef?.current?.offsetWidth ?? 'initial'}
                position="absolute"
                top={`${rect?.top ?? 0}px`}
                left={`${rect?.left ?? 0}px`}
            >
                <PopoverBody>
                    <Wrap>
                        {otherTags.map(tag => (
                            <WrapItem key={tag}>
                                <TagPill
                                    tag={tag}
                                    onRemove={
                                        (creatingExpense && tag.toLowerCase() === 'expense') || isDisabled || editState === 'viewing only'
                                            ? 'cant-be-removed'
                                            : () => removeTag(tag)
                                    }
                                />
                            </WrapItem>
                        ))}
                    </Wrap>
                </PopoverBody>
            </PopoverContent>
        );
    };

    return (
        <Stack ref={stackRef} fontWeight="500" fontSize={'14px'} {...props}>
            {editState == 'ready' && (
                <DropdownSearch
                    allItems={allTags}
                    itemToString={x => x}
                    setValue={onChange}
                    itemToSearchableStrings={x => [x]}
                    onItemClick={(item, setInputValue) => {
                        onSubmit(item, setInputValue);
                    }}
                    itemToDisplayValue={x => x}
                    emptyItem={''}
                    fieldName={fieldName}
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    onSubmit={onSubmit}
                    dropdownPortalRef={dropdownPortalRef}
                    defaultAction={{
                        stringToElement: input => (
                            <span>
                                {'+ Create new category named '}
                                <HighlightedText search={input}>{input}</HighlightedText>
                            </span>
                        ),
                        stringToItem: x => x,
                    }}
                    showSubmitButton
                    tagsDisplayed={tagsDisplayed}
                />
            )}
            <Wrap>
                {tagsDisplayed.map(tag => (
                    <WrapItem key={tag}>
                        <TagPill
                            tag={getEmojiForDetectedType(tag)}
                            onRemove={
                                (creatingExpense && tag.toLowerCase() === 'expense') || isDisabled || editState === 'viewing only'
                                    ? 'cant-be-removed'
                                    : () => removeTag(tag)
                            }
                        />
                    </WrapItem>
                ))}

                {otherTags.length !== 0 && (
                    <Popover placement="bottom">
                        <PopoverTrigger>
                            <TextButton color={'#3376C2'}>{`+ ${otherTags.length} more`}</TextButton>
                        </PopoverTrigger>
                        <Portal>{popoverContent()}</Portal>
                    </Popover>
                )}
            </Wrap>
        </Stack>
    );
};

type ViewTagOnItemProps = {
    item: BullaLineItems;
    isDisabled?: boolean;
    isLoading?: boolean;
    saveTagsOverride?: (tags: string[]) => Promise<void>;
    dropdownPortalRef?: React.RefObject<HTMLDivElement>;
};

export const ViewTagOnItem = ({ item, isDisabled, saveTagsOverride, isLoading: _isLoading, dropdownPortalRef }: ViewTagOnItemProps) => {
    const { readyToTransact } = useAppState();
    const { accountTagSummary } = useUserSummary();
    const [claimTagUpdatePending, { addAccountTag }] = useBullaBanker();
    const [ipTagUpdatePending, { updateTag }] = useInstantPayment();
    const [editedTags, _setEditedTags] = React.useState<string[]>(item.tags);
    const [editTag, setEditTag] = useBoolean();
    const [isTyping, setIsTyping] = useBoolean(false);
    const noUserTags = Object.values(accountTagSummary).length === 0;
    const isLoading = ipTagUpdatePending || claimTagUpdatePending || _isLoading;
    const noTags = item.tags.length == 0;

    const initialTagString = React.useMemo(() => item.tags.join(TAG_SEPARATOR), []);
    const newTagString = editedTags.join(TAG_SEPARATOR);

    const addTag = (newTag: string) => {
        if (newTag !== '' && !editedTags.map(x => x.toLowerCase()).includes(newTag.toLowerCase()))
            _setEditedTags(prevTags => [...prevTags, newTag]);
    };

    const removeTag = (newTag: string) =>
        _setEditedTags(prevTags => prevTags.filter(prevTag => prevTag.toLowerCase() !== newTag.toLowerCase()));

    const addTagToItem = async (tag: string) => {
        const success = item.__type == 'Claim' ? await addAccountTag(item, tag.trim()) : await updateTag(item.id, tag.trim());
        if (!success) {
            _setEditedTags(() => item.tags);
        }
        if (success) {
            setEditTag.off();
        }
    };

    return (
        <HStack w="fit-content" alignItems={editTag ? 'flex-start' : 'center'}>
            {!editTag && noTags && (
                <Text color={'brand.bulla_grey'} fontWeight="500">
                    None
                </Text>
            )}
            <AccountTagViewer
                tags={editedTags}
                removeTag={removeTag}
                addTag={addTag}
                creatingExpense={false}
                setEditing={setIsTyping}
                isDisabled={!readyToTransact || !!isLoading}
                fieldName={'tags'}
                editState={editTag ? 'ready' : 'viewing only'}
                w={editTag ? '250px' : 'fit-content'}
                dropdownPortalRef={dropdownPortalRef}
            />
            {editTag ? (
                <SaveClaimButton
                    onClick={async () =>
                        saveTagsOverride
                            ? saveTagsOverride(editedTags).then(setEditTag.off)
                            : addTagToItem(newTagString).then(setEditTag.off)
                    }
                    px="8"
                    size="md"
                    isLoading={isLoading}
                    isDisabled={!readyToTransact || initialTagString === newTagString || isTyping}
                />
            ) : (
                <BullaBlueTextButton
                    onClick={setEditTag.on}
                    isLoading={isLoading}
                    isDisabled={isLoading || !!isDisabled}
                    textDecor="none"
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight={'24px'}
                >
                    {noUserTags || noTags ? 'Create a category' : 'Edit'}
                </BullaBlueTextButton>
            )}
        </HStack>
    );
};
