{
    "settingsApi": "https://devapisettings.bulla.network",
    "authApi": "https://devapiauth.bulla.network",
    "externalTransactionsApi": "https://devapiextx.bulla.network",
    "contactsApi": "https://devapicontacts.bulla.network",
    "namingServiceApi": "https://devapins.bulla.network",
    "pricesApi": "https://apitoken.bulla.network/prices",
    "spectralApi": "https://api.spectral.finance",
    "underwriterApi": "https://devapiuw.bulla.network",
    "emailApi": "https://devapiemail.bulla.network",
    "custodianApi": "https://devapicust.bulla.network"
}
