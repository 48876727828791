import { BigNumber, constants, Contract } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { AttachmentLinkGenerator } from '../../hooks/useCompanyDetailsRepo';
import { isAttachmentReady, resolveBullaAttachmentToCID } from '../../tools/ipfs';
import { InstantPaymentInfo, TAG_SEPARATOR } from '../data-model';
import { EthAddress, toChecksumAddress } from '../ethereum';
import { defaultDate } from '../helpers';
import { ChainId, TokenInfo } from '../networks';
import { IBullaInstantPay } from './contract-interfaces';
import { CreatePaymentFields } from '../../components/modals/create-claim-modal/create-payment-modal';
import { CreateClaimFields } from '../../components/modals/create-claim-modal/create-claim-modal';

export const isPaymentFields = (fields: CreatePaymentFields | CreateClaimFields): fields is CreatePaymentFields => {
    return 'paymentAmount' in fields;
};

export const mapPaymentInputsToPaymentInfo = async (
    userAddress: EthAddress,
    tokenInfo: TokenInfo,
    fields: CreatePaymentFields | CreateClaimFields,
    chainId: ChainId,
    attachmentLinkGenerator: AttachmentLinkGenerator,
): Promise<InstantPaymentInfo> => {
    const amount = isPaymentFields(fields) ? fields.paymentAmount : fields.claimAmount;
    const { description, recipient, tags: tag, token, attachment } = fields;

    const ipfsHash = isAttachmentReady(attachment)
        ? await resolveBullaAttachmentToCID({
              attachment,
              actingWallet: userAddress,
              amount: amount,
              description,
              recipient,
              tokenSymbol: token.symbol,
              type: 'Payment',
              attachmentLinkGenerator,
          })
        : '';

    return {
        __type: 'InstantPayment',
        id: '',
        created: defaultDate,
        creditor: recipient,
        debtor: userAddress,
        description,
        ipfsHash,
        logs: [],
        paidAmount: parseUnits(amount.toString(), token.decimals),
        tags: tag,
        tokenInfo,
        txHash: '',
        chainId,
        notes: '',
    };
};

type InstantPaymentParams = {
    contract: Contract;
    tag: string;
};

export const createInstantPayment = async ({
    contract,
    to,
    amount,
    tokenAddress,
    description,
    tag,
    ipfsHash,
}: InstantPaymentParams & {
    to: EthAddress;
    amount: BigNumber;
    tokenAddress: EthAddress;
    description: string;
    ipfsHash: string;
}) =>
    await contract.instantPayment(to, amount, tokenAddress, description, tag, ipfsHash, {
        value: tokenAddress === constants.AddressZero ? amount : 0,
    });

export const updateInstantPaymentTag = async ({
    contract,
    tag,
    txAndLogIndexHash,
}: InstantPaymentParams & {
    txAndLogIndexHash: string;
}) => await contract.updateBullaTag(txAndLogIndexHash, tag);

export const getCreateInstantPaymentTransaction = (
    _instantPaymentAddress: EthAddress,
    _to: EthAddress,
    amount: BigNumber,
    _tokenAddress: EthAddress,
    description: string,
    tags: string[],
    ipfsHash: string,
) => {
    const instantPaymentAddress = toChecksumAddress(_instantPaymentAddress);
    const to = toChecksumAddress(_to);
    const tokenAddress = toChecksumAddress(_tokenAddress);
    const value = tokenAddress === constants.AddressZero ? amount.toString() : '0';

    return {
        to: instantPaymentAddress,
        value,
        operation: 0,
        data: IBullaInstantPay.encodeFunctionData('instantPayment', [
            to,
            amount,
            tokenAddress,
            description.trim(),
            tags
                .map(x => x.trim())
                .filter(x => x !== '')
                .join(TAG_SEPARATOR),
            ipfsHash.trim(),
        ]),
    };
};

export const getUpdateInstantPaymentTagTransaction = (_instantPaymentAddress: EthAddress, id: string, newTag: string) => {
    const instantPaymentAddress = toChecksumAddress(_instantPaymentAddress);

    return {
        to: instantPaymentAddress,
        value: '0',
        operation: 0,
        data: IBullaInstantPay.encodeFunctionData('updateBullaTag', [id, newTag]),
    };
};
