import {
    Alert,
    AlertIcon,
    Box,
    Checkbox,
    Collapse,
    Flex,
    Grid,
    GridItem,
    HStack,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Spacer,
    Stack,
    Text,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react';
import { isAddress as validEthereumAddress } from '@ethersproject/address';
import { constants } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { Field, FieldProps, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ClaimType } from '../../../data-lib/data-model';
import { FinancingTerms, getFinancingSummaryLabel } from '../../../data-lib/dto/bulla-finance-dto';
import { getInstantPaymentEvents } from '../../../data-lib/dto/event-filters';
import { getUniqueEventId } from '../../../data-lib/dto/events-dto';
import { addressEquality, EthAddress } from '../../../data-lib/ethereum';
import { ChainId, NETWORKS, SUPPORTED_NETWORKS, TokenDto } from '../../../data-lib/networks';
import { useBullaBanker } from '../../../hooks/useBullaBanker';
import { useCanChangeNetwork } from '../../../hooks/useCanChangeNetwork';
import { useOpenClaimDetails, useOpenInstantPaymentDetails } from '../../../hooks/useClaimDetailDisclosure';
import { useCompanyDetailsRepo } from '../../../hooks/useCompanyDetailsRepo';
import { useSendClaimEmail } from '../../../hooks/useEmail';
import { CreateInvoiceRequestParams, getBackendTxIdForItem, useExternalTransactionsApi } from '../../../hooks/useExternalTransactionsApi';
import { useInstantPayment } from '../../../hooks/useInstantPayment';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useMembership } from '../../../hooks/useMembership';
import { useCreateOffchainInvoice } from '../../../hooks/useOffchainInvoices';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { useAppState } from '../../../state/app-state';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { openCreateClaimParams, useUIState } from '../../../state/ui-state';
import { apply, getBase64 } from '../../../tools/common';
import { vendorFinancingEnabled } from '../../../tools/featureFlags';
import { generateAttachment } from '../../../tools/ipfs';
import { clearSessionStorage, STORAGE_KEYS } from '../../../tools/storage';
import { ChakraCompose } from '../../../tools/types';
import { AlertInfo } from '../../base/alert';
import { useBullaToast } from '../../base/toast';
import { InstantPaymentButton } from '../../display/claim-action-buttons';
import { BullaBlueTextButton, OrangeButton, SecondaryButton, SwitchNetworksButton } from '../../inputs/buttons';
import { CloseModalButton, LabelText, ModalFooterWithShadow } from '../common';
import { EditFinancingTermsModal } from '../financing-terms-modal/financing-terms-modal';
import {
    AccountTagField,
    AttachmentField,
    ChainSelector,
    ClaimAmountField,
    claimAmountValidationSchema,
    ClaimDescriptionField,
    ClaimNotesField,
    disabledInputProps,
    DueByField,
    EmailField,
    emptyFields,
    RecipientField,
} from './create-claim-inputs';
import { PastPaymentsTable } from './create-link-handler';

export type BullaFileObject = { file: File | 'not-uploaded' };

export type UploadedFile = { ipfsHash: string; fileName: string; fileType: string };

export type BullaItemAttachment = BullaFileObject | UploadedFile | 'generate';

export const isUploadedFile = (attachment: BullaItemAttachment | undefined): attachment is UploadedFile =>
    !!(attachment as unknown as UploadedFile)?.fileName;

export type CreateClaimFields = {
    claimAmount: string;
    token: TokenDto;
    instantPayment: boolean;
    recipient: string;
    description: string;
    dueBy: Date;
    tags: string[];
    attachment?: BullaItemAttachment | undefined;
    notes?: string;
    emailAddress?: string;
    emailMessage?: string;
    emailCC?: string;
    customCreditorAndDebtor?: { creditor: string; debtor: string };
};

type CreateClaimModalProps = ChakraCompose & {
    triggerElement?: (onOpen: () => void) => React.ReactNode;
    claimType: ClaimType;
    alertMessage?: string;
    header?: React.ReactNode;
    defaults?: Partial<typeof emptyFields> & { network?: ChainId; token?: TokenDto };
    fromLink?: boolean;
};

export const NewAddressAlert = ({ newAddress }: { newAddress: string }) => {
    const userAddress = useActingWalletAddress();
    const { userClaims, instantPayments } = useGlobalUserData('include-originating-claims');
    const hasPaidUser = React.useMemo(() => {
        return [...userClaims, ...instantPayments].reduce((hasPaid, claim) => {
            const { debtor, creditor } = claim;
            const relatedToClaim =
                addressEquality(newAddress, debtor) || addressEquality(newAddress, creditor) || addressEquality(newAddress, userAddress)
                    ? true
                    : false;
            return hasPaid || relatedToClaim;
        }, false);
    }, [userClaims, instantPayments]);

    return (
        <>
            {!hasPaidUser && (
                <Alert status="info" mb="4">
                    <AlertIcon />
                    You have not interacted with this wallet before. Remember to verify all wallet addresses.
                </Alert>
            )}
        </>
    );
};

export const claimInfoSchemaFields: any = {
    description: Yup.string(),
    emailAddress: Yup.string().email('Invalid email address'),
    emailMessage: Yup.string().optional(),
    emailCC: Yup.string().email('Invalid email address').optional(),
};

export const claimInfoSchema = Yup.object().shape({ ...claimInfoSchemaFields, description: claimInfoSchemaFields.description.optional() });

export const recipientErrorSchema = (senderAddress: string) =>
    Yup.string().test(
        'is-other-user',
        'You cannot create a claim with your address as the recipient',
        value => value?.toLowerCase() !== senderAddress.toLowerCase(),
    );

export const errorMessageSchema = (senderAddress: EthAddress, isNftInvoice?: boolean, descriptionOptional?: boolean) =>
    Yup.object().shape({
        recipient: isNftInvoice
            ? recipientErrorSchema(senderAddress)
                  .required('Required')
                  .test('is-valid-address', 'Invalid wallet address', value => validEthereumAddress(value || ''))
            : recipientErrorSchema(senderAddress),
        claimAmount: claimAmountValidationSchema,
        dueBy: Yup.date().typeError('Invalid date'),
        ...claimInfoSchemaFields,
        description: !!descriptionOptional ? claimInfoSchemaFields.description : claimInfoSchemaFields.description.required('Required'),
        emailCC: ccEmailErrorSchema(),
        emailAddress: !!isNftInvoice
            ? claimInfoSchemaFields.emailAddress.optional()
            : claimInfoSchemaFields.emailAddress.required('Required'),
    });

export const ccEmailErrorSchema = () =>
    Yup.string()
        .email('Invalid email address')
        .optional()
        .when('emailAddress', {
            is: (emailAddress: string) => !!emailAddress,
            then: Yup.string().notOneOf([Yup.ref('emailAddress')], 'Alert - Invoice and confirm will be sent to same email'),
        });

export enum InvoiceType {
    NFT = 'nft',
    OFFCHAIN = 'offchain',
}

export const CreateClaimModal = ({ triggerElement, header, defaults, alertMessage, claimType, fromLink }: CreateClaimModalProps) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const modalBodyRef = useRef<HTMLDivElement>(null);
    const { connectedNetwork, connectedNetworkConfig } = useWeb3();
    const { search } = useLocation();
    const navigate = useNavigate();
    const { getAttachmentGenerationLink } = useCompanyDetailsRepo();
    const canChangeNetwork = useCanChangeNetwork();
    const { transactionPending } = useUIState();
    const { readyToTransact } = useAppState();
    const senderAddress = useActingWalletAddress();
    const sendClaimEmail = useSendClaimEmail();
    const { getTokenByChainIdAndAddress, erc20sByChainId } = useTokenRepo();
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: !triggerElement });
    const [triggerScroll, setTriggerScroll] = React.useState(0);
    const openClaim = useOpenClaimDetails();
    const openInstantPayment = useOpenInstantPaymentDetails();
    const [creatingClaim, { createClaim, createClaimWithSafeAndFinancing }] = useBullaBanker();
    const [creatingInstantPayment, { createInstantPayment }] = useInstantPayment();
    const isMobile = useIsMobile();
    const { changeNetwork } = useOnboard();
    const [showFinancingOptions, setShowFinancingOptions] = React.useState(false);
    const [sendingOffchainClaim, setSendingOffchainClaim] = React.useState(false);
    const isLoading = creatingClaim || creatingInstantPayment || sendingOffchainClaim;
    const { saveExternalTransactions } = useExternalTransactionsApi();
    const { safeInfo } = useGnosisSafe();
    const triggerToast = useBullaToast();
    const membership = useMembership();
    const createOffchainInvoice = useCreateOffchainInvoice();

    const initialDefaultTag = defaults?.tags ?? [];
    const isExpense = initialDefaultTag.includes('Expense');
    const isInitiallyInstantPayment = claimType === 'Payment';
    const initialERC20 = erc20sByChainId[connectedNetwork][0].token;
    const initialToken = isInitiallyInstantPayment
        ? (defaults?.network ? NETWORKS[defaults.network] : connectedNetworkConfig).nativeCurrency.tokenInfo.token
        : initialERC20;
    const [financingTerms, setFinancingTerms] = React.useState<FinancingTerms | 'none'>('none');
    const { paidBullaItemsWithPayments } = useGlobalUserData('exclude-originating-claims');
    const pastPaymentsToRecipient =
        defaults?.recipient && paidBullaItemsWithPayments.filter(p => addressEquality(p.creditor, defaults.recipient!)).slice(0, 2);

    const onScroll = _.debounce(() => {
        setTriggerScroll(prev => prev + 1);
    }, 100);

    React.useEffect(() => {
        modalBodyRef?.current?.addEventListener('scroll', onScroll);
        return () => modalBodyRef?.current?.removeEventListener('scroll', onScroll);
    }, [modalBodyRef?.current ?? 'none']);

    const handleOffchainSend = async (
        formikInputs: CreateClaimFields,
        createOffchainInvoice: (params: CreateInvoiceRequestParams) => Promise<boolean>,
    ) => {
        setSendingOffchainClaim(true);
        const values = { ...formikInputs };

        const attachment = values.attachment;
        const file = await (!attachment
            ? Promise.resolve(undefined)
            : attachment == 'generate'
            ? (() => {
                  const url = getAttachmentGenerationLink(
                      claimType,
                      values.recipient,
                      values.claimAmount,
                      values.token.symbol,
                      values.description,
                  );
                  const filename = `${claimType}-attachment.pdf`;
                  return generateAttachment(url, filename);
              })()
            : Promise.resolve('file' in attachment && attachment.file !== 'not-uploaded' ? attachment.file : undefined));

        const fileDto = file && { name: file.name, base64: await getBase64(file) };

        const offchainResult = await createOffchainInvoice({
            dueDate: Math.floor(values.dueBy.getTime() / 1000),
            email: values.emailAddress!,
            confirmationEmail: values.emailCC,
            description: values.description == '' ? undefined : values.description,
            file: fileDto,
            tokenStrategy: {
                kind: 'fixed-token-and-chain',
                tokenSymbol: values.token.symbol,
                tokenAddress: values.token.address,
                tokenDecimals: values.token.decimals,
                chainId: connectedNetwork,
                amountStrategy: { kind: 'token-amount', amount: parseUnits(values.claimAmount, values.token.decimals).toString() },
            },
            notes: values.notes,
            categories: values.tags,
        });

        if (offchainResult) {
            closeModal();
            triggerToast({ title: 'Invoice sent successfully.', position: 'top', variant: 'top-accent' });
            navigate('/?tab=Receivables');
        } else {
            triggerToast({ title: 'Failed to send invoice.', position: 'top', status: 'error' });
        }
        setSendingOffchainClaim(false);
    };

    const handleSend = async (formikInputs: CreateClaimFields) => {
        const values = {
            ...formikInputs,
            tags: [...formikInputs.tags, ...(isExpense && !formikInputs.tags.includes('Expense') ? ['Expense'] : [])].filter(x => x !== ''),
        };
        const financeTerms = financingTerms !== 'none' ? financingTerms : undefined;
        const result = values.instantPayment
            ? await createInstantPayment(values)
            : safeInfo && financeTerms
            ? await createClaimWithSafeAndFinancing(claimType, values, financeTerms)
            : await createClaim(claimType, values, financeTerms);

        const sendNotes = async (id: string, notes: string) => {
            await saveExternalTransactions({
                [getBackendTxIdForItem(id, connectedNetwork)]: {
                    id,
                    notes: notes,
                    chainId: connectedNetwork,
                },
            });
        };
        if (result) {
            try {
                const claimEvents = sendClaimEmail(claimType, [values], result);
                if (!values.instantPayment) {
                    const claimId = claimEvents.length > 0 && claimEvents[0].tokenId;
                    if (claimId && formikInputs.notes && formikInputs.notes !== '') {
                        await sendNotes(claimId, formikInputs.notes);
                    }
                    return {
                        claimId,
                        success: true,
                    };
                } else {
                    const paymentEvents = getInstantPaymentEvents(result.events);
                    const id =
                        paymentEvents.length > 0
                            ? getUniqueEventId({ txHash: paymentEvents[0].txHash, logIndex: paymentEvents[0].logIndex })
                            : undefined;
                    if (id && formikInputs.notes && formikInputs.notes !== '') {
                        await sendNotes(id, formikInputs.notes);
                    }
                    return { id, success: true };
                }
            } catch (e) {
                console.warn('error sending claim email and getting events. Cant save notes', e);
                return { success: true };
            }
        }
        return { success: false };
    };

    const closeModal = () => {
        if (isOpen) onClose();
        const params = new URLSearchParams(search);
        openCreateClaimParams.forEach(param => params.delete(param));
        navigate({ search: `?${params.toString()}` }, { replace: true });
        clearSessionStorage(STORAGE_KEYS.capturedParams);
        setFinancingTerms('none');
    };

    const handleComplete = (resetForm: () => void) => {
        closeModal();
        resetForm();
    };

    return (
        <>
            {triggerElement ? triggerElement(onOpen) : null}
            <Modal
                isCentered={!isMobile}
                isOpen={isOpen}
                onClose={closeModal}
                motionPreset="slideInBottom"
                closeOnOverlayClick={false}
                closeOnEsc={false}
                size={isMobile ? 'full' : '2xl'}
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <Formik
                    initialValues={{
                        ...emptyFields,
                        instantPayment: isInitiallyInstantPayment,
                        ...defaults,
                        token: defaults?.token ?? initialToken,
                        claimAmount: defaults?.claimAmount ?? '',
                        tags: initialDefaultTag,
                    }}
                    validate={values => {
                        try {
                            const descriptionRequired = claimType == 'Invoice' || !values.instantPayment;
                            validateYupSchema(
                                values,
                                errorMessageSchema(senderAddress, values.invoiceType == InvoiceType.NFT, !descriptionRequired),
                                true,
                            );
                            return {};
                        } catch (err) {
                            return yupToFormErrors(err);
                        }
                    }}
                    validateOnMount={!!defaults}
                    validateOnBlur
                    onSubmit={async (fieldValues, { resetForm }) => {
                        if (fieldValues.invoiceType == InvoiceType.NFT) {
                            const { success, claimId, id } = await handleSend({
                                ...fieldValues,
                                claimAmount: fieldValues.claimAmount,
                            });
                            if (success && isOpen) {
                                handleComplete(resetForm);
                                if (claimType === 'Payment') {
                                    if (claimId) openClaim(claimId, connectedNetwork);
                                    else if (id) openInstantPayment(id, connectedNetwork);
                                } else {
                                    navigate('/?tab=Receivables');
                                }
                            }
                        } else if (createOffchainInvoice !== 'loading') {
                            handleOffchainSend(fieldValues, createOffchainInvoice);
                        }
                    }}
                >
                    {({ errors, touched, setFieldValue, isValid, values, setFieldTouched, dirty, setStatus, status }) => {
                        const isNftInvoice = values.invoiceType == InvoiceType.NFT;
                        const includeNativeToken = !isNftInvoice || values.instantPayment;
                        const isDisabled =
                            !(dirty || !!defaults) || !isValid || isLoading || !readyToTransact || createOffchainInvoice == 'loading';
                        const financeButtonDisabled =
                            transactionPending ||
                            values.claimAmount === '0' ||
                            !!errors.claimAmount ||
                            !connectedNetworkConfig.bullaFinanceAddress;

                        const modalModalWidth = useBreakpointValue({ base: 'md', md: '60%' }, { ssr: false });
                        const modalColumns = useBreakpointValue({ base: '1fr', md: '1fr 1fr' }, { ssr: false });

                        useEffect(() => {
                            if (!includeNativeToken && values.token.isNative) setFieldValue('token', initialERC20);
                        }, [includeNativeToken]);

                        const descriptionRequired = claimType == 'Invoice' || !values.instantPayment;
                        const blockExplorer = values.network ? NETWORKS[values.network].blockExplorer : undefined;
                        return (
                            <>
                                <Form placeholder={''}>
                                    <ModalContent py="4" px="2" bg={'white'} ref={modalContentRef} maxW={modalModalWidth}>
                                        <ModalHeader display="flex">
                                            {header ?? (
                                                <Text color="heading" fontWeight={'700'} fontSize="18px" noOfLines={1} alignSelf="center">
                                                    {isExpense
                                                        ? claimType == 'Invoice'
                                                            ? 'Request Reimbursement'
                                                            : 'Pay An Expense'
                                                        : `Create New ${claimType}`}
                                                </Text>
                                            )}
                                        </ModalHeader>
                                        <CloseModalButton onClose={closeModal} />
                                        <ModalBody ref={modalBodyRef} py="0">
                                            <Grid templateColumns={modalColumns} columnGap="6" rowGap="3" mb={'6'}>
                                                <GridItem key={'left'}>
                                                    <Stack spacing="3">
                                                        {isNftInvoice && !values.instantPayment && (
                                                            <AlertInfo
                                                                message={
                                                                    <HStack>
                                                                        <Text>
                                                                            {alertMessage ??
                                                                                'The Bulla Protocol mints NFTs to represent invoices and scheduled payments.'}
                                                                        </Text>
                                                                        <Link
                                                                            href="https://bulla-network.gitbook.io/bulla-network/welcome-to-bullanetwork/bulla-protocol"
                                                                            target="_blank"
                                                                            fontWeight="600"
                                                                            display={'inline'}
                                                                            whiteSpace="nowrap"
                                                                            color="brand.bulla_blue"
                                                                        >
                                                                            Learn More
                                                                        </Link>
                                                                    </HStack>
                                                                }
                                                            />
                                                        )}
                                                        {!safeInfo && (
                                                            <Box>
                                                                <LabelText pb="3">Chain</LabelText>
                                                                <ChainSelector
                                                                    chainId={defaults?.network ?? connectedNetwork}
                                                                    isDisabled={isLoading || !canChangeNetwork || !!defaults?.network}
                                                                    selectableChains={SUPPORTED_NETWORKS}
                                                                    w="100%"
                                                                    textAlign={'left'}
                                                                    onChainSelected={changeNetwork}
                                                                    {...disabledInputProps}
                                                                />
                                                            </Box>
                                                        )}
                                                        <Collapse in={!values.instantPayment}>
                                                            {claimType == 'Invoice' && (
                                                                <Stack spacing="0.5">
                                                                    <LabelText pb="3">Transaction Type</LabelText>
                                                                    <RadioGroup
                                                                        onChange={value =>
                                                                            setFieldValue('invoiceType', value as InvoiceType)
                                                                        }
                                                                        value={values.invoiceType}
                                                                        defaultValue={InvoiceType.NFT}
                                                                    >
                                                                        <Stack direction="column" ml="1" mt="2" spacing={1}>
                                                                            <Radio value={InvoiceType.NFT}>Onchain</Radio>
                                                                            <Radio value={InvoiceType.OFFCHAIN}>Offchain</Radio>
                                                                        </Stack>
                                                                    </RadioGroup>
                                                                </Stack>
                                                            )}
                                                        </Collapse>
                                                        <LabelText>Recipient Info</LabelText>
                                                        <Collapse
                                                            in={
                                                                (!!touched.recipient && !!values.recipient && !errors.recipient) ||
                                                                (fromLink && !!values.recipient)
                                                            }
                                                            unmountOnExit
                                                        >
                                                            <NewAddressAlert newAddress={values.recipient} />
                                                        </Collapse>
                                                        {isNftInvoice && (
                                                            <HStack>
                                                                <Field name="recipient">
                                                                    {({ field }: FieldProps) => (
                                                                        <RecipientField
                                                                            {...{
                                                                                field,
                                                                                initialValue: defaults?.recipient,
                                                                                isDisabled: transactionPending || !!defaults?.recipient,
                                                                                error: errors.recipient,
                                                                                touched: touched.recipient,
                                                                                setRecipient: apply(setFieldValue, field.name),
                                                                                setEmailAddress: apply(setFieldValue, 'emailAddress'),
                                                                                label: 'Recipient Address',
                                                                                dropdownModalRef: modalContentRef,
                                                                                fromLink,
                                                                                chainId: defaults?.network,
                                                                                required: isNftInvoice,
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </HStack>
                                                        )}
                                                        <Box>
                                                            <Field name="claimAmount">
                                                                {({ field }: FieldProps) => (
                                                                    <ClaimAmountField
                                                                        {...{
                                                                            claimType,
                                                                            networkOverride: defaults?.network,
                                                                            field,
                                                                            isDisabled: transactionPending,
                                                                            includeNativeToken,
                                                                            error: errors.claimAmount,
                                                                            touched: touched.claimAmount,
                                                                            setAmount: apply(setFieldValue, 'claimAmount'),
                                                                            setToken: apply(setFieldValue, 'token'),
                                                                            amount: values.claimAmount,
                                                                            token: values.token,
                                                                            setFieldTouched,
                                                                            lockToken: !!defaults?.token,
                                                                            lockAmount: !!defaults?.claimAmount,
                                                                            label: 'Token',
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {vendorFinancingEnabled &&
                                                                isNftInvoice &&
                                                                claimType == 'Invoice' &&
                                                                !!connectedNetworkConfig.bullaFinanceAddress && (
                                                                    <Box w="100%">
                                                                        {financingTerms === 'none' ? (
                                                                            <BullaBlueTextButton
                                                                                alignContent={'start'}
                                                                                onClick={() => setShowFinancingOptions(true)}
                                                                                isDisabled={financeButtonDisabled}
                                                                                mt="2"
                                                                            >
                                                                                Financing Options
                                                                            </BullaBlueTextButton>
                                                                        ) : (
                                                                            <HStack>
                                                                                <Text color={'rgba(0, 0, 0, 0.64)'}>
                                                                                    {getFinancingSummaryLabel(values.token, financingTerms)}
                                                                                </Text>
                                                                                <BullaBlueTextButton
                                                                                    onClick={() => setShowFinancingOptions(true)}
                                                                                    isDisabled={financeButtonDisabled}
                                                                                >
                                                                                    Edit
                                                                                </BullaBlueTextButton>
                                                                                <BullaBlueTextButton
                                                                                    onClick={() => setFinancingTerms('none')}
                                                                                    isDisabled={financeButtonDisabled}
                                                                                >
                                                                                    Cancel
                                                                                </BullaBlueTextButton>
                                                                            </HStack>
                                                                        )}
                                                                    </Box>
                                                                )}
                                                        </Box>
                                                        <Field name="description">
                                                            {({ field }: FieldProps) => (
                                                                <ClaimDescriptionField
                                                                    {...{
                                                                        field,
                                                                        error: errors.description,
                                                                        touched: touched.description,
                                                                        label: 'Description',
                                                                        isDisabled:
                                                                            transactionPending || (!!fromLink && !!defaults?.description),
                                                                        required: descriptionRequired,
                                                                        fromLink: fromLink,
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                        {values.instantPayment === false && (
                                                            <Field name="dueBy">
                                                                {({ field }: FieldProps) => (
                                                                    <DueByField
                                                                        {...{
                                                                            field: values.instantPayment
                                                                                ? { ...field, value: new Date() }
                                                                                : field,
                                                                            error: errors.dueBy,
                                                                            touched: !!touched.dueBy,
                                                                            setDueBy: apply(setFieldValue, 'dueBy'),
                                                                            isDisabled: transactionPending || values.instantPayment,
                                                                            label: 'Due Date',
                                                                            placement: 'top-right',
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        )}
                                                        {claimType === 'Payment' && !fromLink ? (
                                                            <Field name="instantPayment">
                                                                {({ field }: FieldProps) => (
                                                                    <Checkbox
                                                                        my="2"
                                                                        whiteSpace={'nowrap'}
                                                                        isChecked={field.value}
                                                                        isDisabled={isLoading}
                                                                        onChange={e => {
                                                                            const selectInstantPay = e.target.checked;
                                                                            if (
                                                                                !selectInstantPay &&
                                                                                values.token.address === constants.AddressZero
                                                                            ) {
                                                                                setFieldValue('token', initialERC20);
                                                                            }
                                                                            setFieldValue('instantPayment', selectInstantPay);
                                                                        }}
                                                                    >
                                                                        Instant Payment
                                                                    </Checkbox>
                                                                )}
                                                            </Field>
                                                        ) : undefined}
                                                    </Stack>
                                                </GridItem>
                                                <GridItem key="right">
                                                    <Stack spacing="3">
                                                        <LabelText>Additional Details</LabelText>

                                                        {!fromLink && (
                                                            <Field name="attachment">
                                                                {({ field }: FieldProps) => (
                                                                    <AttachmentField
                                                                        field={field}
                                                                        amount={values.claimAmount}
                                                                        description={values.description}
                                                                        recipient={values.recipient}
                                                                        tokenSymbol={values.token.symbol}
                                                                        type={claimType}
                                                                        label="Attachment"
                                                                        transactionPending={transactionPending}
                                                                        attachment={values.attachment}
                                                                        setAttachment={(file: BullaItemAttachment | undefined) =>
                                                                            setFieldValue('attachment', file)
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                        )}
                                                        {!fromLink && (
                                                            <Field name="tags">
                                                                {({ field }: FieldProps) => (
                                                                    <AccountTagField
                                                                        {...{
                                                                            field,
                                                                            isDisabled: transactionPending,
                                                                            error: errors.tags,
                                                                            touched: touched.tags,
                                                                            setTags: apply(setFieldValue, field.name),
                                                                            setStatus,
                                                                            label: 'Categories',
                                                                            creatingExpense: isExpense,
                                                                            dropdownModalRef: modalContentRef,
                                                                            mb: '-2',
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        )}
                                                        {membership !== null && (
                                                            <Field name="notes">
                                                                {({ field }: FieldProps) => (
                                                                    <ClaimNotesField
                                                                        {...{
                                                                            field,
                                                                            isDisabled: transactionPending,
                                                                            error: errors.notes,
                                                                            touched: touched.notes,
                                                                            label: 'Notes',
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        )}
                                                        <Field name="emailAddress">
                                                            {({ field }: FieldProps) => (
                                                                <Collapse in={!fromLink}>
                                                                    <EmailField
                                                                        {...{
                                                                            field,
                                                                            isDisabled: transactionPending,
                                                                            error: errors.emailAddress,
                                                                            touched: touched.emailAddress,
                                                                            label: 'Recipient Email',
                                                                            required: claimType == 'Invoice' && !isNftInvoice,
                                                                        }}
                                                                    />
                                                                </Collapse>
                                                            )}
                                                        </Field>
                                                        <Field name="emailCC" unmountOnExit>
                                                            {({ field }: FieldProps) => (
                                                                <EmailField
                                                                    {...{
                                                                        field,
                                                                        isDisabled: transactionPending,
                                                                        error: errors.emailCC,
                                                                        touched: touched.emailCC,
                                                                        label: 'Confirmation Email',
                                                                        required: false,
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                        {fromLink && pastPaymentsToRecipient && (
                                                            <PastPaymentsTable
                                                                payments={pastPaymentsToRecipient}
                                                                readyToTransact={readyToTransact}
                                                                network={defaults?.network ?? connectedNetwork}
                                                                recipient={defaults.recipient!}
                                                            />
                                                        )}
                                                    </Stack>
                                                </GridItem>
                                            </Grid>
                                        </ModalBody>
                                        <ModalFooterWithShadow>
                                            <Spacer />
                                            {fromLink && (
                                                <Flex justifyContent={'flex-end'}>
                                                    <SecondaryButton
                                                        onClick={() => {
                                                            closeModal();
                                                            navigate('/');
                                                        }}
                                                        w="min-content"
                                                        mr="4"
                                                    >
                                                        Cancel
                                                    </SecondaryButton>
                                                </Flex>
                                            )}
                                            {defaults?.network !== undefined && connectedNetwork !== defaults.network ? (
                                                <SwitchNetworksButton requiredNetwork={defaults.network!} />
                                            ) : values.instantPayment ? (
                                                <InstantPaymentButton
                                                    paymentInfo={[{ amount: values.claimAmount, token: values.token }]}
                                                    isLoading={isLoading}
                                                    isDisabled={isDisabled}
                                                    w={['100%', 'inherit', 'inherit']}
                                                />
                                            ) : (
                                                <OrangeButton
                                                    w={['100%', 'inherit', 'inherit']}
                                                    type="submit"
                                                    isLoading={isLoading}
                                                    isDisabled={isDisabled}
                                                >
                                                    Create
                                                </OrangeButton>
                                            )}
                                        </ModalFooterWithShadow>
                                        {vendorFinancingEnabled && (
                                            <EditFinancingTermsModal
                                                isOpen={showFinancingOptions}
                                                onClose={() => setShowFinancingOptions(false)}
                                                height={modalContentRef?.current?.getBoundingClientRect().height}
                                                terms={financingTerms}
                                                token={values.token}
                                                claimAmount={values.claimAmount}
                                                setClaimAmount={apply(setFieldValue, 'claimAmount')}
                                                onSubmit={terms => {
                                                    setFinancingTerms(terms);
                                                    setShowFinancingOptions(false);
                                                }}
                                            />
                                        )}
                                    </ModalContent>
                                </Form>
                            </>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
