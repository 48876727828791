import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Box,
    ButtonGroup,
    Collapse,
    Divider,
    Flex,
    Grid,
    GridItem,
    HStack,
    Image,
    Link,
    ModalBody,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CoinsLogo from 'url:../../../assets/coins.svg';
import { ClaimInfo, FinanciableClaimInfo } from '../../../data-lib/data-model';
import { InvoiceFundedEvent, InvoiceReconciledEvent } from '../../../data-lib/domain/factoring-domain';
import { isFactoringEvent, isUnFactoredEvent } from '../../../data-lib/dto/event-filters';
import { addressEquality, weiToDisplayAmt } from '../../../data-lib/ethereum';
import { getGnosisSafeURL } from '../../../data-lib/gnosis-tools/gnosis';
import {
    getItemRelationToUser,
    isFactored,
    isFinancedClaim,
    isFinancingAccepted,
    isFinancingOffered,
    isUnfactored,
} from '../../../data-lib/helpers';
import { BULLA_COLLECTION_ADDRESS, NETWORKS, TokenDto } from '../../../data-lib/networks';
import { TokenDisplay } from '../../../data-lib/tokens';
import { useCanChangeNetwork } from '../../../hooks/useCanChangeNetwork';
import { useTokenBalances } from '../../../hooks/useChainData';
import { useContactsApi } from '../../../hooks/useContactsApi';
import { useEditNotesDisplay } from '../../../hooks/useEditNotesDisplay';
import { useFactoringAndDepositPermissions } from '../../../hooks/useFactoringAndDepositPermissions';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useDataReadiness } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { calculateDateDifference, toDateDisplay, toDateWithTime } from '../../../tools/common';
import { enableBullaFactoringPool, truckerFinanceEnabled } from '../../../tools/featureFlags';
import { WithSkeleton } from '../../base/skeleton';
import { getStatusBadge } from '../../base/status-badge';
import { ChainSymbol } from '../../chain-symbol';
import { TokenAmount } from '../../currency/token-display-amount';
import { CancelButton, PayButton, UnfactorButton } from '../../display/claim-action-buttons';
import { ViewTagOnItem } from '../../inputs/account-tag-input';
import { IPFSLink } from '../../inputs/attachment-input';
import { BullaBlueTextButton, OrangeButton, TextButton } from '../../inputs/buttons';
import { CloseModalButton, ModalFooterWithShadow, ShareItemButton } from '../common';
import { getFinancingTermLabels, TermsSummary } from '../financing-terms-modal/financing-inputs';
import { ViewFinancingTermsModal } from '../financing-terms-modal/financing-terms-modal';
import { MakePaymentModal } from '../make-payment-modal/make-payment-modal';
import { PremiumPricingModal } from '../premium-pricing-modal';
import { BullaFactoringBox, claimDetailVariants, CreditorDebtorBox, ItemDesc, logVariants, ReceiptLine } from './item-details-components';
import { TruckerFinanceOfferBox } from './trucker-financing';
import { ViewLogModal } from './view-log';

export type ClaimDetailsProps = { claim?: ClaimInfo; handleClose: () => void; modalContentRef?: React.RefObject<HTMLDivElement> };

const isTransferred = (claim: ClaimInfo, actingWallet: string) => {
    const transferEvents = claim.logs.filter(log => log.eventType === 'Transfer');

    if (addressEquality(claim.debtor, actingWallet) && transferEvents.length > 0) {
        const firstTransfer = transferEvents[0];
        const lastTransfer = transferEvents[transferEvents.length - 1];

        if ('from' in firstTransfer && 'to' in lastTransfer) {
            return !addressEquality(firstTransfer.from, lastTransfer.to);
        }
    }
    return claim.isTransferred;
};

export function getFundedAndKickbackFactoringPayments(claim: ClaimInfo): BigNumber {
    const fundedAndKickbackPayments = claim.logs.filter(isFactoringEvent).map(log => {
        return BigNumber.from(log.__typename === 'InvoiceFundedEvent' ? log.fundedAmount : log.kickbackAmount);
    });

    return fundedAndKickbackPayments.reduce((acc, current) => acc.add(current), BigNumber.from(0));
}

export function getUnfactoredPayments(claim: ClaimInfo) {
    const unfactorEvent = claim.logs.find(isUnFactoredEvent);
    if (!unfactorEvent) {
        return { refundAmount: BigNumber.from(0), interestCharged: BigNumber.from(0) };
    }
    return {
        refundAmount: BigNumber.from(unfactorEvent.totalRefundAmount),
        interestCharged: BigNumber.from(unfactorEvent.interestToCharge),
    };
}

type FactoringTermsCardProps = {
    token: TokenDto;
    invoiceFundedEvent: InvoiceFundedEvent;
    claimAmount: BigNumber;
    invoiceReconciledEvent: InvoiceReconciledEvent | undefined;
};
const FactoringTermsCard: React.FC<FactoringTermsCardProps> = ({
    token,
    claimAmount,
    invoiceFundedEvent: { fundedAmount, targetInterest, targetAdminFee, targetProtocolFee },
    invoiceReconciledEvent,
}) => {
    const needsRefresh = !targetInterest || !targetAdminFee || !targetProtocolFee ? 'refresh page to see amounts' : undefined;
    const upfrontAmount = fundedAmount;

    const fullAmountReceived = claimAmount
        .sub(invoiceReconciledEvent?.trueInterest ?? targetInterest!)
        .sub(invoiceReconciledEvent?.trueAdminFee ?? targetAdminFee!)
        .sub(invoiceReconciledEvent?.trueProtocolFee ?? targetProtocolFee!);

    return (
        <Box bg="gray.50" borderRadius={'4px'} px="5" py="4" fontSize={'13px'} fontWeight={'500'} mt="3">
            <Stack spacing="4" my="2">
                <Flex justify="space-between">
                    <Text color="gray.600">Invoice Amount</Text>
                    <Text color="gray.600">{`${formatUnits(claimAmount, token.decimals)} ${token.symbol}`}</Text>
                </Flex>
                <Divider color={'gray.300'} />
                <Flex justify="space-between">
                    <Text color="gray.600">Received Upfront</Text>
                    <Text color="gray.600">{`${formatUnits(upfrontAmount, token.decimals)} ${token.symbol}`}</Text>
                </Flex>
                <Divider color={'gray.300'} />
                <Flex justify="space-between">
                    <Text color="gray.600">{invoiceReconciledEvent ? 'Received Later' : 'To Receive Later'}</Text>
                    <Text color="gray.600">{`${formatUnits(fullAmountReceived.sub(upfrontAmount), token.decimals)} ${token.symbol}`}</Text>
                </Flex>
                <Divider color={'gray.300'} />
                <Flex justify="space-between">
                    <Text color="gray.600">{invoiceReconciledEvent ? 'Received in Total' : 'To Receive in Total'}</Text>
                    <Text color="gray.600">{needsRefresh ?? `${formatUnits(fullAmountReceived, token.decimals)} ${token.symbol}`}</Text>
                </Flex>
                <Divider color={'gray.300'} />
                <Flex justify="space-between" color="gray.600" fontSize={'13px'}>
                    <Text>Pool Fee</Text>
                    <Text>
                        {needsRefresh ??
                            `${formatUnits(invoiceReconciledEvent?.trueInterest ?? targetInterest!, token.decimals)} ${token.symbol}`}
                    </Text>
                </Flex>
                <Divider color={'gray.300'} />
                <Flex justify="space-between" color="gray.600" fontSize={'13px'}>
                    <Text>Admin Fee</Text>

                    <Text>
                        {needsRefresh ??
                            `${formatUnits(invoiceReconciledEvent?.trueAdminFee ?? targetAdminFee!, token.decimals)} ${token.symbol}`}
                    </Text>
                </Flex>
                <Divider color={'gray.300'} />
                <Flex justify="space-between" color="gray.600" fontSize={'13px'}>
                    <Text>Protocol Fee</Text>
                    <Text>
                        {needsRefresh ??
                            `${formatUnits(invoiceReconciledEvent?.trueProtocolFee ?? targetProtocolFee!, token.decimals)} ${token.symbol}`}
                    </Text>
                </Flex>
            </Stack>
        </Box>
    );
};

export const ClaimDetails = ({ claim, handleClose, modalContentRef }: ClaimDetailsProps) => {
    const actingWallet = useActingWalletAddress();
    const { connectedNetwork, connectedNetworkConfig } = useWeb3();
    const { isChainInitialized } = useDataReadiness();
    const canChangeNetwork = useCanChangeNetwork();
    const { pendingPayments, safeInfo } = useGnosisSafe();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const tokenBalances = useTokenBalances({ chainId: claim?.chainId ?? connectedNetwork, poll: true });
    const [showFinancingOptions, setShowFinancingOptions] = React.useState(false);
    const [showMakePaymentModal, setShowMakePaymentModal] = React.useState(false);
    const [viewLogs, setViewLogs] = React.useState(false);
    const bodyRef = React.useRef<HTMLDivElement>(null);
    const [showFactoringTerms, setShowFactoringTerms] = React.useState(false);

    const isLoading = !claim || !isChainInitialized(claim.chainId);
    const isUserRelatedToItem =
        !isLoading &&
        (addressEquality(actingWallet, claim.creditor) ||
            addressEquality(actingWallet, claim.debtor) ||
            addressEquality(actingWallet, claim.origin) ||
            claim.logs.some(log => log.__typename === 'InvoiceFundedEvent' && addressEquality(log.originalCreditor, actingWallet))); // @notice picks up Safe factoring, to allow unfactorin
    const bullaBatchFeeClaim =
        !isLoading && addressEquality(claim.creditor, BULLA_COLLECTION_ADDRESS) && claim.description == 'Bulla Batch Fee';
    const isPendingGnosisApproval = !!pendingPayments.payClaimTransactions.find(item =>
        item.__type === 'PayClaimTransaction' ? item.tokenId === claim?.id : true,
    );
    const direction = claim ? getItemRelationToUser(actingWallet, claim).direction : 'not-loaded';
    const isPayable =
        claim &&
        direction === 'Out' &&
        (claim.claimStatus === 'Pending' || claim.claimStatus === 'Repaying') &&
        addressEquality(claim.debtor, actingWallet);
    const isLoan = claim && isFinancingAccepted(claim);
    const isPaid = claim && claim.claimStatus === 'Paid';

    const toggleViewLogs = () => setViewLogs(viewLogs => !viewLogs);

    const templateColumns = isMobile ? '1fr 1fr' : '1fr 1fr 1fr';
    const notYourClaim =
        (claim &&
            !addressEquality(actingWallet, claim.debtor) &&
            !addressEquality(actingWallet, claim.creditor) &&
            !isFactored(claim, actingWallet)) ??
        false;

    const { poolsWithPermissions } = useFactoringAndDepositPermissions();
    const currentPool = poolsWithPermissions.find(pool => pool.chainId === claim?.chainId);
    const hasFactoringPermissions = currentPool?.hasFactoringPermissions ?? false;
    const factoringConfig = currentPool?.factoringConfig;
    const bullaFundAddress = factoringConfig?.bullaFactoringToken.token.address;

    const buttonGroup = (
        <HStack spacing="4" w="100%" justify="center" minH={'50px'}>
            <ButtonGroup spacing="4">
                <BullaBlueTextButton textDecoration={'none'} onClick={toggleViewLogs} minW="80px">
                    {viewLogs ? 'Back' : 'View Logs'}
                </BullaBlueTextButton>
                {/* {!isMobile && shareClaimButton} */}
            </ButtonGroup>
            {!isMobile && <Spacer />}
            {claim &&
                isUserRelatedToItem &&
                (claim.claimStatus === 'Pending' || claim.claimStatus === 'Factored' || claim.claimStatus === 'Unfactored') &&
                !bullaBatchFeeClaim &&
                (isFactored(claim, actingWallet) && !isUnfactored(claim) && factoringConfig ? (
                    <HStack>
                        <UnfactorButton claimInfo={claim} onComplete={handleClose} factoringConfig={factoringConfig} />
                    </HStack>
                ) : (
                    (!bullaFundAddress || !addressEquality(claim.creditor, bullaFundAddress)) && (
                        <CancelButton claimInfo={claim} onComplete={handleClose} />
                    )
                ))}
            {claim &&
                isPayable &&
                (isFinancingAccepted(claim) ? (
                    <OrangeButton onClick={() => setShowMakePaymentModal(true)} isDisabled={claim.chainId !== connectedNetwork}>
                        Make a payment
                    </OrangeButton>
                ) : (
                    <PayButton claimInfo={claim} onComplete={handleClose} />
                ))}
            {claim && notYourClaim && claim.claimStatus == 'Pending' && truckerFinanceEnabled && (
                <OrangeButton
                    onClick={() => {
                        handleClose();
                        const params = new URLSearchParams();
                        params.set('recipient', claim.creditor);
                        params.set('token', claim.tokenInfo.token.address);
                        params.set('description', `Financing for claim #${claim.id}`);
                        params.set('amount', weiToDisplayAmt({ amountWei: claim.claimAmount, token: claim.tokenInfo.token }).toString());
                        params.set('dueInDays', '100');
                        navigate({ pathname: '/financing/new', search: `?${params.toString()}` });
                    }}
                >
                    Offer Loan
                </OrangeButton>
            )}
        </HStack>
    );

    const calculateOutstandingAmount = (claim: ClaimInfo) => {
        const factoringPayments = getFundedAndKickbackFactoringPayments(claim);
        const { interestCharged } = getUnfactoredPayments(claim);
        return isFactored(claim, actingWallet)
            ? +claim.claimAmount.sub(factoringPayments)
            : isUnfactored(claim)
            ? +interestCharged
            : +claim.claimAmount.sub(claim.paidAmount);
    };

    function getFinancingDetails(claim: FinanciableClaimInfo) {
        const financingTerms = claim.financingState.terms;
        const principalAmount = financingTerms.principalAmount;
        const outstandingLoanAmount = financingTerms.totalAmountDue.sub(claim.paidAmount);
        const paidAmountOnLoan = claim.paidAmount.sub(financingTerms.downPayment);
        const interestRatePercent = financingTerms.interestRateBPS / 100;
        const interestProportion = interestRatePercent / (100 + interestRatePercent);
        const interestPaid = +(interestProportion * +paidAmountOnLoan);
        const placeValue = Math.floor(Math.log10(interestPaid)) - 1;
        const factor = Math.pow(10, placeValue);
        const interestPaidRounded = interestPaid > 0 ? Math.ceil(interestPaid / factor) * factor : 0;

        return {
            principalAmount,
            outstandingLoanAmount,
            paidAmountOnLoan,
            interestRatePercent,
            interestProportion,
            interestPaid,
            interestPaidRounded,
        };
    }

    function getTitle(isLoan: boolean | undefined, userAddress: string, claim: ClaimInfo): string {
        if (isPaid) {
            return addressEquality(userAddress, claim.debtor) ? 'Paid' : 'Received';
        } else if (isLoan) {
            return addressEquality(userAddress, claim.debtor) ? 'Interest Paid' : 'Interest Received';
        } else if (isUnfactored(claim)) {
            return 'Received (after unfactoring)';
        } else if (isFactored(claim, userAddress)) {
            return 'Received (after factoring)';
        } else {
            return 'Received';
        }
    }

    const walletBalance = claim ? tokenBalances.getBalanceForToken(claim.tokenInfo.token.address) : undefined;
    const { changeNetwork } = useOnboard();

    const insufficientFunds = (claim: ClaimInfo, walletBalance: number) =>
        weiToDisplayAmt({ amountWei: calculateOutstandingAmount(claim), token: claim.tokenInfo.token }) > walletBalance;

    const switchText = (claim: ClaimInfo) => `Switch to ${NETWORKS[claim.chainId].label}`;

    const wrongNetworkLabel = claim && claim.chainId !== connectedNetwork && (
        <Box p="0">
            <Alert status="warning" bg={'white'} py="0">
                <AlertIcon />
                <span>
                    You are connected to {connectedNetworkConfig.label} network.{' '}
                    {canChangeNetwork ? (
                        <TextButton onClick={() => changeNetwork(claim.chainId)}>{switchText(claim)}</TextButton>
                    ) : (
                        switchText(claim)
                    )}{' '}
                    to {direction === 'Out' ? 'Pay' : 'Rescind'}.
                </span>
            </Alert>
        </Box>
    );

    const { labelRow, notesRow, modalOpen, closeModal } = useEditNotesDisplay({
        isLoading,
        item: !!claim ? { ...claim, initialNotes: claim.notes, tags: [], description: '' } : undefined, // tags and description are not managed by backend
    });

    const usageLabel = React.useMemo(() => (claim ? (isLoan ? 'Loan' : claim.claimType) : 'Claim'), [claim, isLoan]);

    const { isContactOfBullaFund } = useContactsApi();
    const [displayTruckerFinanceModal, setDisplayTruckerFinanceModal] = React.useState(false);

    React.useEffect(() => {
        if (claim) {
            const checkAndSetModal = async () => {
                const result = await isContactOfBullaFund(actingWallet);
                setDisplayTruckerFinanceModal(result);
            };
            checkAndSetModal();
        }
    }, [claim?.id ?? 'no-id']);

    return (
        <>
            <CloseModalButton onClose={handleClose} />
            <PremiumPricingModal modalOpen={modalOpen} closeModal={closeModal} />

            <ModalBody ref={bodyRef} width="200%" px="0" overflowY="auto" pb="10">
                <AnimatePresence initial={false}>
                    <motion.div
                        key="details"
                        style={{
                            width: `50%`,
                            display: 'inline-block',
                            float: 'left',
                            padding: '0 2em',
                            maxHeight: 'fit-content',
                            height: '100%',
                        }}
                        initial={'active'}
                        animate={viewLogs ? 'inactive' : 'active'}
                        transition={{
                            x: { type: 'just' },
                        }}
                        variants={claimDetailVariants}
                    >
                        <WithSkeleton isLoading={isLoading} fixedWidth="16em" height="32px">
                            <Text color="gray.700" fontWeight={'700'} fontSize="24px" noOfLines={1} lineHeight="32px">
                                {usageLabel} Details
                                {claim && <ShareItemButton item={claim} />}
                            </Text>
                        </WithSkeleton>
                        <Box h="16px" />
                        {(isPendingGnosisApproval || (claim && isTransferred(claim, actingWallet))) && (
                            <Alert status="info" mb="4">
                                <AlertIcon />
                                {isPendingGnosisApproval
                                    ? `${usageLabel} payment is pending approval in your Gnosis-Safe`
                                    : claim && isFactored(claim, actingWallet)
                                    ? `This ${usageLabel.toLowerCase()} has been factored`
                                    : `This ${usageLabel.toLowerCase()} has been ${
                                          claim && isUnfactored(claim) ? 'unfactored' : 'transferred'
                                      }`}
                                {isPendingGnosisApproval && (
                                    <Link href={getGnosisSafeURL(connectedNetworkConfig, safeInfo!.safeAddress)} isExternal ml="2" mt="1">
                                        <Box color={'scheme.accent_dark'}>
                                            <ExternalLinkIcon mt="-4px" />
                                        </Box>
                                    </Link>
                                )}
                            </Alert>
                        )}
                        <Stack spacing="3">
                            <ItemDesc title="Chain">
                                <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                    {claim && (
                                        <HStack>
                                            <ChainSymbol chainId={claim.chainId} />
                                            <Text>{NETWORKS[claim.chainId].label}</Text>
                                        </HStack>
                                    )}
                                </WithSkeleton>
                            </ItemDesc>
                            <ItemDesc title="Token">
                                <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                    {claim && <TokenDisplay token={claim.tokenInfo} />}
                                </WithSkeleton>
                            </ItemDesc>
                            <ItemDesc title="Created">
                                <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                    {claim && `${toDateWithTime(claim.created)} (${calculateDateDifference(claim.created)})`}
                                </WithSkeleton>
                            </ItemDesc>

                            <ItemDesc title="Due Date">
                                <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                    {claim && `${toDateDisplay(claim.dueBy)} (Due ${calculateDateDifference(claim.dueBy)})`}
                                </WithSkeleton>
                            </ItemDesc>

                            <ItemDesc title="Claim Id">
                                <WithSkeleton isLoading={isLoading} fixedWidth="6em">
                                    <HStack>{claim && <Text> #{claim.id} </Text>}</HStack>
                                </WithSkeleton>
                            </ItemDesc>
                            <ItemDesc title="Description">
                                <WithSkeleton children={claim ? claim.description : 'loading'} isLoading={isLoading} randomW />
                            </ItemDesc>

                            {!!claim && claim.ipfsHash !== '' && (
                                <ItemDesc title="File">
                                    <WithSkeleton children={<IPFSLink ipfsHash={claim.ipfsHash} />} isLoading={isLoading} randomW />
                                </ItemDesc>
                            )}

                            <ItemDesc title="Categories">
                                <WithSkeleton isLoading={isLoading} fixedWidth="14em">
                                    {claim ? (
                                        <ViewTagOnItem
                                            item={claim}
                                            isDisabled={isUserRelatedToItem === false || claim.chainId !== connectedNetwork}
                                            dropdownPortalRef={modalContentRef}
                                        />
                                    ) : (
                                        'loading'
                                    )}
                                </WithSkeleton>
                            </ItemDesc>
                            <HStack>
                                <ItemDesc title="Status">
                                    <WithSkeleton
                                        children={
                                            <GridItem>
                                                {claim && direction !== 'not-loaded' ? (
                                                    <HStack>
                                                        {getStatusBadge(claim.claimStatus, direction)}
                                                        {(isFactored(claim, actingWallet) || isUnfactored(claim)) && (
                                                            <BullaBlueTextButton onClick={() => setShowFactoringTerms(prev => !prev)}>
                                                                View factoring terms
                                                            </BullaBlueTextButton>
                                                        )}
                                                    </HStack>
                                                ) : (
                                                    ''
                                                )}
                                            </GridItem>
                                        }
                                        isLoading={isLoading}
                                        randomW
                                    />
                                </ItemDesc>
                            </HStack>
                            <Collapse in={showFactoringTerms}>
                                {claim && isFactored(claim, actingWallet) && (
                                    <FactoringTermsCard
                                        token={claim.tokenInfo.token}
                                        claimAmount={claim.claimAmount}
                                        invoiceFundedEvent={
                                            claim.logs.filter((x): x is InvoiceFundedEvent => x.__typename == 'InvoiceFundedEvent')[0]
                                        }
                                        invoiceReconciledEvent={
                                            claim.logs.filter(
                                                (x): x is InvoiceReconciledEvent => x.__typename == 'InvoiceReconciledEvent',
                                            )[0]
                                        }
                                    />
                                )}
                            </Collapse>
                        </Stack>
                        <Box h="2" />
                        <CreditorDebtorBox
                            creditor={claim?.creditor ?? 'loading'}
                            debtor={claim?.debtor ?? 'loading'}
                            chainId={claim?.chainId ?? 1}
                        />
                        <Box h="2" />
                        {claim ? (
                            isFinancingOffered(claim) ? (
                                <Flex border="1px solid #E2E8F0" borderRadius={'8px'} flexDir="row" px="7" py="6" mb="4">
                                    <Image src={CoinsLogo} mr="6" />
                                    <Stack spacing="1">
                                        <Text fontSize={'20px'} fontWeight="700" color={'black'}>
                                            {direction == 'In' ? 'Debtor can pay over time' : 'Pay over time available'}
                                        </Text>
                                        <Text fontSize={'12px'}>{`Financing offer for ${
                                            claim.financingState.terms.interestRateBPS / 100
                                        }% interest with ${weiToDisplayAmt({
                                            amountWei: claim.financingState.terms.downPayment,
                                            token: claim.tokenInfo.token,
                                        })} ${claim.tokenInfo.token.symbol} down`}</Text>
                                    </Stack>
                                    <Spacer />
                                    <BullaBlueTextButton
                                        textDecoration="none"
                                        w="fit-content"
                                        onClick={() => setShowFinancingOptions(true)}
                                    >
                                        View terms
                                    </BullaBlueTextButton>
                                </Flex>
                            ) : isLoan ? (
                                <Stack>
                                    <TermsSummary
                                        terms={getFinancingTermLabels(
                                            claim.financingState.terms,
                                            claim.tokenInfo.token.symbol,
                                            claim.tokenInfo.token.decimals,
                                        )}
                                        hideDownPaymentRow={claim.financingState.origination.kind === 'frendlend'}
                                    />
                                    <Box h="4" />
                                </Stack>
                            ) : null
                        ) : null}
                        {claim &&
                            addressEquality(claim.creditor, actingWallet) &&
                            enableBullaFactoringPool &&
                            factoringConfig &&
                            hasFactoringPermissions &&
                            !isFinancingOffered(claim) &&
                            (claim.claimStatus == 'Repaying' || claim.claimStatus == 'Pending') && (
                                <BullaFactoringBox
                                    claim={claim!}
                                    usageLabel={usageLabel}
                                    onComplete={handleClose}
                                    factoringConfig={factoringConfig}
                                />
                            )}
                        {claim &&
                            truckerFinanceEnabled &&
                            addressEquality(actingWallet, claim.creditor) &&
                            displayTruckerFinanceModal &&
                            claim.claimStatus == 'Pending' && <TruckerFinanceOfferBox claim={claim} />}
                        {labelRow}
                        {notesRow}
                        <Grid templateColumns={templateColumns} w={'100%'} rowGap="4" mt="4">
                            <ReceiptLine
                                isLoading={isLoading}
                                isMobile={!!isMobile}
                                title={isLoan ? 'Principal Amount' : 'Requested'}
                                tokenAmount={
                                    <WithSkeleton isLoading={isLoading}>
                                        {!isLoading && (
                                            <HStack justifyContent="end">
                                                <TokenAmount
                                                    amount={isLoan ? getFinancingDetails(claim).principalAmount : claim.claimAmount}
                                                    tokenInfo={claim.tokenInfo}
                                                />
                                            </HStack>
                                        )}
                                    </WithSkeleton>
                                }
                            />

                            <ReceiptLine
                                isLoading={isLoading}
                                isMobile={!!isMobile}
                                title={getTitle(isLoan, actingWallet, claim!)}
                                tokenAmount={
                                    <WithSkeleton isLoading={isLoading}>
                                        {claim && (
                                            <TokenAmount
                                                amount={
                                                    isPaid && !isFactored(claim, actingWallet)
                                                        ? claim.claimAmount
                                                        : isLoan
                                                        ? getFinancingDetails(claim).interestPaidRounded
                                                        : isFactored(claim, actingWallet)
                                                        ? getFundedAndKickbackFactoringPayments(claim)
                                                        : claim.paidAmount
                                                }
                                                tokenInfo={claim.tokenInfo}
                                            />
                                        )}
                                    </WithSkeleton>
                                }
                            />

                            {claim && claim.claimStatus == 'Pending' && direction === 'Out' && !notYourClaim && (
                                <ReceiptLine
                                    isLoading={isLoading}
                                    isMobile={!!isMobile}
                                    title="Wallet Balance"
                                    tokenAmount={
                                        <WithSkeleton isLoading={walletBalance == undefined}>
                                            {walletBalance !== undefined && (
                                                <TokenAmount
                                                    amount={walletBalance}
                                                    tokenInfo={claim.tokenInfo}
                                                    isDisplayAmount
                                                    withRounding
                                                />
                                            )}
                                        </WithSkeleton>
                                    }
                                    {...(walletBalance !== undefined && insufficientFunds(claim, walletBalance)
                                        ? { color: 'red.500' }
                                        : {})}
                                />
                            )}
                            {isLoan ? (
                                <ReceiptLine
                                    isLoading={isLoading}
                                    isMobile={!!isMobile}
                                    title={+getFinancingDetails(claim).outstandingLoanAmount > 0 ? 'Outstanding' : 'Principal Repaid'}
                                    tokenAmount={
                                        <WithSkeleton isLoading={isLoading}>
                                            <HStack justifyContent="end" h="24px">
                                                {claim && (
                                                    <TokenAmount
                                                        amount={
                                                            +getFinancingDetails(claim).outstandingLoanAmount > 0
                                                                ? getFinancingDetails(claim).outstandingLoanAmount
                                                                : getFinancingDetails(claim).principalAmount
                                                        }
                                                        tokenInfo={claim.tokenInfo}
                                                    />
                                                )}
                                            </HStack>
                                        </WithSkeleton>
                                    }
                                    {...(walletBalance !== undefined &&
                                    claim &&
                                    insufficientFunds(claim, walletBalance) &&
                                    direction == 'Out'
                                        ? { color: 'red.500' }
                                        : {})}
                                />
                            ) : (
                                <>
                                    {claim && isUnfactored(claim) && (
                                        <ReceiptLine
                                            isLoading={isLoading}
                                            isMobile={!!isMobile}
                                            title="Bad Debt (after factoring)"
                                            tokenAmount={
                                                <WithSkeleton isLoading={isLoading}>
                                                    <HStack justifyContent="end" h="24px">
                                                        <TokenAmount
                                                            amount={getUnfactoredPayments(claim).interestCharged}
                                                            tokenInfo={claim.tokenInfo}
                                                        />
                                                    </HStack>
                                                </WithSkeleton>
                                            }
                                        />
                                    )}
                                    <ReceiptLine
                                        isLoading={isLoading}
                                        isMobile={!!isMobile}
                                        title={
                                            claim && claim.claimStatus === 'Paid' && !addressEquality(actingWallet, claim.debtor)
                                                ? 'Factoring Fees'
                                                : 'Outstanding'
                                        }
                                        tokenAmount={
                                            <WithSkeleton isLoading={isLoading}>
                                                <HStack justifyContent="end" h="24px">
                                                    {claim && (
                                                        <TokenAmount
                                                            amount={
                                                                claim.claimStatus === 'Paid' && !addressEquality(actingWallet, claim.debtor)
                                                                    ? calculateOutstandingAmount(claim)
                                                                    : claim.claimAmount.sub(claim.paidAmount)
                                                            }
                                                            tokenInfo={claim.tokenInfo}
                                                        />
                                                    )}
                                                </HStack>
                                            </WithSkeleton>
                                        }
                                        {...(walletBalance !== undefined &&
                                        claim &&
                                        insufficientFunds(claim, walletBalance) &&
                                        direction == 'Out'
                                            ? { color: 'red.500' }
                                            : {})}
                                    />
                                </>
                            )}
                        </Grid>
                    </motion.div>
                    <motion.div
                        style={{
                            width: `50%`,
                            display: 'inline-block',
                            float: 'left',
                            padding: '0 2em',
                            maxHeight: 'fit-content',
                        }}
                        key="logs"
                        initial={['invisible', 'out']}
                        animate={viewLogs ? ['visible', 'in'] : ['invisible', 'out']}
                        transition={{
                            x: { type: 'just' },
                        }}
                        variants={logVariants}
                    >
                        {claim && <ViewLogModal item={claim} />}
                    </motion.div>
                </AnimatePresence>
            </ModalBody>
            <ModalFooterWithShadow {...(isMobile ? { px: '0' } : {})}>
                <Stack w="100%" spacing={4} {...(!isMobile ? { alignItems: 'center' } : {})}>
                    {buttonGroup}
                    {wrongNetworkLabel}
                    {notYourClaim && (
                        <Box p="0">
                            <Alert status="warning" bg={'white'} py="0">
                                <AlertIcon />
                                <span>This item is not associated with your wallet address.</span>
                            </Alert>
                        </Box>
                    )}
                </Stack>
            </ModalFooterWithShadow>
            {claim && isFinancedClaim(claim) && (
                <ViewFinancingTermsModal
                    isOpen={showFinancingOptions}
                    onClose={() => setShowFinancingOptions(false)}
                    height={modalContentRef?.current?.getBoundingClientRect().height}
                    claim={claim}
                />
            )}
            {claim && isFinancingAccepted(claim) && (
                <MakePaymentModal isOpen={showMakePaymentModal} onClose={() => setShowMakePaymentModal(false)} claim={claim} />
            )}
        </>
    );
};
