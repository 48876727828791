import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    ButtonProps,
    ChakraProps,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuItemProps,
    MenuList,
    MenuProps,
    Spacer,
    Link as ChakraLink,
    Text,
    TextProps,
} from '@chakra-ui/react';
import React from 'react';
import { NavLink as ReactRouterNavLink, useNavigate } from 'react-router-dom';
import { ChainId, NETWORKS } from '../../data-lib/networks';
import { useCanChangeNetwork } from '../../hooks/useCanChangeNetwork';
import { useOnboard, useWeb3 } from '../../hooks/useWeb3';
import { useAppState } from '../../state/app-state';
import { ActionPopover, PopoverAction } from '../display/actionPopover';
import { CreateClaimModal } from '../modals/create-claim-modal/create-claim-modal';
import { enableNewInvoiceCreation } from '../../tools/featureFlags';
import { CreatePaymentModal } from '../modals/create-claim-modal/create-payment-modal';

export const menuItemDisabledProps: Partial<MenuItemProps> = {
    textColor: 'gray',
    _hover: { bg: 'white', cursor: 'not-allowed' },
};

export const buttonSizeProps: Partial<ButtonProps> = {
    h: '12',
    px: '8',
    w: 'auto',
};

export const buttonFontProps: Partial<ButtonProps> = {
    fontSize: '14px',
    fontWeight: '600',
};

export const secondaryButtonProps: Partial<ButtonProps> = {
    colorScheme: 'white',
    borderRadius: '6px',
    color: 'brand.bulla_blue',
    borderColor: 'brand.bulla_blue',
    borderWidth: '2px',
    _hover: { bg: 'rgb(18, 82, 91, .1)', borderColor: 'brand.600', color: 'brand.600' },
    _active: { bg: 'rgb(18, 82, 91, .2)', borderColor: 'brand.600', color: 'brand.600' },
};

export const OrangeButton = (props: Partial<ButtonProps>) => (
    <Button colorScheme={'accent'} {...buttonFontProps} {...buttonSizeProps} {...props} />
);
export const CreateOrangeButton = (text: string) => (props: Partial<ButtonProps>) => <OrangeButton {...props}>{text}</OrangeButton>;
export const CreateSecondaryButton = (text: string) => (props: Partial<ButtonProps>) =>
    <SecondaryButton {...props}>{text}</SecondaryButton>;

export const SecondaryButton = (props: Partial<ButtonProps & { href: string; target: string }>) => (
    <Button {...buttonSizeProps} {...buttonFontProps} {...secondaryButtonProps} {...props} />
);

export const SmallSecondaryButton = (props: Partial<ButtonProps>) => <Button {...secondaryButtonProps} {...props} />;

export const secondaryOrangeButtonProps: Partial<ButtonProps> = {
    border: '1px',
    borderColor: '#F3B685',
    color: '#DF5907',
    backgroundColor: '#FCEDE2',
    _hover: { backgroundColor: '#F3B685', color: '#DF5907' },
};

export const SecondaryOrangeButton = (props: Partial<ButtonProps>) => (
    <Button {...buttonFontProps} {...buttonSizeProps} {...secondaryOrangeButtonProps} {...props} />
);

type LinkButtonProps = { children: React.ReactNode; to: string } & TextProps;

export const LinkButtonText = ({ children, ...overrides }: Omit<LinkButtonProps, 'to'>) => (
    <Text fontWeight={'600'} fontSize="15px" lineHeight={'20px'} letterSpacing="0.02em" {...overrides}>
        {children}
    </Text>
);

export const LinkButton = ({ children, to, ...overrides }: LinkButtonProps) => (
    <ChakraLink as={ReactRouterNavLink} to={to}>
        <LinkButtonText {...overrides}>{children}</LinkButtonText>
    </ChakraLink>
);

export const CloseButton = (props: Partial<ButtonProps>) => <SecondaryButton {...props}>Close</SecondaryButton>;

const whiteButtonProps: Partial<ButtonProps> = {
    bg: 'white',
    h: '12',
    py: '6',
    px: '10',
    w: 'auto',
    border: '1px solid #E2E8F0',
};

export const WhiteButton: React.FC<ButtonProps> = ({ children, ...props }) => (
    <Button {...whiteButtonProps} {...props}>
        <Text fontWeight="600" fontSize={16} color="#2D3748">
            {children}
        </Text>
    </Button>
);

export const CreateSmallWhiteButton = (text: string) => (props: Partial<ButtonProps>) =>
    (
        <Button bg="white" py="2" px="4" border="1px solid #E2E8F0" {...props}>
            <Text fontWeight="600" fontSize={14} color="#2D3748">
                {text}
            </Text>
        </Button>
    );

export const TextButton = ({ children, ...overrides }: ButtonProps) => (
    <Button variant="link" textStyle="textButton" fontWeight="500" color="accent.400" {...overrides}>
        {children}
    </Button>
);

export const ViewDetailsButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <TextButton fontWeight="600" color="brand.bulla_blue" textDecoration={'initial'} w="fit-content" onClick={onClick}>
        View
    </TextButton>
);

export const LoanOfferButton: React.FC<{ onClick: () => void; text: string; isDisabled: boolean }> = ({ onClick, text, isDisabled }) => (
    <BullaBlueTextButton textDecoration="initial" w="fit-content" onClick={onClick} isDisabled={isDisabled}>
        {text}
    </BullaBlueTextButton>
);

export const BullaBlueTextButton = ({ ...props }: Partial<ButtonProps>) => (
    <TextButton color="brand.bulla_blue" {...buttonFontProps} {...props} />
);

export const BullaOrangeTextButton = ({ ...props }: Partial<ButtonProps>) => (
    <TextButton color="brand.bulla_orange" {...buttonFontProps} {...props} />
);

export const navButtonStyles: ChakraProps & ButtonProps = {
    fontWeight: 600,
    fontSize: '15px',
    letterSpacing: '0.02em',
    color: 'white',
    variant: 'ghost',
    _hover: { bg: 'brand.600' },
    _active: { bg: 'brand.500' },
};

export const NavButton = ({
    onClick,
    isDisabled: disabled,
    children,
    ...overrides
}: {
    onClick?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
} & ButtonProps) => (
    <Button onClick={onClick} isDisabled={disabled} {...navButtonStyles} {...overrides}>
        {children}
    </Button>
);

export const ActionDropdownButton = ({
    text,
    icon,
    actions,
    ...buttonProps
}: { text: string; icon: React.ReactNode; actions: React.ReactNode[]; isDisabled: boolean } & Partial<ButtonProps>) => {
    const actionButton = (
        <Button h="12" py="4" {...buttonProps} {...buttonFontProps}>
            <HStack spacing={'2'} px="3">
                <Text fontWeight="700">{text}</Text>
                {icon}
            </HStack>
        </Button>
    );

    return <ActionPopover actions={actions} triggerElement={actionButton} />;
};

export const ActionDropdown = ({ border }: { border?: string }) => {
    const { readyToTransact } = useAppState();
    const navigate = useNavigate();

    const actions = [
        enableNewInvoiceCreation ? (
            <PopoverAction onClick={() => navigate('/new-invoice')} isDisabled={!readyToTransact}>
                Invoice
            </PopoverAction>
        ) : (
            <CreateClaimModal
                claimType="Invoice"
                triggerElement={onOpen => (
                    <PopoverAction onClick={onOpen} isDisabled={!readyToTransact}>
                        Invoice
                    </PopoverAction>
                )}
            />
        ),
        <CreatePaymentModal
            triggerElement={onOpen => (
                <PopoverAction onClick={onOpen} isDisabled={!readyToTransact}>
                    Payment
                </PopoverAction>
            )}
        />,
        <PopoverAction onClick={() => navigate('/batch-invoice')} isDisabled={!readyToTransact}>
            Batch Invoice
        </PopoverAction>,
        <PopoverAction onClick={() => navigate('/batch-payment')} isDisabled={!readyToTransact}>
            Batch Payment
        </PopoverAction>,
    ];

    return (
        <ActionDropdownButton
            colorScheme={'accent'}
            text={'Create New'}
            actions={actions}
            border={border}
            isDisabled={!readyToTransact}
            icon={<ChevronDownIcon w="6" h="6" />}
            ml={'3'}
        />
    );
};

export const MenuDropdownButton = ({
    text,
    icon,
    actions,
    menuProps,
    ...buttonProps
}: {
    text: string;
    icon: React.ReactNode;
    actions: React.ReactNode[];
    menuProps?: Partial<MenuProps>;
} & Partial<ButtonProps>) => (
    <Menu {...menuProps}>
        <MenuButton fontWeight="700" as={Button} h="14" py="6" px="6" {...buttonProps}>
            <Flex align={'center'}>
                <Text>{text}</Text>
                <Spacer />
                {icon}
            </Flex>
        </MenuButton>
        <MenuList>{actions}</MenuList>
    </Menu>
);

export const ContinueButton = CreateOrangeButton('Continue');

type SwitchNetworksButtonProps = { requiredNetwork: ChainId };
export const SwitchNetworksButton: React.FC<SwitchNetworksButtonProps> = ({ requiredNetwork }) => {
    const canChangeNetwork = useCanChangeNetwork();
    const { changeNetwork } = useOnboard();

    return (
        <SecondaryButton isDisabled={!canChangeNetwork} onClick={() => changeNetwork(requiredNetwork)}>
            {canChangeNetwork ? 'Switch Network' : 'Switch Wallet Network to ' + NETWORKS[requiredNetwork].label}
        </SecondaryButton>
    );
};
